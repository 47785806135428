import React from 'react'
import apiUrl from '../../apiConfig'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { IoChevronForward } from 'react-icons/io5'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { IconContext } from 'react-icons'
// import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'

class OpenHouse extends React.Component {
  state = {
    view: '',
    createHouse: '',
    editHouse: '',
    openHouse: '',
    validated: '',
    mls: '',
    openHouseInfo: '',
    loaded: false
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.mlsid !== prevProps.mlsid) {
      this.getRequest()
    }
    if (this.state.view !== prevState.view) {
      this.props.viewProfile('openHouse', this.state.view)
    }
  }

  getRequest = () => {
    // console.log('getrequest')
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        this.setState({ openHouse: res.data.portals[0].featuredoh.id, view: '', createHouse: '', editHouse: '', validated: '' })
      })
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.portals[0].featuredoh.id) {
          this.setState({ openHouse: res.data.portals[0].featuredoh.id, view: 'edit' })
        }
        this.setState({ mls: res.data.portals[0].mlsinfo.mls })
      })
      .then(() => this.setState({ loaded: true }))
  }

  handleChange = (event) => {
    const value = event.target.value
    this.setState({ [event.target.name]: value },
      () => {
        if (this.state.openHouse.length > 0) {
          this.setState({ validated: true })
        } else {
          this.setState({ validated: false })
        }
      })
  }

  // handleSubmit = (event) => {
  //   event.preventDefault()
  //   axios({
  //     url: apiUrl + '/listing',
  //     method: 'POST',
  //     headers: {
  //       Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
  //     },
  //     data: {
  //       listingid: this.state.openHouse,
  //       mlsId: this.state.mls
  //     }
  //   })
  //     .then(res => {
  //       if (res.data.listing['@odata.count'] === 0) {
  //         this.setState({ error: 'Please double check your Open House Mls Id and try again.', validated: '' })
  //       } else {
  //         this.setState({ openHouseInfo: res.data.listing.value[0] })
  //         this.addOpenHouse()
  //       }
  //     })
  //     .catch(() => {
  //       this.setState({ error: 'Please double check your Open House Mls Id and try again.', validated: '' })
  //     })
  // }

  handleSubmit = (event) => {
    event.preventDefault()
    axios({
      url: apiUrl + '/openhouse',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        listingid: this.state.openHouse,
        mlsId: this.state.mls
      }
    })
      .then(res => {
        if (res.data.listing['@odata.count'] === 0) {
          this.setState({ error: 'Please double check your Mls Id, and make sure it is an Active Open House.', validated: '' })
        } else {
          this.addOpenHouse()
        }
      })
      .catch(() => {
        this.setState({ error: 'Please double check your Mls Id, and make sure it is an Active Open House.', validated: '' })
      })
  }

  addOpenHouse = () => {
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          featuredoh: {
            id: this.state.openHouse
          }
        }
      }
    })
      .then(this.setState({ validated: false, view: 'edit' }))
  }

  render () {
    let jsx
    if (!this.state.view) {
      jsx = (
        <React.Fragment>
          {!this.state.createHouse && !this.state.editHouse && this.state.loaded &&
          <div onClick={() => this.setState({ createHouse: true })}>
            <p style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'underline' }} className="main-font-style small-text red">Add Featured Open House</p>
          </div>
          }
          {this.state.createHouse &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <Form.Control
                  required
                  placeholder="Open House MLS ID"
                  onChange={this.handleChange}
                  onFocus={() => this.setState({ error: '', openHouse: '', validated: '' })}
                  value={this.state.openHouse}
                  name="openHouse"
                />
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
              {this.state.error &&
              <p className="auth-error-messages">{this.state.error}</p>
              }
            </Form>
          </div>
          }
          {this.state.editHouse &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <Form.Control
                  required
                  placeholder="Open House MLS ID"
                  onChange={this.handleChange}
                  onFocus={() => this.setState({ error: '', openHouse: '', validated: '' })}
                  value={this.state.openHouse}
                  name="openHouse"
                />
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
              {this.state.error &&
              <p className="auth-error-messages">{this.state.error}</p>
              }
            </Form>
          </div>
          }
        </React.Fragment>
      )
    } else {
      jsx = (
        <div style={{ width: '100%' }}>
          <p onClick={() => this.setState({ view: '', createHouse: '', editHouse: 'true', validated: 'true' })} style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'none' }} className="main-font-style small-text variable-main-font-color"><span className="xs-text" style={{ fontWeight: 'bold' }}>featured open house:</span><br></br><span className="variable-checks">&#10004;</span>&nbsp;&nbsp;{this.state.openHouse}&nbsp;<MdOutlineModeEditOutline></MdOutlineModeEditOutline></p>
        </div>
      )
    }
    return (
      <React.Fragment>
        {jsx}
      </React.Fragment>
    )
  }
}

export default OpenHouse
