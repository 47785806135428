import React from 'react'
import apiUrl from '../../../apiConfig'
import axios from 'axios'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

class PaymentSuccess extends React.Component {
  state = {
    redirect: '',
    stripeCustomerId: '',
    stripeSubId: '',
    stripeSubStatus: '',
    stripeDefPaymentId: '',
    portalId: ''
  }

  // componentDidMount () {
  //   const string = window.location.href
  //   const arr = string.split('=')
  //   const sessionId = arr[1]
  //   console.log(sessionId)
  //   axios({
  //     url: apiUrl + '/checkoutsession',
  //     method: 'POST',
  //     headers: {
  //       Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
  //     },
  //     data: {
  //       session_id: sessionId
  //     }
  //   })
  //     .then(res => this.setState({ stripeCustomerId: res.data.checkoutSession.customer }))
  //     .then(() => this.setState({ redirect: true }))
  // }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        this.setState({ portalId: res.data.portals[0]._id, stripeCustomerId: res.data.portals[0].stripe.stripeCusId, stripeSubStatus: res.data.portals[0].stripe.stripeSubStatus })
      })
      .then(setTimeout(() => { this.setState({ redirect: true }) }, 2000))
  }
  // before create subscription - get paymentintent, get payment id, edit user to make it the default payment

  getCheckoutSession = () => {
    const string = window.location.href
    const arr = string.split('=')
    const sessionId = arr[1]
    // console.log(sessionId)
    axios({
      url: apiUrl + '/checkoutsession',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        session_id: sessionId
      }
    })
    // .then(res => console.log(res))
    // .then(res => this.setState({ stripeDefPaymentId: res.data.checkoutSession.payment_intent.payment_method }))
    // .then(this.addPaymentMethod)
    // .catch(this.setState({ redirect: true }))
  }

  addPaymentMethod = () => {
    axios({
      url: apiUrl + '/defaultpaymentmethod',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        customerId: this.state.stripeCustomerId,
        paymentMethod: this.state.stripeDefPaymentId
      }
    })
      .then(setTimeout(() => { this.setState({ redirect: true }) }, 2000))
  }

  // createSubscription = () => {
  //   axios({
  //     url: apiUrl + '/create-subscription',
  //     method: 'POST',
  //     headers: {
  //       Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
  //     },
  //     data: {
  //       customer: this.state.stripeCustomerId,
  //       portal: this.state.portalId,
  //       stripeSubStatus: this.state.stripeSubStatus
  //     }
  //   })
  //     .then(res => this.setState({ stripeSubId: res.data.subscription.id, stripeSubStatus: res.data.subscription.status }))
  //     // .then(this.addSubscription)
  //     .then(setTimeout(() => { this.setState({ redirect: true }) }, 2000))
  // }

  // addSubscription = () => {
  //   axios({
  //     url: apiUrl + '/portals/' + this.state.portalId,
  //     method: 'PATCH',
  //     headers: {
  //       Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
  //     },
  //     data: {
  //       portal: {
  //         portalAccess: true,
  //         stripe: {
  //           stripeCusId: this.state.stripeCustomerId,
  //           stripeSubId: this.state.stripeSubId,
  //           stripeDefPaymentId: this.state.stripeDefPaymentId
  //         }
  //       }
  //     }
  //   })
  //     .then(setTimeout(() => { this.setState({ redirect: true }) }, 2000))
  // }

  render () {
    if (this.state.redirect) {
      return <Switch><Redirect to ={{ pathname: '/portal', props: { result: 'success', stripeCustomerId: this.state.stripeCustomerId } }} /></Switch>
    }
    return (
      <Container fluid className="portal-container theme-one" style={{ maxWidth: '600px', height: '100vh' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ height: '50%' }} className='d-flex align-items-center'>
            <p style={{ textAlign: 'center' }} className='main-font-style med-text white'>We are happy to have you aboard, <span style={{ fontWeight: 'bold' }}>{this.props.user.idToken.payload.given_name}</span> and looking forward to being a part of your win! We are currently provisioning your <span style={{ fontWeight: 'bold' }}>license</span>. Please do not close the browser. </p>
          </div>
          <div style={{ height: '50%' }} className='d-flex justify-content-center'>
            <Spinner animation='border' role='status' style={{ height: '6rem', width: '6rem', color: 'grey' }}/>
          </div>
        </div>
      </Container>
    )
  }
}

export default withRouter(PaymentSuccess)
