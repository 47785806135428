import React from 'react'
import apiUrl from '../../apiConfig'
import axios from 'axios'
import InputMask from 'react-input-mask'
// import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { IoChevronForward } from 'react-icons/io5'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { IconContext } from 'react-icons'

class MobileNumber extends React.Component {
  state = {
    view: '',
    createNumber: '',
    editNumber: '',
    phoneNumber: '',
    validated: '',
    loaded: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.portals[0].mobile) {
          this.setState({ phoneNumber: res.data.portals[0].mobile.number, view: 'edit' })
        }
      })
      .then(() => this.setState({ loaded: true }))
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.view !== prevState.view) {
      this.props.viewProfile('mobileNumber', this.state.view)
    }
  }

  handleChange = (event) => {
    const value = event.target.value
    this.setState({ [event.target.name]: value },
      () => {
        const arr = this.state.phoneNumber.split('')
        if (arr.every((index) => index !== ' ')) {
          this.setState({ validated: true })
        } else {
          this.setState({ validated: false })
        }
      })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ view: 'edit' })
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          mobile: {
            number: this.state.phoneNumber
          }
        }
      }
    })
  }

  render () {
    let jsx
    if (!this.state.view) {
      jsx = (
        <React.Fragment>
          {!this.state.createNumber && !this.state.editNumber && this.state.loaded &&
          <div onClick={() => this.setState({ createNumber: true })}>
            <p style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'underline' }} className="main-font-style small-text red">Add Mobile Number</p>
          </div>
          }
          {this.state.createNumber &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <InputMask
                  mask="(999)999-9999"
                  maskChar=" "
                  className="form-control"
                  style={{ borderRadius: '.25rem 0 0 .25rem' }}
                  placeholder="Mobile Number"
                  onChange={this.handleChange}
                  value={this.state.phoneNumber}
                  name="phoneNumber"
                />
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
            </Form>
          </div>
          }
          {this.state.editNumber &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <InputMask
                  mask="(999)999-9999"
                  maskChar=" "
                  className="form-control"
                  style={{ borderRadius: '.25rem 0 0 .25rem' }}
                  placeholder="Mobile Number"
                  onChange={this.handleChange}
                  value={this.state.phoneNumber}
                  name="phoneNumber"
                />
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
            </Form>
          </div>
          }
        </React.Fragment>
      )
    } else {
      jsx = (
        <div style={{ width: '100%' }}>
          <p onClick={() => this.setState({ view: '', createNumber: '', editNumber: 'true', validated: 'true' })} style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'none' }} className="main-font-style small-text variable-main-font-color"><span className="xs-text" style={{ fontWeight: 'bold' }}>mobile number:</span><br></br><span className="variable-checks">&#10004;</span>&nbsp;&nbsp;{this.state.phoneNumber}&nbsp;<MdOutlineModeEditOutline></MdOutlineModeEditOutline></p>
        </div>
      )
    }
    return (
      <React.Fragment>
        {jsx}
      </React.Fragment>
    )
  }
}

export default MobileNumber
