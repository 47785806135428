import React from 'react'
import axios from 'axios'
import apiUrl from '../../apiConfig'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import { IconContext } from 'react-icons'
import Spinner from 'react-bootstrap/Spinner'
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md'
import BulkLicensesSubscription from './BulkLicensesSubscriptions'

class BulkLicensesModal extends React.Component {
  state = {
    buttonClicked: false,
    arrowClicked: false,
    qty: '',
    bulkSubscriptions: '',
    loading: true,
    assigneeId: '',
    portalsArray: '',
    upgradeAccepted: ''
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => {
        if (res.data.portals[0].bulkSubscriptions) {
          if (res.data.portals[0].bulkSubscriptions.subscriptionStatus !== 'canceled') {
            this.setState({ bulkSubscriptions: res.data.portals[0].bulkSubscriptions })
          }
        }
      })
      .then(() => this.setState({ loading: false }))
  }

  getRequest = () => {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => {
        if (res.data.portals[0].bulkSubscriptions.quantity === this.state.bulkSubscriptions.quantity) {
          this.getRequest()
        } else {
          this.setState({ bulkSubscriptions: res.data.portals[0].bulkSubscriptions })
        }
      })
  }

  getRequestAssignees = () => {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => {
        if (res.data.portals[0].bulkSubscriptions.assignees === this.state.bulkSubscriptions.assignees) {
          this.getRequestAssignees()
        } else {
          this.setState({ bulkSubscriptions: res.data.portals[0].bulkSubscriptions })
        }
      })
  }

  getBulkSubscriptions = () => {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => {
        if (res.data.portals[0].bulkSubscriptions) {
          this.setState({ bulkSubscriptions: res.data.portals[0].bulkSubscriptions })
        }
      })
  }

  toPurchaseBulk = (event) => {
    event.preventDefault()
    this.setState({ buttonClicked: true })
    axios({
      url: apiUrl + '/bulk-subscription-checkout-session',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        stripeCustomerId: this.props.stripeCustomerId,
        portalId: this.props.portalId,
        cognitoId: this.props.user.accessToken.payload.username,
        quantity: this.state.qty,
        assigneeId: ''
      }
    })
      .then(res => window.location.replace(res.data.url))
      .catch(() => {
        this.props.msgAlert({
          heading: 'Something Went Wrong',
          message: 'Please try again later'
        })
      })
  }

  toAddBulkQuantity = (event) => {
    this.setState({ arrowClicked: true })
    event.preventDefault()
    axios({
      url: apiUrl + '/bulk-subscription-add',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        quantity: parseFloat(this.state.qty) + parseFloat(this.state.bulkSubscriptions.quantity),
        subscriptionId: this.state.bulkSubscriptions.subscriptionId,
        assigneeId: ''
      }
    })
      .then(res => {
        this.props.msgAlert({
          heading: 'Success',
          message: `Your bulk subscription was successfully updated by ${this.state.qty} unit(s) to ${res.data.subscription.quantity} licenses.`
        })
      }
      )
      .then(() => this.setState({ arrowClicked: false, qty: '', upgradeAccepted: '' }))
      .then(this.getRequest)
      .catch(() => {
        this.setState({ arrowClicked: false, qty: '', upgradeAccepted: '' })
        this.props.bulkLicensesModalClose()
        this.props.msgAlert({
          heading: 'Something Went Wrong',
          message: 'Please check your payment info and your internet connection, and try again'
        })
      })
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
    this.setState({ upgradeAccepted: false })
  }

  sum = () => {
    return parseFloat(this.state.qty) * parseFloat(this.props.currentPrice)
  }

  totalMonthly = () => {
    const qty = parseFloat(this.state.qty)
    const currentPrice = parseFloat(this.props.currentPrice)
    const subQty = parseFloat(this.state.bulkSubscriptions.quantity)
    const prodOne = qty * currentPrice
    const prodTwo = subQty * currentPrice
    return prodOne + prodTwo
  }

  totalLicenses = () => {
    const qty = parseFloat(this.state.qty)
    const subQty = parseFloat(this.state.bulkSubscriptions.quantity)
    return qty + subQty
  }

  assignLicense = (event) => {
    event.preventDefault()
    this.setState({ buttonClicked: true })
    axios({
      url: apiUrl + '/allportals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => this.setState({ portalsArray: res.data.portals }))
      .then(this.findPortal)
  }

  findPortal = () => {
    if (this.state.portalsArray.some((index) => index._id === this.state.assigneeId)) {
      // console.log(true)
    } else {
      this.props.msgAlert({
        heading: 'Invalid ID',
        message: 'Please verify the user ID and try again'
      })
      this.setState({ assigneeId: '', buttonClicked: false })
    }
  }

  render () {
    return (
      <React.Fragment>
        <Modal show={this.props.bulkLicensesModal} fullscreen={'sm-down'} onHide={this.props.bulkLicensesModalClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: 'bold' }} className="main-font-style">Manage Bulk Licensing</Modal.Title>
          </Modal.Header>
          <Modal.Body className="main-font-style">
            {this.state.bulkSubscriptions &&
            <p style={{ textAlign: 'center', borderBottom: 'solid', borderBottomWidth: '1px', borderBottomColor: 'rgb(222, 226, 230)', paddingBottom: '1rem' }}>You currently have <span style={{ fontWeight: 'bold' }}>{this.state.bulkSubscriptions.quantity}</span> bulk licenses total. Purchase more subscriptions below by inputting the quantity you would like to purchase, then click the green arrow. <span style={{ fontWeight: 'bold' }}></span></p>
            }
            {!this.state.bulkSubscriptions &&
            <p style={{ textAlign: 'center', borderBottom: 'solid', borderBottomWidth: '1px', borderBottomColor: 'rgb(222, 226, 230)', paddingBottom: '1rem' }}>In this section, you can purchase multiple subscriptions for assigning to MLS licensed agents. Bulk subscriptions are ${this.props.currentPrice} each. Start by selecting a quantity, then click the arrow to redirect to checkout.</p>
            }
            {this.state.bulkSubscriptions &&
            <div>
              {!this.state.arrowClicked &&
              <div style={{ borderBottom: 'solid', borderBottomWidth: '1px', borderBottomColor: 'rgb(222, 226, 230)', paddingBottom: '1rem' }}>
                <div className="d-flex">
                  <div className="col-5">
                    <p style={{ fontWeight: 'bold', marginBottom: '0', textAlign: 'center' }}>Purchase more Subscriptions</p>
                  </div>
                  <div className="col-7 d-flex align-items-center">
                    <Form onSubmit={this.toAddBulkQuantity}>
                      <Form.Group as={Row} className="d-flex justify-content-around">
                        <Col className="col-6 d-flex flex-row justify-content-end" style={{ paddingRight: '.3rem' }}>
                          <Form.Label className="d-flex align-items-center mb-0 mr-1">Qty:</Form.Label>
                          <Form.Control
                            style={{ width: '40%', marginLeft: '.6rem' }}
                            size="sm"
                            required
                            type='number'
                            name='qty'
                            min='1'
                            value={this.state.qty}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col className="col-6" style={{ paddingLeft: '0' }}>
                          <Button disabled={!this.state.upgradeAccepted} className="white" type="submit" variant="ternary" size='sm' style={{ backgroundColor: 'white', borderColor: 'white' }}><IconContext.Provider value={{ color: '#23a455', size: '1.5rem' }}><div><BsArrowRightCircleFill /></div></IconContext.Provider></Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div style={{ textAlign: 'right', width: '80%' }} className="mt-1">
                  {this.state.qty >= 1 &&
                    <div>
                      <p style={{ color: 'black', marginBottom: '0' }}><span style={{ fontWeight: 'bold' }}>${this.sum()} additional</span>/month</p>
                      <p style={{ color: 'black', marginBottom: '0' }}><span style={{ fontWeight: 'bold' }}>${this.totalMonthly()} total</span>/month for <span style={{ fontWeight: 'bold' }}>{this.totalLicenses()}</span> licenses</p>
                      <div className="d-flex justify-content-end">
                        {!this.state.upgradeAccepted &&
                        <div className="d-flex align-items-center" style={{ color: 'black', marginRight: '.2rem' }}>Click to Accept</div>
                        }
                        {!this.state.upgradeAccepted &&
                        <div onClick={() => this.setState({ upgradeAccepted: true })} className="d-flex align-items-center"><MdOutlineCheckBoxOutlineBlank></MdOutlineCheckBoxOutlineBlank></div>
                        }
                        {this.state.upgradeAccepted &&
                        <div className="d-flex align-items-center" style={{ color: 'black', marginRight: '.2rem' }}>Click Arrow to Purchase</div>
                        }
                        {this.state.upgradeAccepted &&
                        <div onClick={() => this.setState({ upgradeAccepted: false })} className="d-flex align-items-center"><MdOutlineCheckBox></MdOutlineCheckBox></div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
              }
              {this.state.arrowClicked &&
              <div className="d-flex justify-content-center" style={{ borderBottom: 'solid', borderBottomWidth: '1px', borderBottomColor: 'rgb(222, 226, 230)', paddingBottom: '1rem' }}>
                <Spinner style={{ color: '#23a455' }} animation="border" size="lg"></Spinner>
              </div>
              }
            </div>
            }
            {!this.state.bulkSubscriptions &&
            <div style={{ borderBottom: 'solid', borderBottomWidth: '1px', borderBottomColor: 'rgb(222, 226, 230)', paddingBottom: '1rem' }}>
              <div className="d-flex">
                <div className="col-5">
                  <p style={{ fontWeight: 'bold', marginBottom: '0', textAlign: 'center' }}>Bulk Subscriptions:</p>
                </div>
                <div className="col-7 d-flex align-items-center">
                  <Form onSubmit={this.toPurchaseBulk}>
                    <Form.Group as={Row} className="d-flex justify-content-around">
                      <Col className="col-6 d-flex flex-row justify-content-end" style={{ paddingRight: '.3rem' }}>
                        <Form.Label className="d-flex align-items-center mb-0 mr-1">Qty:</Form.Label>
                        <Form.Control
                          style={{ width: '40%', marginLeft: '.6rem' }}
                          size="sm"
                          required
                          type='number'
                          name='qty'
                          min='1'
                          value={this.state.qty}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col className="col-6" style={{ paddingLeft: '0' }}>
                        <Button className="white" type="submit" variant="ternary" size='sm' style={{ backgroundColor: 'white', borderColor: 'white' }}><IconContext.Provider value={{ color: '#23a455', size: '1.5rem' }}><div><BsArrowRightCircleFill /></div></IconContext.Provider></Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </div>
              <div style={{ textAlign: 'right', width: '80%' }} className="mt-1">
                {this.state.qty >= 1 &&
                  <span style={{ color: 'black' }}><span style={{ fontWeight: 'bold' }}>${this.sum()}</span> /month</span>
                }
              </div>
            </div>
            }
            {!this.state.loading && this.state.bulkSubscriptions &&
            <div>
              {this.state.bulkSubscriptions.assignees.length <= this.state.bulkSubscriptions.quantity &&
              <div className="main-font-style" style={{ borderBottom: 'solid', borderBottomWidth: '1px', borderBottomColor: 'rgb(222, 226, 230)', paddingBottom: '1rem', paddingTop: '1rem' }}>
                <BulkLicensesSubscription user={this.props.user} getRequest={this.getRequest} getRequestAssignees={this.getRequestAssignees} msgAlert={this.props.msgAlert} {...this.state} {...this.props}></BulkLicensesSubscription>
              </div>
              }
            </div>
            }
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default BulkLicensesModal
