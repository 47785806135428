import React from 'react'
import axios from 'axios'
import apiUrl from '../../apiConfig'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

class FailedBulkPaymentModal extends React.Component {
  state = {
    buttonClicked: false,
    bulkSubscriptions: ''
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => {
        if (res.data.portals[0].bulkSubscriptions) {
          this.setState({ bulkSubscriptions: res.data.portals[0].bulkSubscriptions })
        }
      })
  }

  toBilling = () => {
    axios({
      url: apiUrl + '/create-portal-session',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        customer: this.props.stripeCustomerId
      }
    })
      // .then(res => console.log(res))
      .then(res => window.location.replace(res.data.url))
  }

  updateSubStatus = () => {
    this.setState({ buttonClicked: true })
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          bulkSubscriptions: {
            assignees: this.state.bulkSubscriptions.assignees,
            ownerStripeId: this.state.bulkSubscriptions.ownerStripeId,
            ownerPortalId: this.state.bulkSubscriptions.ownerPortalId,
            ownerCognitoId: this.state.bulkSubscriptions.ownerCognitoId,
            subscriptionStatus: 'past_due_alert_given',
            subscriptionId: this.state.bulkSubscriptions.subscriptionId,
            quantity: this.state.bulkSubscriptions.quantity
          }
        }
      }
    })
      .then(this.toBilling)
  }

  render () {
    return (
      <React.Fragment>
        <Modal show={this.props.failedBulkPaymentModal} fullscreen={'sm-down'} onHide={this.props.failedBulkPaymentModalClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: 'bold' }} className="main-font-style">ATTENTION!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="main-font-style">
            <p>Your payment method failed while attempting to renew your <span style={{ fontWeight: 'bold' }}>Bulk Licenses</span>. Within 48 hours, please update the payment method in your <span style={{ fontWeight: 'bold' }}>Billing</span> settings to prevent your Bulk Licenses from lapsing. If the licenses laps, all assignees will lose access.</p>
            {!this.state.buttonClicked &&
            <Button onClick={this.updateSubStatus} style={{ width: '100%' }} variant="ternary" className="white mt-3">Billing &rarr;</Button>
            }
            {this.state.buttonClicked &&
            <Button style={{ color: 'white', width: '100%' }} className="mt-3" variant="ternary"><Spinner size='sm' as='span' animation='border' role='status'/></Button>
            }
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default FailedBulkPaymentModal
