/* eslint-disable no-tabs */
import React, { Component, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import AuthenticatedRoute from './components/AuthenticatedRoute/AuthenticatedRoute'
import AutoDismissAlert from './components/AutoDismissAlert/AutoDismissAlert'
import SalesPage from './components/SalesPage/SalesPage'
import SignUp2 from './components/auth/SignUp2/SignUp2'
import SignIn2 from './components/auth/SignIn2/SignIn2'
import SignOut2 from './components/auth/SignOut2/SignOut2'
import ChangePassword from './components/auth/ChangePassword'
import AgentPortal from './components/AgentPortal/AgentPortal'
import Unauthorized from './components/auth/Unauthorized'
import UserPool from './components/auth/SignUp2/UserPool'
import CreatePortal from './components/AgentPortal/CreatePortal'
import Profile from './components/AgentPortal/ViewProfile/ViewProfile'
import PaymentSuccess from './components/AgentPortal/Stripe/PaymentSuccess'
import PaymentCancel from './components/AgentPortal/Stripe/PaymentCancel'
import SelectMls from './components/AgentPortal/SelectMls'
import PageNotFound from './components/NotFound'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: null,
      msgAlerts: [],
      currentPrice: 197,
      setupPrice: 149
    }
  }

  UNSAFE_componentWillMount () {
    // this.getSession().then((data) => {
    //   this.setState({ user: data })
    // })
    const user = UserPool.getCurrentUser()
    if (user) {
      user.getSession((err, data) => {
        if (err) {
          console.error(err)
        } else {
          this.setState({ user: data })
        }
      })
    }
  }

  getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser()
      if (user) {
        user.getSession((err, data) => {
          if (err) {
            reject(err)
          } else {
            // console.log(data)
            resolve(data)
          }
        })
      } else {
        reject(user)
      }
    })
  }

  setUser = (user) => this.setState({ user })

  clearUser = () => this.setState({ user: null },
    () => {
      // console.log('user logged out', this.state.user)
    })

  deleteAlert = (id) => {
    this.setState((state) => {
      return { msgAlerts: state.msgAlerts.filter((msg) => msg.id !== id) }
    })
  }

  msgAlert = ({ heading, message, variant }) => {
    const id = uuid()
    this.setState((state) => {
      return {
        msgAlerts: [...state.msgAlerts, { heading, message, variant, id }]
      }
    })
  }

  render () {
    const { msgAlerts, user } = this.state

    return (
      <Fragment>
	      {msgAlerts.map((msgAlert) => (
          <AutoDismissAlert
            key={msgAlert.id}
            heading={msgAlert.heading}
            variant={msgAlert.variant}
            message={msgAlert.message}
            id={msgAlert.id}
            deleteAlert={this.deleteAlert}
          />
        ))}
	      <main>
          <Switch>
            <Route
              exact path='/'
              render={() => (
                <SalesPage msgAlert={this.msgAlert} getSession={this.getSession} user={user} />
              )}
            />
            <Route
              exact path='/sign-up'
              render={() => (
                <SignUp2 msgAlert={this.msgAlert} />
              )}
            />
            <Route
              exact path='/sign-in'
              render={() => (
                <SignIn2 msgAlert={this.msgAlert} setUser={this.setUser} user={user} />
              )}
            />
            <Route
              exact path='/unauthorized'
              render={() => (
                <Unauthorized user={user} msgAlert={this.msgAlert} />
              )}
            />
            <Route
              user={user}
              path='/sign-out'
              render={() => (
                <SignOut2
                  msgAlert={this.msgAlert}
                  clearUser={this.clearUser}
                  user={user}
                />
              )}
            />
            <AuthenticatedRoute
              user={user}
              path='/profile/:id'
              render={() => (
                <Profile
                  msgAlert={this.msgAlert}
                  clearUser={this.clearUser}
                  user={user}
                />
              )}
            />
            <AuthenticatedRoute
              user={user}
              getSession={this.getSession}
              setUser={this.setUser}
              path='/change-password'
              render={() => (
                <ChangePassword msgAlert={this.msgAlert} user={user} />
              )}
            />
            <AuthenticatedRoute
              user={user}
              setUser={this.setUser}
              getSession={this.getSession}
              path='/portal/:id'
              render={() => (
                <AgentPortal msgAlert={this.msgAlert} user={user} setUser={this.setUser} getSession={this.getSession} setupPrice={this.state.setupPrice} currentPrice={this.state.currentPrice}/>
              )}
            />
            <AuthenticatedRoute
              user={user}
              exact path='/portal'
              render={() => (
                <CreatePortal user={user} msgAlert={this.msgAlert} currentPrice={this.state.currentPrice}/>
              )}
            />
            <AuthenticatedRoute
              user={user}
              exact path='/selectmls'
              render={() => (
                <SelectMls user={user} msgAlert={this.msgAlert} currentPrice={this.state.currentPrice}/>
              )}
            />
            <AuthenticatedRoute
              user={user}
              exact path='/cancel'
              render={() => (
                <PaymentCancel user={user} />
              )}
            />
            <AuthenticatedRoute
              user={user}
              exact path='/success'
              render={() => (
                <PaymentSuccess user={user} />
              )}
            />
            <Route
              render={() => (
                <PageNotFound></PageNotFound>
              )}
            />
          </Switch>
        </main>
      </Fragment>
    )
  }
}

export default (App)
