import React from 'react'
import apiUrl from '../../apiConfig'
import axios from 'axios'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { serverError } from '../AutoDismissAlert/messages'
import Spinner from 'react-bootstrap/Spinner'
import EulaModal from './EulaModal'

class SelectMls extends React.Component {
  state = {
    redirect: '',
    mlsArray: '',
    value: '',
    validated: '',
    mls: '',
    state: '',
    serverError: false,
    loading: true,
    eulaModal: false,
    checkBox: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => {
        if (!res.data.portals[0]) {
          this.pullMlsOptions()
        } else {
          this.setState({ redirect: true })
          // this.pullMlsOptions()
        }
      })
      .catch(() => {
        this.setState({ serverError: true },
          () => this.setServerErrorMessage()
        )
      })
  }

  pullMlsOptions = () => {
    this.setState({ loading: false })
    axios({
      url: apiUrl + '/mlslist',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => {
        const array = res.data.list.sort((a, b) => a.state.toLowerCase().localeCompare(b.state.toLowerCase()))
        this.setState({ mlsArray: array })
      })
      .catch(() => {
        this.setState({ serverError: true },
          () => this.setServerErrorMessage()
        )
      })
  }

  setServerErrorMessage = () => {
    if (this.state.serverError === true) {
      this.props.msgAlert({
        heading: 'Server Error',
        message: serverError
      })
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value },
      () => {
        if (this.state.value) {
          const value = event.target.value
          const newValue = value.split('-')
          const noBlanksState = newValue[0].split(' ')
          const noBlanksMls = newValue[1].split(' ')
          const state = noBlanksState[0]
          const mls = noBlanksMls[1]
          this.setState({ validated: true, mls: mls, state: state })
        } else {
          this.setState({ validated: false, mls: '', state: '' })
        }
      })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ redirect: true })
  }

  toSignOut = () => {
    return this.props.history.push('/sign-out')
  }

  eulaModalClose = () => {
    this.setState({ eulaModal: false })
  }

  render () {
    if (this.state.serverError) {
      return <Switch><Redirect to ={{ pathname: '/sign-out' }} /></Switch>
    }
    if (this.state.redirect) {
      return <Switch><Redirect to ={{ pathname: '/portal', props: { mls: this.state.mls, state: this.state.state } }} /></Switch>
    }
    return (
      <React.Fragment>
        <Container fluid className="portal-container" style={{ maxWidth: '600px', backgroundColor: '#252739', height: '100vh' }}>
          {this.state.loading &&
          <div style={{ width: '100%', height: '100%' }} className="d-flex justify-content-center align-items-center">
            <Spinner animation='border' role='status' style={{ height: '6rem', width: '6rem', color: 'grey' }}/>
          </div>
          }
          <Button onClick={this.toSignOut} className="portal-top-btn mt-3">Sign Out</Button>
          {!this.state.loading &&
          <div className="d-flex flex-column justify-content-center" style={{ width: '100%', height: '80%' }}>
            <p style={{ textAlign: 'center', fontSize: '1.2rem' }} className="main-font-style white">Select your MLS from the list below. We currently only support the MLS&apos;s on the list. To get your MLS added, please reach out to the Leads Genie Team.</p>
            {this.state.mlsArray &&
            <div style={{ width: '100%' }} className="d-flex justify-content-center mt-2">
              <Form onSubmit={this.handleSubmit} style={{ width: '80%' }}>
                <InputGroup>
                  <Form.Select required name="value" onChange={this.handleChange}>
                    <option></option>
                    {this.state.mlsArray.map(instance => (
                      <option className="main-font-style" key={instance._id}>{instance.state} - {instance.mls}</option>
                    ))}
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mt-3">
                  <p style={{ textAlign: 'center', fontSize: '1.2rem' }} className="main-font-style white">Please acknowledge that you have read the <span style={{ fontWeight: 'bold' }}> End User License Agreement.</span></p>
                  <div style={{ width: '100%' }} className='d-flex justify-content-center'>
                    <Button onClick={() => this.setState({ eulaModal: true, checkBox: true })} variant="ternary" style={{ marginRight: '.5rem' }} className="white">View EULA</Button>
                    <div style={{ height: '100%' }} className="d-flex align-items-end">
                      <Form.Check required style={{ color: 'white' }} type="checkbox" label="I Accept" />
                    </div>
                  </div>
                </InputGroup>
                <InputGroup className="d-flex justify-content-end">
                  <Button type="submit" disabled={!this.state.validated} className="white mt-2" variant="ternary">Go</Button>
                </InputGroup>
              </Form>
            </div>
            }
          </div>
          }
        </Container>
        <EulaModal {...this.state} eulaModalClose={this.eulaModalClose}></EulaModal>
      </React.Fragment>
    )
  }
}

export default withRouter(SelectMls)
