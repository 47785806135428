import React, { createRef } from 'react'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import { IoClipboard } from 'react-icons/io5'
import { IconContext } from 'react-icons'
import axios from 'axios'
import apiUrl from '../../apiConfig'
import BulkLicensesModal from './BulkLicensesModal'

class NoLicense extends React.Component {
  trigger = createRef()
  state = {
    modal: false,
    purchasingSubscription: '',
    toolTip: false,
    bulkLicensesModal: false
  }

  modalClose = () => {
    this.setState({ modal: false })
  }

  // handleSubmit = () => {
  //   this.setState({ purchasingSubscription: true })
  //   axios({
  //     url: apiUrl + '/create-checkout-session',
  //     method: 'POST',
  //     headers: {
  //       Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
  //     },
  //     data: {
  //       lookup_key: 'prod_L9365QvGd0T1NK'
  //     }
  //   })
  //     .then(res => window.location.replace(res.data.url))
  // }

  createPaymentIntent = () => {
    this.setState({ purchasingSubscription: true })
    axios({
      url: apiUrl + '/create-checkout-session',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        stripeCustomerId: this.props.stripeCustomerId
      }
    })
      .then(res => window.location.replace(res.data.url))
  }

  initialPurchaseSubscription = () => {
    this.setState({ purchasingSubscription: true })
    axios({
      url: apiUrl + '/subscription-checkout-session-initial',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        stripeCustomerId: this.props.stripeCustomerId,
        portalId: this.props.portalId
      }
    })
      .then(res => window.location.replace(res.data.url))
      .catch(() => {
        this.props.msgAlert({
          heading: 'Something Went Wrong',
          message: 'Please try again later'
        })
      })
  }

  purchaseSubscriptionAgain = () => {
    this.setState({ purchasingSubscription: true })
    axios({
      url: apiUrl + '/subscription-checkout-session-again',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        stripeCustomerId: this.props.stripeCustomerId,
        portalId: this.props.portalId
      }
    })
      .then(res => window.location.replace(res.data.url))
      .catch(() => {
        this.props.msgAlert({
          heading: 'Something Went Wrong',
          message: 'Please try again later'
        })
      })
  }

  handleToolTip = async (event) => {
    event.persist()
    await navigator.clipboard.writeText(event.currentTarget.dataset.id)
    this.setState({ toolTip: true })
    this.toolTipFalse()
  }

  toolTipFalse = () => {
    setTimeout(() => { this.setState({ toolTip: false }) }, 1200)
  }

  bulkLicensesModalOpen = () => {
    this.setState({ bulkLicensesModal: true, accountInfoModal: false })
  }

  bulkLicensesModalClose = () => {
    this.setState({ bulkLicensesModal: false })
  }

  render () {
    const bulkSubscriptionActive = this.props.bulkSubStatus !== 'canceled' && this.props.bulkSubStatus !== ''
    // console.log(bulkSubscriptionActive, 'active')
    return (
      <Container fluid style={{ minHeight: '800px' }}>
        {this.props.stripeSubStatus !== 'canceled' &&
        <div style={{ height: '100%' }}>
          <div style={{ height: '60%' }} className="d-flex flex-column justify-content-evenly">
            <p style={{ textAlign: 'center' }} className='main-font-style large-text white'>Hi,&nbsp;<span style={{ fontWeight: 'bold' }} >{this.props.user.idToken.payload.given_name}</span></p>
            <p style={{ textAlign: 'center' }} className='main-font-style med-text-profile white'>You do not currently have an active subscription. To have a license assigned to you, give the assignor your User ID. If you would like to instead purchase your own subscription, click the button below. </p>
            <Form>
              <Form.Group>
                <Row>
                  <p className="white mb-0">User ID:</p>
                  <Col className="col-11" style={{ paddingRight: '0' }}>
                    <Form.Control readOnly defaultValue={this.props.portalId} style={{ textAlign: 'center' }} />
                  </Col>
                  <Col className="col-1" style={{ paddingLeft: '0' }}>
                    <Button ref={this.trigger} onClick={this.handleToolTip} data-id={this.props.portalId} className="white clipboard" variant="ternary" style={{ backgroundColor: '#252739', borderColor: '#252739', paddingLeft: '0' }}><IconContext.Provider value={{ color: '#23a455', size: '1.5rem', padding: '0', margin: '0' }}><div><IoClipboard /></div></IconContext.Provider></Button>
                    <Overlay target={this.trigger.current} show={this.state.toolTip} placement="left">
                      {(props) => (
                        <Tooltip {...props}>
                          Copied!
                        </Tooltip>
                      )}
                    </Overlay>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </div>
          <div style={{ height: '30%' }} className="d-flex flex-column justify-content-evenly">
            <p style={{ textAlign: 'center' }} className="white large-text">- OR -</p>
            <div>
              {!this.state.purchasingSubscription &&
              <Button onClick={this.initialPurchaseSubscription} className="white" style={{ width: '100%' }} variant="ternary">Purchase Subscription</Button>
              }
              {this.state.purchasingSubscription &&
              <Button style={{ color: 'white', width: '100%' }} variant="ternary"><Spinner size='sm' as='span' animation='border' role='status'/></Button>
              }
            </div>
            {/* <p style={{ textAlign: 'center' }} className="white small-text blink"><span style={{ fontWeight: 'bold' }}>***</span>Limited 7 Day FREE Trial<span style={{ fontWeight: 'bold' }}>***</span></p> */}
          </div>
          <p style={{ textAlign: 'center' }} className="white small-text">${this.props.currentPrice}/month plus a <span style={{ fontWeight: 'bold' }}><em>one time</em></span> ${this.props.setupPrice} setup fee</p>
        </div>
        }
        {this.props.stripeSubStatus === 'canceled' &&
        <React.Fragment>
          {!bulkSubscriptionActive &&
          <div style={{ height: '100%' }}>
            <div style={{ height: '60%' }} className="d-flex flex-column justify-content-evenly">
              <p style={{ textAlign: 'center' }} className='main-font-style large-text white'>Hi,&nbsp;<span style={{ fontWeight: 'bold' }} >{this.props.user.idToken.payload.given_name}</span></p>
              <p style={{ textAlign: 'center' }} className='main-font-style med-text-profile white'>You do not currently have an active subscription. To have a license assigned to you, give the assignor your User ID. If you would like to instead purchase your own subscription, click the button below.</p>
              <Form>
                <Form.Group>
                  <Row>
                    <p className="white mb-0">User ID:</p>
                    <Col className="col-11" style={{ paddingRight: '0' }}>
                      <Form.Control readOnly defaultValue={this.props.portalId} value={this.props.portalId} style={{ textAlign: 'center' }} />
                    </Col>
                    <Col className="col-1" style={{ paddingLeft: '0' }}>
                      <Button ref={this.trigger} onClick={this.handleToolTip} data-id={this.props.portalId} className="white clipboard" variant="ternary" style={{ backgroundColor: '#252739', borderColor: '#252739', paddingLeft: '0' }}><IconContext.Provider value={{ color: '#23a455', size: '1.5rem', padding: '0', margin: '0' }}><div><IoClipboard /></div></IconContext.Provider></Button>
                      <Overlay target={this.trigger.current} show={this.state.toolTip} placement="left">
                        {(props) => (
                          <Tooltip {...props}>
                            Copied!
                          </Tooltip>
                        )}
                      </Overlay>
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </div>
            <div style={{ height: '30%' }} className="d-flex flex-column justify-content-evenly">
              <p style={{ textAlign: 'center' }} className="white large-text">- OR -</p>
              {!this.state.purchasingSubscription &&
              <Button onClick={this.purchaseSubscriptionAgain} className="white" style={{ width: '100%' }} variant="ternary">Purchase Subscription</Button>
              }
              {this.state.purchasingSubscription &&
              <Button className="white" style={{ width: '100%' }} variant="ternary"><Spinner as='span' size='sm' animation='border' role='status'/></Button>
              }
            </div>
            <p style={{ textAlign: 'center' }} className="white med-text">${this.props.currentPrice}/month</p>
          </div>
          }
          {bulkSubscriptionActive &&
          <div style={{ height: '100%' }}>
            <div style={{ height: '60%' }} className="d-flex flex-column justify-content-evenly">
              <p style={{ textAlign: 'center' }} className='main-font-style large-text white'>Hi,&nbsp;<span style={{ fontWeight: 'bold' }} >{this.props.user.idToken.payload.given_name}</span></p>
              <p style={{ textAlign: 'center' }} className='main-font-style med-text-profile white'>Your personal subscription is inactive, but you still have an active Bulk Licenses subscription. Manage those licenses by clicking the button below. </p>
            </div>
            <div style={{ height: '30%' }} className="d-flex flex-column justify-content-evenly">
              <Button variant="outline-ternary" size="sm" onClick={this.bulkLicensesModalOpen} className="mb-2">Manage Bulk Licenses</Button>
              {!this.state.purchasingSubscription &&
              <Button size="sm" onClick={this.purchaseSubscriptionAgain} className="white" style={{ width: '100%' }} variant="ternary">Purchase Personal Subscription</Button>
              }
              {this.state.purchasingSubscription &&
              <Button size="sm" className="white" style={{ width: '100%' }} variant="ternary"><Spinner as='span' size='sm' animation='border' role='status'/></Button>
              }
            </div>
            <p style={{ textAlign: 'center' }} className="white med-text">${this.props.currentPrice}/month</p>
          </div>
          }
        </React.Fragment>
        }
        <Modal show={this.state.modal} fullscreen={'sm-down'} onHide={this.modalClose}>
          <Modal.Header closeButton>
            <Modal.Title>7 Day Trial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="main-font-style">
              This subscription to Leads Genie includes a <span style={{ fontWeight: 'bold' }}>7 day FREE Trial</span>! Once you click the Subscribe button, you will be redirected to add a payment method. Your subscription will be active once you have successfully added your payment method; however, you will not be charged until your trial has ended. After your trial starts, to edit your active subscription or change your payment method, visit the <span style={{ fontWeight: 'bold' }}>Account Info</span> section of your <span style={{ fontWeight: 'bold' }}>Agent Portal.</span>
            </div>
            <div className="mt-3 mb-0 d-flex justify-content-center">
              {!this.state.purchasingSubscription &&
              <Button onClick={this.createPaymentIntent} style={{ color: 'white' }} variant="ternary">Subscribe</Button>
              }
              {this.state.purchasingSubscription &&
              <Button style={{ color: 'white' }} variant="ternary"><Spinner size='sm' as='span' animation='border' role='status'/></Button>
              }
            </div>
          </Modal.Body>
        </Modal>
        <BulkLicensesModal user={this.props.user} {...this.props} {...this.state} bulkLicensesModalClose={this.bulkLicensesModalClose}></BulkLicensesModal>
      </Container>
    )
  }
}

export default NoLicense
