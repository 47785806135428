import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

class ResetPassword extends React.Component {
  state = {

  }

  render () {
    return (
      <Form onSubmit={this.props.passwordResetVer} className='auth-form-form'>
        <Form.Group className={this.props.errorVerificationCode || this.props.verErrorMsg ? '' : 'auth-form-form-group'}>
          <Form.Control
            required
            id="verification-code"
            type="number"
            name="verificationCode"
            placeholder="Verification Code"
            value={this.props.verificationCode}
            onChange={this.props.handleChange}
            onFocus={this.props.emptyErrors}
          />
          <div>
            {this.props.errorVerificationCode &&
              <p className="auth-error-messages">Code should be at least 6 characters.</p>
            }
          </div>
          <div>
            {this.props.verErrorMsg &&
              <p className="auth-error-messages">{this.props.verErrorMsg}</p>
            }
          </div>
        </Form.Group>
        <Form.Group className={this.props.newPasswordError || this.props.newPasswordNError || this.props.newPasswordUError || this.props.newPasswordLError ? '' : 'auth-form-form-group'}>
          <Form.Control
            required
            id="new-password"
            type="password"
            name="newPassword"
            placeholder="New Password"
            value={this.props.newPassword}
            onChange={this.props.handleChange}
          />
          {this.props.newPasswordError &&
            <p className="auth-error-messages">{this.props.newPasswordError}</p>
          }
          {this.props.newPasswordNError &&
            <p className="auth-error-messages">{this.props.newPasswordNError}</p>
          }
          {this.props.newPasswordUError &&
            <p className="auth-error-messages">{this.props.newPasswordUError}</p>
          }
          {this.props.newPasswordLError &&
            <p className="auth-error-messages">{this.props.newPasswordLError}</p>
          }
        </Form.Group>
        <Form.Group className="auth-form-form-group">
          <Button disabled={!this.props.validation} type="submit" style={{ width: '100%' }} className="btn-ternary white">Next</Button>
        </Form.Group>
      </Form>
    )
  }
}

export default ResetPassword
