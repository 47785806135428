import React from 'react'
import axios from 'axios'
import apiUrl from '../../apiConfig'
// import { CognitoUser } from 'amazon-cognito-identity-js'
import UserPool from './../auth/SignUp2/UserPool'
// import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

class AccountInfoModal extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    firstNameError: '',
    lastNameError: '',
    oldPassword: '',
    newPassword: '',
    oldPasswordError: '',
    newPasswordCError: '',
    newPasswordUError: '',
    newPasswordLError: '',
    newPasswordNError: '',
    validation1: '',
    validation2: '',
    stripeCustomerId: '',
    purchasedLicense: ''
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        this.setState({ purchasedLicense: res.data.portals[0].portalAccess })
        if (res.data.portals[0].stripe) {
          this.setState({ stripeCustomerId: res.data.portals[0].stripe.stripeCusId })
        }
      })
    this.setState({ firstName: this.props.user.idToken.payload.given_name, lastName: this.props.user.idToken.payload.family_name })
  }

  handleErrors = (event) => {
    if (event.target.name === 'firstName') {
      if (event.target.value.length < 3) {
        this.setState({ firstNameError: '*Minimum 3 Characters' },
          () => {
            this.validation1()
          })
      } else {
        this.setState({ firstNameError: '' },
          () => {
            this.validation1()
          })
      }
    } else if (event.target.name === 'lastName') {
      if (event.target.value.length < 3) {
        this.setState({ lastNameError: '*Minimum 3 Characters' },
          () => {
            this.validation1()
          })
      } else {
        this.setState({ lastNameError: '' },
          () => {
            this.validation1()
          })
      }
    } else if (event.target.name === 'newPassword') {
      const string = event.target.value
      const upperRegex = /(?=.*[A-Z])/
      const lowerRegex = /(?=.*[a-z])/
      const numberRegex = /(?=.*[0-9])/
      if (string.length < 8) {
        this.setState({ newPasswordCError: '*Minimum 8 Characters' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ newPasswordCError: '' },
          () => {
            this.validation2()
          })
      }
      if (string.match(upperRegex)) {
        this.setState({ newPasswordUError: '' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ newPasswordUError: '*Must Include Uppercase Letter' },
          () => {
            this.validation2()
          })
      }
      if (string.match(lowerRegex)) {
        this.setState({ newPasswordLError: '' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ newPasswordLError: '*Must Include Lowercase Letter' },
          () => {
            this.validation2()
          })
      }
      if (string.match(numberRegex)) {
        this.setState({ newPasswordNError: '' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ newPasswordNError: '*Must Include a Number' },
          () => {
            this.validation2()
          })
      }
    }
  }

  validation1 = () => {
    if (this.state.firstName && this.state.lastName && !this.state.firstNameError && !this.state.lastNameError) {
      this.setState({ validation1: true })
    } else {
      this.setState({ validation1: false })
    }
  }

  validation2 = () => {
    if (this.state.newPassword && this.state.oldPassword && !this.state.newPasswordCError && !this.state.newPasswordUError && !this.state.newPasswordLError && !this.state.newPasswordNError) {
      this.setState({ validation2: true })
    } else {
      this.setState({ validation2: false })
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value },
      () => {
        this.handleErrors(event)
      })
  }

  handlePWSubmit = (event) => {
    event.preventDefault()
    this.setState({ newPassword: '', oldPassword: '' })
    const user = UserPool.getCurrentUser()
    user.getSession((err, session) => {
      if (session) {
        user.changePassword(this.state.oldPassword, this.state.newPassword, (err, result) => {
          if (err) {
            this.setState({ oldPasswordError: '*Incorrect Password' })
          } else {
            // console.log('success', result)
            this.props.msgAlert({ heading: 'Password Successfully Changed', variant: 'secondary' })
          }
        })
      } else {
        return err
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const attributes = [{ Name: 'family_name', Value: this.state.lastName }, { Name: 'given_name', Value: this.state.firstName }]
    const user = UserPool.getCurrentUser()
    if (user) {
      if (this.state.firstName !== this.props.user.idToken.payload.given_name || this.state.lastName !== this.props.user.idToken.payload.family_name) {
        if (this.state.validation1) {
          user.getSession((err, session) => {
            if (session) {
              // console.log(session)
              user.updateAttributes(attributes, (err, data) => {
                if (err) {
                  console.error(err)
                } else {
                  // console.log(data.user)
                }
              })
            } else {
              console.error(err)
            }
          })
        }
      }
    }
  }

  toBilling = () => {
    axios({
      url: apiUrl + '/create-portal-session',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        customer: this.state.stripeCustomerId
      }
    })
      // .then(res => console.log(res))
      .then(res => window.location.replace(res.data.url))
      .catch(err => console.error(err))
  }

  render () {
    return (
      <React.Fragment>
        <Modal show={this.props.accountInfoModal} fullscreen={'sm-down'} onHide={this.props.accountInfoModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Account Information</Modal.Title>
          </Modal.Header>
          <div className="d-flex justify-content-around">
            {this.state.purchasedLicense &&
            <Button onClick={this.toBilling} style={{ margin: '.5rem 0 0 .5rem' }} variant="ternary" className="white">Billing Portal</Button>
            }
            {this.state.purchasedLicense &&
            <Button onClick={this.props.bulkLicensesModalOpen} style={{ margin: '.5rem 0 0 .5rem' }} variant="outline-ternary" className="ternary">Bulk Licenses</Button>
            }
          </div>
          <Modal.Body>
            <div style={{ height: '100%' }} className="d-flex flex-column justify-content-around">
              <div>
                <p style={{ fontWeight: 'bold' }} className="main-font-style">User Information:</p>
                <div>
                  <Form className="mb-1">
                    <Form.Group as={Row} className={this.state.firstNameError ? '' : 'auth-form-form-group'}>
                      <Form.Label className="col-4 d-flex align-items-center mb-0">First Name</Form.Label>
                      <Col className="col-8">
                        {/* <Form.Control
                          id="first-name"
                          type="input"
                          name="firstName"
                          value={this.state.firstName}
                          onChange={this.handleChange}
                          onBlur={this.handleSubmit}
                        /> */}
                        <Form.Control plaintext readOnly defaultValue={this.state.firstName} />
                        <div>
                          {this.state.firstNameError &&
                            <p className="portal-form-error-messages">&nbsp;{this.state.firstNameError}</p>
                          }
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                  <Form className="mb-1">
                    <Form.Group as={Row} className={this.state.lastNameError ? '' : 'auth-form-form-group'}>
                      <Form.Label className="col-4 d-flex align-items-center mb-0">Last Name</Form.Label>
                      <Col className="col-8">
                        {/*  <Form.Control
                          id="last-name"
                          type="input"
                          name="lastName"
                          value={this.state.lastName}
                          onChange={this.handleChange}
                          onBlur={this.handleSubmit}
                        /> */}
                        <Form.Control plaintext readOnly defaultValue={this.state.lastName} />
                        <div>
                          {this.state.lastNameError &&
                            <p className="portal-form-error-messages">&nbsp;{this.state.lastNameError}</p>
                          }
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                  <Form className="mb-1">
                    <Form.Group as={Row}>
                      <Form.Label className="col-4 d-flex align-items-center mb-0">Email</Form.Label>
                      <Col className="col-8">
                        <Form.Control plaintext readOnly defaultValue={this.props.user.idToken.payload.email}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </div>
              {this.props.assignor &&
              <div>
                <p style={{ fontWeight: 'bold' }} className="main-font-style mb-0">License Information:</p>
                <Form className="">
                  <Form.Group as={Row}>
                    <Form.Label className="col-4 d-flex align-items-center mb-0">Assignor</Form.Label>
                    <Col className="col-8">
                      <Form.Control plaintext readOnly defaultValue={this.props.assignor}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
              }
              {!this.props.assignor &&
              <div>
                <p style={{ fontWeight: 'bold' }} className="main-font-style mb-0">Primary Subscription:</p>
                <Form className="">
                  <Form.Group as={Row}>
                    <Form.Label className="col-4 d-flex align-items-center mb-0">Status</Form.Label>
                    <Col className="col-8">
                      <Form.Control plaintext readOnly defaultValue={this.props.stripeSubStatus}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
              }
              <div>
                <p style={{ fontWeight: 'bold' }} className="main-font-style">Change Password:</p>
                <div>
                  <Form onSubmit={this.handlePWSubmit}>
                    <Form.Group className={this.state.oldPasswordError ? 'mb-1' : 'auth-form-form-group mb-1'}>
                      <Form.Control
                        id="password"
                        type="password"
                        name="oldPassword"
                        value={this.state.oldPassword}
                        placeholder="Current Password"
                        onChange={this.handleChange}
                        onFocus={() => this.setState({ oldPasswordError: '' })}
                      />
                      <div>
                        {this.state.oldPasswordError &&
                          <p className="portal-form-error-messages">&nbsp;{this.state.oldPasswordError}</p>
                        }
                      </div>
                    </Form.Group>
                    <Form.Group className={this.state.newPasswordCError || this.state.newPasswordLError || this.state.newPasswordNError || this.state.newPasswordUError ? 'mb-1' : 'auth-form-form-group mb-1'}>
                      <Form.Control
                        id="newpassword"
                        type="password"
                        name="newPassword"
                        value={this.state.newPassword}
                        placeholder="New Password"
                        onChange={this.handleChange}
                      />
                      <div>
                        {this.state.newPasswordCError &&
                          <p className="portal-form-error-messages">&nbsp;{this.state.newPasswordCError}</p>
                        }
                      </div>
                      <div>
                        {this.state.newPasswordLError &&
                        <p className="portal-form-error-messages">&nbsp;{this.state.newPasswordLError}</p>
                        }
                      </div>
                      <div>
                        {this.state.newPasswordUError &&
                        <p className="portal-form-error-messages">&nbsp;{this.state.newPasswordUError}</p>
                        }
                      </div>
                      <div>
                        {this.state.newPasswordNError &&
                        <p className="portal-form-error-messages">&nbsp;{this.state.newPasswordNError}</p>
                        }
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Button type='submit' disabled={!this.state.validation2} style={{ width: '100%' }} variant="ternary" className="white">Submit</Button>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AccountInfoModal
