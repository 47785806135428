import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import signImage from './../../../images/sign.png'

class Container1 extends React.Component {
  state = {

  }

  render () {
    return (
      <Container fluid className="landing-sect3-container">
        <Container className="p-0">
          <Row>
            <Col className="landing-sect3-cont-rows-images" xs={4}>
              <img src={signImage} className="landing-sect3-cont1-img" />
            </Col>
            <Col xs={8} className="d-flex align-content-center">
              <div className="landing-sect3-cont-rows-text">
                <p className="small-text secondary-font-color mb-0" style={{ verticalAlign: 'middle' }}>
                  Place your unique QR code on a &apos;Featured Open Houses&apos; sign
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

export default Container1
