import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { IoChevronBack } from 'react-icons/io5'
import { IconContext } from 'react-icons'
import { withRouter } from 'react-router-dom'

class Step2 extends React.Component {
  state = {

  }

  render () {
    return (
      <React.Fragment>
        {/* <Form.Group className={this.props.userNameError ? '' : 'auth-form-form-group'}>
          <Form.Control
            required
            id="user-name"
            type="input"
            name="userName"
            value={this.props.userName}
            placeholder="User Name (Permanent)"
            onChange={this.props.handleChange}
          />
          <div>
            {this.props.userNameError &&
            <p className="auth-error-messages">&nbsp;{this.props.userNameError}</p>
            }
          </div>
          </Form.Group> */}
        <Form.Group className={this.props.passwordError || this.props.passwordLError || this.props.passwordUError || this.props.passwordNError ? '' : 'auth-form-form-group'}>
          <Form.Control
            required
            id="password"
            type="password"
            name="password"
            placeholder="Password"
            value={this.props.password}
            onChange={this.props.handleChange}
          />
          <div>
            {this.props.passwordError &&
            <p className="auth-error-messages">&nbsp;{this.props.passwordError}</p>
            }
          </div>
          <div>
            {this.props.passwordLError &&
            <p className="auth-error-messages">&nbsp;{this.props.passwordLError}</p>
            }
          </div>
          <div>
            {this.props.passwordUError &&
            <p className="auth-error-messages">&nbsp;{this.props.passwordUError}</p>
            }
          </div>
          <div>
            {this.props.passwordNError &&
            <p className="auth-error-messages">&nbsp;{this.props.passwordNError}</p>
            }
          </div>
        </Form.Group>
        <Form.Group className={this.props.pwConfirmError ? '' : 'auth-form-form-group'}>
          <Form.Control
            required
            id="pw-confirm"
            type="password"
            name="pwConfirm"
            placeholder="Confirm Password"
            value={this.props.pwConfirm}
            onChange={this.props.handleChange}
          />
          <div>
            {this.props.pwConfirmError &&
            <p className="auth-error-messages">&nbsp;{this.props.pwConfirmError}</p>
            }
          </div>
          <div>
            {this.props.duplicateError &&
            <p className="auth-error-messages">That email address is already registered. <span><a href='' onClick={() => this.props.history.push('/sign-in')} style={{ fontWeight: 'bold', color: 'white', textDecoration: 'none', display: 'inline-block' }}>Sign In</a></span></p>
            }
          </div>
        </Form.Group>
        <Form.Group className="auth-form-form-group">
          <Row className="g-0">
            <Col className="col-3 g-0" style={{ paddingRight: '0' }}>
              <Button onClick={this.props.backButtons} className="btn-primary white" style={{ width: '100%', borderRadius: '.25rem 0 0 .25rem', borderColor: '#23a455' }}>
                <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                  <IconContext.Provider value={{ className: 'auth-back-img' }}>
                    <div>
                      <IoChevronBack></IoChevronBack>
                    </div>
                  </IconContext.Provider>
                </div>
              </Button>
            </Col>
            <Col className="col-9 g-0" style={{ paddingLeft: '0' }}>
              <Button type='submit' disabled={!this.props.validation2} style={{ width: '100%', borderRadius: '0 .25rem .25rem 0' }} className="btn-ternary white">Finish</Button>
            </Col>
          </Row>
        </Form.Group>
      </React.Fragment>
    )
  }
}

export default withRouter(Step2)
