import React from 'react'
// import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import apiUrl from '../../apiConfig'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { IoChevronForward } from 'react-icons/io5'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { IconContext } from 'react-icons'

class BrokerInfo extends React.Component {
  state = {
    view: '',
    createid: '',
    editid: '',
    mlsid: '',
    validated: '',
    mls: '',
    mlsState: '',
    error: '',
    memberInfo: '',
    originalMlsId: '',
    loaded: false
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.view !== prevState.view) {
      this.props.viewProfile('mlsIdComponent', this.state.view)
    }
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.portals[0].mlsinfo.mlsid) {
          this.setState({ mlsid: res.data.portals[0].mlsinfo.mlsid, originalMlsId: res.data.portals[0].mlsinfo.mlsid, view: 'edit' })
        }
        this.setState({ mls: res.data.portals[0].mlsinfo.mls, mlsState: res.data.portals[0].mlsinfo.mlsState })
      })
      .then(() => this.setState({ loaded: true }))
  }

  handleChange = (event) => {
    const value = event.target.value
    this.setState({ [event.target.name]: value },
      () => {
        if (this.state.mlsid.length > 0) {
          this.setState({ validated: true })
        } else {
          this.setState({ validated: false })
        }
      })
  }

  addAdminMlsId = () => {
    if (this.state.mlsid === this.state.originalMlsId) {
      this.setState({ view: 'edit' })
      return
    }
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          mlsinfo: {
            mls: this.state.mls,
            mlsid: this.state.mlsid,
            mlsState: this.state.mlsState,
            brokerid: '987654321',
            brokerOfficeName: 'Admin Brokers Team',
            brokerOfficeFranchise: 'The Broker Franchise',
            brokerOfficePhone: '555-555-5555'
          },
          location: {
            city: '',
            state: '',
            zip: '',
            county: ''
          },
          zipcodes: {
            zip1: '',
            zip2: '',
            zip3: ''
          },
          featuredoh: {
            id: '123456789'
          }
        }
      }
    })
      .then(this.setState({ validated: '', view: 'edit', originalMlsId: this.state.mlsid }))
      .then(this.props.getMlsId)
  }

  addMlsId = () => {
    // console.log(this.state.memberInfo.MemberMlsId, 'member mls id')
    if (this.state.originalMlsId === this.state.memberInfo.MemberMlsId) {
      this.setState({ view: 'edit' })
      return
    }
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          mlsinfo: {
            mls: this.state.mls,
            mlsid: this.state.mlsid,
            mlsState: this.state.mlsState,
            brokerid: this.state.memberInfo.Office.OfficeMlsId,
            brokerOfficeName: this.state.memberInfo.Office.OfficeName,
            brokerOfficeFranchise: this.state.memberInfo.Office.FranchiseAffiliation,
            brokerOfficePhone: this.state.memberInfo.Office.OfficePhone
          },
          location: {
            city: '',
            state: '',
            zip: '',
            county: ''
          },
          zipcodes: {
            zip1: '',
            zip2: '',
            zip3: ''
          },
          featuredoh: {
            id: ''
          }
        }
      }
    })
      .then(this.setState({ validated: false, view: 'edit', originalMlsId: this.state.mlsid }))
      .then(this.props.getMlsId)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (this.state.mlsid === '123456789') {
      this.addAdminMlsId()
      return
    }
    axios({
      url: apiUrl + '/member',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        mlsId: this.state.mls,
        mlsMemberId: this.state.mlsid
      }
    })
      .then(res => {
        if (res.data.member['@odata.count'] === 0) {
          this.setState({ error: 'Please double check your MLS Id and try again.', validated: '', mlsid: this.state.originalMlsId })
        } else {
          this.setState({ memberInfo: res.data.member.value[0] })
          this.addMlsId()
        }
      })
      .catch(() => {
        this.setState({ error: 'Please double check your MLS Id and try again.', validated: '' })
      })
  }

  render () {
    let jsx
    if (!this.state.view) {
      jsx = (
        <React.Fragment>
          {!this.state.createid && !this.state.editid && this.state.loaded &&
          <div style={{ width: '100%' }} onClick={() => this.setState({ createid: true })}>
            <p style={{ textAlign: 'center' }} className="main-font-style med-text white" >Start by inputting your {this.state.mls} MLS Id</p>
            <p style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'underline' }} className="main-font-style small-text red">Add Your MLS Id</p>
          </div>
          }
          {this.state.createid &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <Form.Control
                  required
                  placeholder="Mls ID"
                  onChange={this.handleChange}
                  onFocus={() => this.setState({ error: '', mlsid: '', validated: '' })}
                  value={this.state.mlsid}
                  name="mlsid"
                />
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
              {this.state.error &&
              <p className="auth-error-messages">{this.state.error}</p>
              }
            </Form>
          </div>
          }
          {this.state.editid &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <Form.Control
                  required
                  placeholder="Mls ID"
                  onChange={this.handleChange}
                  value={this.state.mlsid}
                  name="mlsid"
                />
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
              {this.state.error &&
              <p className="auth-error-messages">{this.state.error}</p>
              }
            </Form>
          </div>
          }
        </React.Fragment>
      )
    } else {
      jsx = (
        <div style={{ width: '100%' }}>
          <p onClick={() => this.setState({ view: '', createid: '', editid: 'true', validated: 'true' })} style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'none' }} className="main-font-style small-text variable-main-font-color"><span className="xs-text" style={{ fontWeight: 'bold' }}>mls id:</span><br></br><span className="variable-checks">&#10004;</span>&nbsp;&nbsp;{this.state.mlsid}&nbsp;<MdOutlineModeEditOutline></MdOutlineModeEditOutline></p>
        </div>
      )
    }
    return (
      <React.Fragment>
        {jsx}
      </React.Fragment>
    )
  }
}

export default BrokerInfo
