import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Document from './../../documents/EULA.pdf'

class EulaModal extends React.Component {
  state = {
  }

  render () {
    return (
      <React.Fragment>
        <Modal show={this.props.eulaModal} fullscreen={'sm-down'} onHide={this.props.eulaModalClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: 'bold' }} className="main-font-style">End User License Agreement</Modal.Title>
          </Modal.Header>
          <Modal.Body className="main-font-style">
            <p className='main-font-style' style={{ textAlign: 'justify' }}>
            This End User Licensing Agreement for Leads Genie, L.L.C. – and Services Agreement (&quot;Agreement&quot;) is by and between Leads Genie, L.L.C. –DBA Leads Genie and Open House Genie (and includes all products and entities related to such business which shall be collectively known as &quot;Leads Genie&quot; and here in known as Leads Genie) all of which are part of this Florida Corporation and You, your heirs, agents, staff, successors, and assigns (collectively &quot;You&quot;), and is made effective as of the date of electronic execution. This Agreement sets forth the terms and conditions of Your use of the Leads Genie application/website collectively known as (&quot;Software&quot;) and all other documents, videos, products (&quot;Services&quot;) and explains Your rights as related to Your limited exclusive use of these products and the collective obligation by Leads Genie to You and Your obligations to Leads Genie in relation to the terms of use of this Software and Services You purchase (which is defined as continuous use of during Your term as a subscriber).  These rights are non-transferable and exclusively for the use of the member(s) who are appropriately registered with Leads Genie.  Any other use shall be considered a violation of this agreement and it is further acknowledged that the materials are copyrighted and unauthorized use is prohibited and no duplication shall be permitted without express written permission of  Leads Genie. <br></br><br></br>

This Agreement as well as any additional Leads Genie policies, together with all modifications thereto, constitute the complete and exclusive agreement between You and Leads Genie concerning Your use of Leads Genie&apos;s Software and Services, and supersede and govern all prior proposals, agreements, or other communications. All Leads Genie policies and agreements specific to particular Software and Service are incorporated herein and made part of this Agreement by reference, including the dispute procedure. By purchasing Leads Genie&apos;s Software or Services, You acknowledge that You have read, understood, and agree to be bound by all terms and conditions of this Agreement and any other policies or agreements made part of this Agreement by reference, as well as any new, different or additional terms, conditions or policies which Leads Genie may establish from time to time, and any agreements that Leads Genie is currently bound by or will be bound by in the future. You may view the latest versions of these Agreements on this here.<br></br><br></br>

In addition to transactions entered into by You on Your behalf, You also agree to be bound by the terms of this Agreement for transactions entered into by anyone who uses the Software and Services which is an employee of Yours at the time of execution of this agreement with Leads Genie, whether or not the actions were Your behalf.<br></br><br></br>

 A. GENERAL TERMS APPLICABLE TO ALL SOFTWARE AND SERVICES<br></br><br></br>

1. TERM OF AGREEMENT; MODIFICATIONS.
You agree that Leads Genie may modify this Agreement and the Services from time to time. You agree to be bound by any changes Leads Genie may reasonably make to this Agreement when such changes are made. If You have purchased Services or Software from Leads Genie, the term of this Agreement shall continue in full force and effect as long as You pay and take advantage of and use the Software or Services. In the event You terminate Your usage, Leads Genie will not refund any amounts You have paid. You agree that Leads Genie shall not be bound by any representations made by third parties who You may use to purchase Services from Leads Genie, and that any statement of a general nature, which may be posted on Leads Genie&apos;s web site or contained in Leads Genie&apos;s promotional materials, will not bind Leads Genie. Leads Genie may, at times, offer certain promotions with different charges and features.

You agree that You will be responsible for notifying Leads Genie should You desire to terminate Your use of Leads Genie&apos;s Services. Notification of Your intent to terminate must be provided to Leads Genie no earlier than ten (10) days prior to Your billing date but no later than three (3) days prior to Your billing date.
              <br></br><br></br>
2. ACCURATE INFORMATION.
You agree to maintain accurate information by providing updates to Leads Genie, as needed, while You are using Leads Genie&apos;s Services. You agree You will notify Leads Genie within five (5) business days when any change of the information You provided as part of the application and/or registration process changes. Failure by You, for whatever reason, to respond within five (5) business days to any inquiries made by Leads Genie to determine the validity of information provided by You will constitute a material breach of this Agreement.

You agree that Leads Genie may use and rely on any such information provided by You for all purposes in connection with Your Services, subject to Leads Genie&apos;s Privacy Policy. If You provide any information that is inaccurate, not current, false, misleading or incomplete, or if Leads Genie has reasonable grounds to suspect that Your information is inaccurate, not current, false, misleading or incomplete, Leads Genie has the absolute right, in its sole discretion, to terminate its Services and close Your account.
              <br></br><br></br>
3. PRIVACY.
You can view Leads Genie&apos;s Privacy Policy here, which is incorporated herein by reference, as it is applicable to all Leads Genie Software and Services. The Privacy Policy provides Your rights and Leads Genie, L.L.C. – DBA -Leads Genie&apos;s responsibilities with regard to Your personal information. Leads Genie, L.L.C. – DBA -Leads Genie&apos;s will not use Your information in any way inconsistent with the purposes of doing business together and offering further services to potentially be provided that may be desirable to You as defined by Leads Genie, L.L.C. DBA- Leads Genie.
              <br></br><br></br>
4. ACCOUNT SECURITY.
You agree You are entirely responsible for maintaining the confidentiality of Your customer number/login, password, credit card number, and shopper PIN (collectively, the &quot;Account Access Information&quot;). For security purposes, Leads Genie recommends that You change Your password at least every six (6) months, for every Leads Genie account, subject to Leads Genie&apos;s password guidelines. You agree You&apos;re entirely responsible for any and all activities that occur under Your account and the Accounts of your employees. You agree to notify Leads Genie immediately of any unauthorized use of Your account or any other breach of security. You agree Leads Genie will not be liable for any loss that You may incur as a result of someone else using Your Account Access Information, either with or without Your knowledge. You further agree You could be held liable for losses incurred by Leads Genie or another party due to someone else using Your Account Access Information. You should keep Account Access Information in a secure location and take precautions to prevent others from gaining access to Your Account Access Information. You agree that You will be responsible for all activity in Your account, whether initiated by You, or by others on Your behalf, or by any other means. Leads Genie specifically disclaims liability for any activity in Your account, whether authorized by You or not.
              <br></br><br></br>
5. NO UNLAWFUL CONDUCT OR IMPROPER USE.
As a condition of Your use of Leads Genie&apos;s Software and Services, You agree not to use them for any purpose that is unlawful or prohibited by these terms and conditions, and You agree to comply with any applicable local, state, federal and international laws, government rules or requirements. You agree You will not be entitled to a refund of any fees paid to Leads Genie if, for any reason, Leads Genie takes corrective action with respect to Your improper or illegal use of its Services.
Leads Genie reserves the right at all times to disclose any information as Leads Genie deems necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in Leads Genie&apos;s sole discretion.
If You have purchased Services, Leads Genie has no obligation to monitor Your use of the Services. Leads Genie reserves the right to review Your use of the Services and to cancel the Services in its sole discretion. Leads Genie reserves the right to terminate Your access to the Services at any time, without notice, for any reason whatsoever.
              <br></br><br></br>
Leads Genie reserves the right to terminate Services if Your usage of the Services results in, or is the subject of, legal action or threatened legal action, against Leads Genie or any of its affiliates or partners, without consideration for whether such legal action or threatened legal action is eventually determined to be with or without merit.
Except as set forth below, Leads Genie may also cancel Your use of the Services, after thirty (30) days, if You are using the Services, as determined by Leads Genie in its sole discretion, in association with any activities that are found to be contradictory to Leads Genie, L.L.C. – DBA -Leads Genie&apos;s business philosophies. Morally objectionable activities will include, but not be limited to:
              <br></br><br></br>
&#x2022; activities designed to defame, embarrass, harm, abuse, threaten, slander or harass third parties;
              <br></br>
&#x2022; activities prohibited by the laws of the United States and/or foreign territories in which You conduct business;
              <br></br>
&#x2022; activities designed to encourage unlawful behavior by others, such as hate crimes, terrorism and child pornography;
              <br></br>
&#x2022; activities that are tortuous, vulgar, obscene, invasive of the privacy of a third party, racially, ethnically, or otherwise objectionable;
              <br></br>
&#x2022; activities designed to impersonate the identity of a third party;
              <br></br>
&#x2022; illegal access to other computers or networks
              <br></br>
&#x2022; distribution of Internet viruses or similar destructive activities;
              <br></br>
&#x2022; activities designed to harm or use unethically minors in any way; and
              <br></br>
&#x2022; activities associated with the sale or distribution of prescription medication without a valid prescription.
              <br></br><br></br>

Notwithstanding anything to the contrary herein, in the event Leads Genie cancels Your Services during the first thirty (30) days after You will receive no refund. You agree You will not be entitled to a refund of any fees paid to Leads Genie if, for any reason, Leads Genie takes corrective action with respect to Your improper or illegal use of its Services.
              <br></br><br></br>
6. INTELLECTUAL PROPERTY.
You agree Leads Genie or its licensor holds all rights, title and interest in all Software and Services and all intellectual property, including other rights related to intangible property, unless otherwise indicated. You acknowledge that no title or interest in such Intellectual Property Rights is being transferred to You and You agree to make no claim of interest in any such Services or Software.

You understand and agree that all content and materials contained with in the site and distributed via the Software and Services (including but not limited to Leads Genie, L.L.C. – DBA -Leads Genie&apos;s web site, and any affiliated web sites, are protected by the various copyright,  trademark(s), service mark and trade secret laws of the United States, as well as any other applicable proprietary rights and laws, and that Leads Genie or its licensor expressly reserves its rights in and to all such content and materials. You further understand and agree that You are prohibited from using, in any manner whatsoever, any of the content or materials without the express written permission of Leads Genie.  Further,  No license or right under any copyright, patent, trademark, service mark or other proprietary right or license is granted to You or conferred upon You by this Agreement or otherwise for the resale, repurposing, or redistribution for any reason without express written consent.
              <br></br><br></br>
7. USE OF Leads Genie SOFTWARE/SERVICES.
If You have applied for and been granted access to via Subscription for the use of the Software and/or Services of Leads Genie, L.L.C. – DBA -Leads Genie&apos;s, then Leads Genie, L.L.C. – DBA -Leads Genie&apos;s grants You a limited, non-exclusive, nontransferable and non-assignable license to use the software for such purposes as are ordinary and customary. You are free to use the software on any computer, but not on two (2) or more computers at one time.

Leads Genie reserves all rights to the Software and Services. The Software and any copies You are authorized to make are the intellectual property of Leads Genie for Your use during the term of Your subscription. This Agreement does not grant You any ownership rights in the Software and Services (or materials associated with such Software and Services) and all rights are reserved.

In the event YOU as a &quot;Participant&quot; subscriber (defined as the individual or as a company paying multiple subscriptions on behalf of others) agree to adhere to the limitations of quality of membership level(s) as shown on the Membership page of the prospectboomerang.com site.
Leads Genie PROVIDES THIS SOFTWARE &quot;AS IS&quot; WITHOUT WARRANTY OF ANY KIND EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.

8. FEES AND PAYMENT.
As consideration for the Software or Services purchased by You and provided to You by Leads Genie, You agree to pay Leads Genie at the time You order and monthly as disclosed on the website. All fees are due immediately and reoccurring as disclosed and are non-refundable unless otherwise expressly noted, even if Your Services are suspended, terminated, or transferred prior to the end of the Services term. Leads Genie expressly reserves the right to modify pricing through email notification and/or notice on its web site. Payment may be made by You by Express Checkout if available, by a valid credit card, or debit card except as prohibited in any product specific agreement.
Confirmation of your order and the choice of your payment selection will be sent to the email address provided. Your Payment Method on file must be kept valid if You have any active products in Your account. If You signed up for a monthly payment plan, Your monthly billing date will be determined based on the day of the month You purchase the Services unless that date falls after the 28th of the month, in which case Your billing date will be the 28th of each month. If You signed up for an annual payment plan, and You elected the automatic renewal option, Leads Genie will automatically renew Your Services when they come up for renewal and will take payment from the Payment Method You have on file with Leads Genie, at Leads Genie&apos;s then current rates.

If for any reason Leads Genie is unable to charge Your Payment Method for the full amount owed Leads Genie for the Services provided, or if Leads Genie is charged a penalty for any fee it previously charged to Your Payment Method, You agree that Leads Genie may pursue all available remedies in order to obtain payment. If You pay by credit card and if for any reason Leads Genie is unable to charge Your credit card with the full amount of the Services provided, or if Leads Genie is charged back for any fee it previously charged to the credit card You provided, You agree that Leads Genie may pursue all available remedies in order to obtain payment. You agree that among the remedies Leads Genie may pursue in order to effect payment, shall include but will not be limited to, immediate cancellation without notice to You of all subscription included on that payment method.  Leads Genie reserves the right to charge a reasonable administrative fee for administrative tasks outside the scope of its regular Services, including additional costs that it may incur in providing the Services and pass along to You. You may change Your Payment Method at any time by logging into Your Account Manager. For Leads Genie Marketplace orders where payment has not been received or Leads Genie has received a chargeback for any portion of the transaction, You understand that in addition to any administrative fees Leads Genie may charge for the handling of the chargeback and also may also pursue all available lawful remedies to collect payment plus applicable fees.

While all transactions are processed in US dollars, Leads Genie may provide an estimated conversion price to currencies other than US dollars. You acknowledge and agree that the pricing displayed during the checkout process is an estimate. Due to potential slight time delays between actual purchase and the payment settlement, the actual price charged may fluctuate. Leads Genie makes no representations or warranties that the estimated price will be the same or substantially similar to the actual price You will pay and You waive any and all claims based upon any discrepancy between the estimate and the actual price and the price paid and any subsequent refund. In addition, You may be charged VAT, GST or other localized taxes, based on the country indicated in Your billing address section.
              <br></br>
 (A) Pay by PayPal/Stripe/EFT (collectively &quot;elected payment type&quot;) –
By using Leads Genie&apos;s pay by elected payment type option (such as &quot;PayPal&quot;), You can purchase Leads Genie Software and Services. In consideration for the Software and Services purchased by You and provided to You by Leads Genie, You agree to allow elected payment type to debit the full amount of Your purchase/subscription from Your Account ( from the credit card(s), debit card(s), bank account(s), or other allowed payment method(s) linked to Your Funding Source&quot;).
It is Your responsibility to keep Your Account and Funding Source current and funded, and Your Account backed by a valid credit card if applicable. You agree that Leads Genie will not be responsible for payments that fail to go through as a result of Your Funding Source no longer existing, or holding insufficient funds. If for any reason we are unable to withdraw the full amount owed for Your purchase, You agree that the funding source (elected payment type) and Leads Genie may pursue all available remedies in order to obtain payments contractually owed. You agree that if the transaction is returned unpaid, You will pay a service charge of twenty-five dollars ($25.00) or the maximum amount allowed by law, which may be debited from Your Account or PayPal Funding Source.
By clicking the box labeled &quot;I agree&quot; to the terms of the payment option, You authorize a debit of the full amount of Your purchase from Your Funding Account or elected payment type.
              <br></br><br></br>
9. REPRESENTATIONS AND WARRANTIES.
You, or the individuals who electronically execute this Agreement on behalf of You hereby represent and warrant that they have the right, power, legal capacity and appropriate authority to enter into this Agreement, and that they own and have not transferred to any other person or entity any of the rights, claims or interests that are the subject of this Agreement. You represent and warrant that You are eighteen (18) years of age or older, or that You have an agent authorized by law to represent You who is eighteen (18) years of age or older who is entering into this Agreement on Your behalf. You warrant that each action You make is being done so in good faith and that You have no knowledge of it infringing upon or conflicting with the legal rights of a third party or a third party&apos;s trademark or trade name.

Leads Genie expressly reserves the right to deny, cancel or transfer any subscription that it deems necessary, in its discretion, to protect the integrity and stability of the registry, to comply with any applicable laws, government rules or requirements, requests of law enforcement, in compliance with any dispute resolution process, or to avoid any liability, civil or criminal, on the part of Leads Genie, as well as its affiliates, subsidiaries, officers, directors and employees. Leads Genie also reserves the right to freeze a subscription during resolution of a dispute.
              <br></br><br></br>
10. LIMITATION OF LIABILITY .
IN NO EVENT SHALL Leads Genie BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, INCLUDING LOSS OF PROFIT OR GOODWILL, FOR ANY MATTER, WHETHER SUCH LIABILITY IS ASSERTED ON THE BASIS OF CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF WARRANTIES, EITHER EXPRESS OR IMPLIED, ANY BREACH OF THIS AGREEMENT OR ITS INCORPORATED AGREEMENTS AND POLICIES YOUR INABILITY TO USE THE SOFTWARE OR SERVICES, YOUR LOSS OF DATA OR FILES OR OTHERWISE, EVEN IF CONTENT BOOMERANG HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
Some states may not allow such a broad exclusion or limitation on liability for damages as contained herein. In such states, Leads Genie&apos;s liability is limited to the full extent permitted by law. You agree that in no event shall Leads Genie&apos;s maximum aggregate liability exceed the total amount paid by You for the particular Software or Service in dispute purchased from Leads Genie.
              <br></br><br></br>
11. DISCLAIMER OF WARRANTIES.
CONTENT BOOMERANG EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE TYPE OF REAL ESTATE MODEL OR COMPANY, AND NON-INFRINGEMENT. SUCH SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. CONTENT BOOMERANG MAKES NO WARRANTY THAT ITS SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. CONTENT BOOMERANG DOES NOT WARRANT, NOR MAKE ANY REPRESENTATIONS REGARDING THE USE, OR RESULTS OF, ANY OF THE SERVICES IT PROVIDES, IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE.
              <br></br><br></br>
12. INDEMNIFICATION.
You agree to defend, indemnify and hold harmless Leads Genie and its contractors, agents, employees, officers, directors, shareholders, and affiliates from any loss, liability, damages or expense, including reasonable attorneys&apos; fees, resulting from any third party claim, action, proceeding or demand related to Your (including Your agents affiliates, or anyone using Your account, software or services with Leads Genie whether or not on Your behalf, and whether or not with Your permission) use of the Software or Services You purchased from Leads Genie or Your breach of this Agreement or incorporated agreements and policies. In addition, You agree to indemnify and hold Leads Genie harmless from any loss, liability, damages or expense, including reasonable attorneys&apos; fees, arising out of any breach of any representation or warranty provided herein, any negligence or willful misconduct by You, or any allegation that Your account infringes a third person&apos;s copyright, trademark or proprietary or intellectual property right, or misappropriates a third person&apos;s trade secrets. This indemnification is in addition to any indemnification required of You elsewhere by Leads Genie. Should Leads Genie be notified of a pending law suit, or receive notice of the filing of a law suit, Leads Genie may seek a written confirmation from You concerning Your obligation to indemnify Leads Genie. Your failure to provide such a confirmation may be considered a breach of this Agreement by You. You agree that Leads Genie shall have the right to participate in the defense of any such claim through counsel of its own choosing. You agree to notify Leads Genie of any such claim promptly in writing and to allow Leads Genie to control the proceedings. You agree to cooperate fully with Leads Genie during such proceedings.

You agree to cooperate fully with Leads Genie during such proceedings. You agree You will not be entitled to a refund of any fees paid to Leads Genie if, for any reason, Leads Genie takes corrective action with respect to Your improper or illegal use of its Services. You also agree that if Leads Genie is notified that a complaint has been filed with a governmental, administrative or judicial body, regarding the use of Leads Genie Software or Services, that Leads Genie, in its sole discretion, may take whatever action Leads Genie deems necessary regarding further modification, assignment of and/or control of Your account to comply with the actions or requirements of the governmental, administrative or judicial body until such time as the dispute is settled.
              <br></br><br></br>
13.  You hereby grant and authorize Leads Genie, L.L.C., and it assigns the right to take, edit, alter, copy, exhibit, publish, distribute, and make use of any and all pictures or videos taken of me by Leads Genie, L.L.C. to be used legally in and for any legal promotional materials including but not limited to newsletters, enewesletters, flyers, posters, brochures, advertisements (digital or otherwise), fundraising, letters, annual reports, press kits, including submissions to journals, websites, social networking sites, Leads Genie subscription site videos/audio etc., and other print and digital communications without payment or any other consideration.  Further, this authorisation extends to all languages media formats and markets now known or hereafter devised.  This authorization shall continue indefinitely unless otherwise revoked by You in writing and delivered via written notice as provided for by this agreement.  I understand and agree that these materials shall become property of Leads Genie, L.L.C., and it&apos;s assigns and will not be returned. You hereby hold harmless and release Leads Genie, L.L.C. and it&apos;s assigns from all liability petitions and cause of action which may arise from the use of these materials.
              <br></br><br></br>
14. GOVERNING LAW, VENUE; WAIVER OF TRIAL BY JURY.
This Agreement shall be deemed entered into in the State of Florida. Except for disputes concerning the user of Software and Services offered by Leads Genie, You agree that the laws and judicial decisions of Hillsborough, County, Florida, shall be used to determine the validity, construction, interpretation and legal effect of this Agreement. You agree that any action relating to or arising out of this Agreement shall be brought in the courts of Hillsborough County, Florida. You further agree to waive the right to trial by jury in any proceeding that takes place relating to or arising out of this Agreement.
              <br></br><br></br>
15. NOTICES.
You agree that all notices (except for notices concerning breach of this Agreement) from Leads Genie to You may be emailed to you. Notices concerning breach will be sent either to the email or postal address You have on file with Leads Genie. In either case, delivery shall be deemed to have been made five (5) days after the date sent.
Notices from You to Leads Genie shall be made either by email, sent to the address provided on the Leads Genie web site, or first class mail to Leads Genie&apos;s address at:
              <br></br>
Leads Genie, L.L.C., DBA-Leads Genie<br></br>
Attn: Support<br></br>
2652 Tanglewood Trail<br></br>
Palm Harbor, FL.  34685<br></br>
              <br></br><br></br>
16. ENTIRE AGREEMENT.
You agree this Agreement constitutes the complete and only Agreement between You and Leads Genie regarding the Software and Services contemplated herein.
              <br></br><br></br>
17. SEVERABILITY.
You agree that the terms of this Agreement are severable. If any part of this Agreement is determined to be unenforceable or invalid, that part of the Agreement will be interpreted in accordance with applicable law as closely as possible, in line with the original intention of both parties to the Agreement. The remaining terms and conditions of the Agreement will remain in full force and effect.
              <br></br>
18. WAIVER.
The failure of Leads Genie to enforce any of the provisions within this Agreement or its incorporated agreements and policies against You or others shall not be construed to be a waiver of the right of Leads Genie thereafter to enforce such provisions.
              <br></br>
19. FORCE MAJEURE.
Leads Genie will make every effort to keep its web site and Services operational. However, certain technical difficulties and other factors outside of its control may, from time to time, result in temporary service interruptions. You agree not to hold Leads Genie liable for any of the consequences of such interruptions.
              <br></br>
20. SURVIVAL.
The Terms of this Agreement which can legally survive the termination or cancellation of this agreement shall survive.
              <br></br>
21. NO THIRD PARTY BENEFICIARIES.
Nothing in this Agreement, express or implied, is intended to confer upon any third party any rights, remedies, obligations, or liabilities under or by reason of this Agreement, except as expressly provided in this Agreement.

            </p>
            <div style={{ width: '100%' }} className='d-flex justify-content-center'>
              <a className='btn'
                style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '.6rem', paddingBottom: '.6rem', borderRadius: '10px', textDecoration: 'none', color: 'white', backgroundColor: '#23a455' }}
                href={Document}
                download='leads_genie_eula'
              >
                Click to download
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default EulaModal
