import React from 'react'
// import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { CgCopyright } from 'react-icons/cg'

// const authenticatedOptions = (
//   <Fragment>
//     <NavLink to='/change-password' className='nav-link'>Change Password</NavLink>
//     <NavLink to='/sign-out' className='nav-link'>Sign Out</NavLink>
//   </Fragment>
// )

// const unauthenticatedOptions = (
//   <Fragment>
//     <NavLink to='/sign-up' className='nav-link'>Sign Up</NavLink>
//     <NavLink to='/sign-in' className='nav-link'>Sign In</NavLink>
//   </Fragment>
// )

// const alwaysOptions = (
//   <Fragment>
//     <NavLink exact to='/' className='nav-link'>Home</NavLink>
//   </Fragment>
// )

const Footer = ({ user }) => (
  <Navbar bg='secondary' variant='dark'>
    <Container style={{ width: '100%' }}>
      <Row style={{ width: '100%' }}>
        <Col className="d-flex justify-content-center">
          <p className="white xs-text m-0" ><span><CgCopyright size={10}></CgCopyright></span> Leads Genie 2021</p>
        </Col>
      </Row>
    </Container>
  </Navbar>
)

export default Footer
