import React from 'react'
import axios from 'axios'
import apiUrl from '../../apiConfig'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

class FailedPaymentModal extends React.Component {
  state = {
    buttonClicked: false
  }

  // componentDidMount () {
  // }

  toBilling = () => {
    axios({
      url: apiUrl + '/create-portal-session',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        customer: this.props.stripeCustomerId
      }
    })
      // .then(res => console.log(res))
      .then(res => window.location.replace(res.data.url))
  }

  updateSubStatus = () => {
    this.setState({ buttonClicked: true })
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          stripe: {
            stripeCusId: this.props.stripeCustomerId,
            stripeSubId: this.props.stripeSubId,
            stripeSubStatus: 'past_due_alert_given',
            stripeDefPaymentId: this.props.stripeDefPaymentId
          }
        }
      }
    })
      .then(this.toBilling)
  }

  render () {
    return (
      <React.Fragment>
        <Modal show={this.props.failedPaymentModal} fullscreen={'sm-down'} onHide={this.props.failedPaymentModalClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: 'bold' }} className="main-font-style">ATTENTION!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="main-font-style">
            <p>Your payment method failed while attempting to renew your subscription. Within 48 hours, please update the payment method in your <span style={{ fontWeight: 'bold' }}>Billing</span> settings to prevent your subscription from lapsing.</p>
            {!this.state.buttonClicked &&
            <Button onClick={this.updateSubStatus} style={{ width: '100%' }} variant="ternary" className="white mt-3">Billing &rarr;</Button>
            }
            {this.state.buttonClicked &&
            <Button style={{ color: 'white', width: '100%' }} className="mt-3" variant="ternary"><Spinner size='sm' as='span' animation='border' role='status'/></Button>
            }
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default FailedPaymentModal
