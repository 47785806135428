import React from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { withRouter, Redirect, Switch } from 'react-router-dom'

class PageNotFound extends React.Component {
  state = {
    portal: false
  }

  render () {
    if (this.state.portal) {
      return <Switch><Redirect to = {{ pathname: '/' }}></Redirect></Switch>
    }
    return (
      <Container fluid className="portal-container white secondary-font-style" style={{ maxWidth: '600px', backgroundColor: '#252739' }}>
        <div style={{ height: '50%' }} className="d-flex flex-column justify-content-evenly">
          <div>
            <h1 style={{ textAlign: 'center' }}>404 Error</h1>
            <h1 style={{ textAlign: 'center' }}>Page Not Found</h1>
          </div>
          <Button onClick={() => this.setState({ portal: true })} className="white" size="lg" variant="ternary">Home</Button>
        </div>
      </Container>
    )
  }
}
export default withRouter(PageNotFound)
