import React from 'react'
import WithLicenseIntro from './WithLicenseIntro'
import WithLicensePortal from './WithLicensePortal'

class WithLicense extends React.Component {
  state = {
    view: 'intro'
  }

  componentDidMount () {
    if (this.props.locationProps === undefined) {
      this.setState({ view: 'portal' })
    } else {
      setTimeout(() => {
        this.setState({ view: 'portal' })
      }, 5500)
    }
  }

  render () {
    return (
      <div style={{ height: '100%' }}>
        { this.state.view === 'intro' &&
        <WithLicenseIntro portalId={this.props.portalId} user={this.props.user}></WithLicenseIntro>
        }
        { this.state.view === 'portal' &&
        <WithLicensePortal showQRCode={this.props.showQRCode} theme={this.props.theme} changeTheme={this.props.changeTheme} viewProfile={this.props.viewProfile} getMlsId={this.props.getMlsId} mlsState={this.props.mlsState} mlsid={this.props.mlsid} bulkSubStatus={this.props.bulkSubStatus} assignor={this.props.assignor} currentPrice={this.props.currentPrice} stripeSubId={this.props.stripeSubId} stripeDefPaymentId={this.props.stripeDefPaymentId} stripeSubStatus={this.props.stripeSubStatus} stripeCustomerId={this.props.stripeCustomerId} portalId={this.props.portalId} msgAlert={this.props.msgAlert} user={this.props.user} getSession={this.props.getSession} setUser={this.props.setUser}></WithLicensePortal>
        }
      </div>
    )
  }
}

export default WithLicense
