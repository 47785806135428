import React from 'react'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import Header from './Header/Header.js'
import Footer from './Footer/Footer.js'
import Section1 from './Section1/Section1.js'
import Section2 from './Section2/Section2.js'
import Section3 from './Section3/Section3.js'

class SalesPage extends React.Component {
  state = {

  }

  render () {
    if (this.props.user) {
      return <Switch><Redirect to ={{ pathname: '/portal' }} /></Switch>
    }
    return (
      <React.Fragment>
        <Header></Header>
        <Section1></Section1>
        <Section2></Section2>
        <Section3></Section3>
        <Footer></Footer>
      </React.Fragment>
    )
  }
}

export default withRouter(SalesPage)
