import React from 'react'
import { withRouter, Switch, Redirect } from 'react-router-dom'

class PaymentCancel extends React.Component {
  state = {
    redirect: ''
  }

  componentDidMount () {
    this.setState({ redirect: true })
  }

  render () {
    if (this.state.redirect) {
      return <Switch><Redirect to ={{ pathname: '/portal', props: { result: 'cancel' } }} /></Switch>
    }
    return (
      <div></div>
    )
  }
}

export default withRouter(PaymentCancel)
