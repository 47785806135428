import React from 'react'
import { withRouter } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from './../../../images/meeting.jpg'
// import NavLink from 'react-bootstrap/NavLink'
import Button from 'react-bootstrap/Button'

class Section1 extends React.Component {
  state = {

  }

  handleClick = () => {
    this.props.history.push('/sign-up')
  }

  render () {
    return (
      <Container fluid className="section-one-landing">
        <Container fluid className='bg-image-landing' style={{ backgroundImage: `url(${Image})` }}></Container>
        <Container fluid className='bg-image-filter'>
        </Container>
        <Container fluid className='section-one-landing-text'>
          <div className="">
            <a href='' onClick={() => this.props.history.push('/sign-up')} className="white main-font-style small-text" style={{ marginRight: '30px', marginTop: '1rem', textDecoration: 'none', float: 'right' }}>Sign Up</a>
            <a href='' onClick={() => this.props.history.push('/sign-in')} className="white main-font-style small-text" style={{ marginRight: '30px', marginTop: '1rem', textDecoration: 'none', float: 'right' }}>Sign In</a>
          </div>
          <Container fluid className="section-one-landing-vertical-spacing-wrapper">
            <Container fluid className="section-one-landing-vertical-spacing" >
              <Row>
                <Col className='mt-4'>
                  <p className="white main-font-style small-text" style={{ textAlign: 'center' }}>
                    For Agents and Brokers
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="white main-font-style large-text mt-1" style={{ textAlign: 'center', letterSpacing: '.09rem' }}>Hyper Local Lead Generation Using Open Houses in Your Area!</h2>
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-2 mb-3">
                <div className="green-divider"></div>
              </div>
              <Row className="mb-3">
                <Col className="d-flex justify-content-center">
                  <Button onClick={this.handleClick} className="large-text white secondary-font-style landing-page-btn" variant="ternary">Register Now!</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="white main-font-style small-text" style={{ textAlign: 'center' }}>It has been proven time and time again that Real Estate is a local business. In today’s competitive market, it is imperative for agents to use creative methods to get face to face with prospective clients. Open Houses Automated will do exactly that by leveraging the local open houses happening in your area every weekend. While other agents are looking for Buyers, with Open Houses Automated, warm Buyers will be looking for you!</p>
                </Col>
              </Row>
            </Container>
          </Container>
        </Container>
      </Container>
    )
  }
}

export default withRouter(Section1)
