import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

class Section2 extends React.Component {
  state = {

  }

  render () {
    return (
      <Container fluid className="section-two-landing">
        <Container fluid className="section-two-landing-heading">
          <Row className="">
            <Col>
              <h3 className="pt-4 pb-4 secondary-font-style large-text secondary-font-color" style={{ textAlign: 'center' }}>
                Simple But Effective
              </h3>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

export default Section2
