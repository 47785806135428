import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Container1 from './Container1.js'
import Container2 from './Container2.js'
import Container3 from './Container3.js'
import Container4 from './Container4.js'
// import Container5 from './Container5.js'

class Section3 extends React.Component {
  state = {

  }

  render () {
    return (
      <Container fluid>
        <Container>
          <Row>
            <Col>
              <h4 className="main-font-style pt-4 pb-4 med-text" style={{ textAlign: 'center', color: '#23a455' }}>Here&apos;s how it works</h4>
            </Col>
          </Row>
        </Container>
        <Container1></Container1>
        <Container2></Container2>
        <Container3></Container3>
        <Container4></Container4>
        {/* <Container5></Container5> */}
      </Container>
    )
  }
}

export default Section3
