import React from 'react'
// import axios from 'axios'
// import apiUrl from '../../apiConfig'
import Modal from 'react-bootstrap/Modal'
import QRCode from 'qrcode'

class QRCodeModal extends React.Component {
  state = {
    url: '',
    loaded: false
  }

  componentDidMount () {
    this.generateCode()
  }

  getUrl = () => {
    let url
    if (window.location.hostname === 'localhost') {
      url = 'https://ako-21.github.io/openhouse-genie'
    } else {
      url = 'https://oh-genie.com'
    }
    return url
  }

  generateCode = () => {
    QRCode.toDataURL(`${this.getUrl()}/${this.props.portalId}`)
      .then(url => {
        this.setState({ url: url })
      })
      .then(() => this.setState({ loaded: true }))
      .catch(err => {
        console.error(err)
      })
  }

  render () {
    return (
      <React.Fragment>
        <Modal show={this.props.state} fullscreen={'sm-down'} onHide={this.props.close}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: 'bold' }} className="main-font-style">Download Your QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body className="main-font-style">
            {this.state.loaded &&
            <React.Fragment>
              <div style={{ width: '100%' }} className='d-flex justify-content-center'>
                <img src={this.state.url} alt='qrcode' />
              </div>
              <div className={'d-flex justify-content-center main-font-style mb-3' + ' ' + this.props.theme} style={{ backgroundColor: 'white' }}>
                <a className='variable-buttons'
                  style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '.6rem', paddingBottom: '.6rem', borderRadius: '10px', textDecoration: 'none', color: 'white' }}
                  href={this.state.url}
                  download='leads_genie_qr_code'
                >
                  Click to download
                </a>
              </div>
              <p style={{ textAlign: 'center' }}>{this.getUrl()}/{this.props.portalId}</p>
            </React.Fragment>
            }
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default QRCodeModal
