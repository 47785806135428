import React from 'react'
import { withRouter } from 'react-router-dom'

class Step3 extends React.Component {
  state = {

  }

  render () {
    return (
      <div style={{ height: '85%' }} className="p-4 d-flex flex-column justify-content-around">
        <p className="secondary-font-style small-text" style={{ textAlign: 'center' }}>Thank you for registering. An activation email has been sent to you. Click the link in the email to confirm your account.</p>
        <div>
          <a href='' onClick={() => this.props.history.push('/')} className="primary-font-style large-text" style={{ textAlign: 'center', textDecoration: 'none', color: 'black', display: 'block' }}>X</a>
          <a href='' onClick={() => this.props.history.push('/')} className="primary-font-style xs-text" style={{ textAlign: 'center', color: 'black', display: 'block' }}>exit</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Step3)
