import React from 'react'
import { withRouter } from 'react-router-dom'
import UserPool from './UserPool'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
// import { SiOpsgenie } from 'react-icons/si'
// import { IconContext } from 'react-icons'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Logo2 from './../../../images/logo2.png'

class SignUp2 extends React.Component {
  state = {
    step: 1,
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    password: '',
    pwConfirm: '',
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    userNameError: '',
    passwordError: '',
    passwordLError: '',
    passwordUError: '',
    passwordNError: '',
    pwConfirmError: '',
    validation1: false,
    validation2: false,
    duplicateError: ''
  }

  nextButtons = () => {
    this.setState({ step: this.state.step + 1 })
  }

  backButtons = () => {
    this.setState({ step: this.state.step - 1 })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const attributes = [{ Name: 'family_name', Value: this.state.lastName }, { Name: 'given_name', Value: this.state.firstName }]
    UserPool.signUp(this.state.email, this.state.password, attributes, null, (err, data) => {
      if (err) {
        console.error(err.message)
        if (err.message === 'An account with the given email already exists.') {
          this.setState({ duplicateError: true, password: '', pwConfirm: '' })
        }
      } else {
        // console.log(data.user)
        this.setState({ step: 3 })
      }
    })
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value },
      () => {
        this.handleErrors(event)
      })
  }

  handleErrors = (event) => {
    if (event.target.name === 'firstName') {
      if (event.target.value.length < 3) {
        this.setState({ firstNameError: '*Minimum 3 Characters' },
          () => {
            this.validation1()
          })
      } else {
        this.setState({ firstNameError: '' },
          () => {
            this.validation1()
          })
      }
    } else if (event.target.name === 'lastName') {
      if (event.target.value.length < 3) {
        this.setState({ lastNameError: '*Minimum 3 Characters' },
          () => {
            this.validation1()
          })
      } else {
        this.setState({ lastNameError: '' },
          () => {
            this.validation1()
          })
      }
    } else if (event.target.name === 'email') {
      const string = event.target.value
      const format = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
      if (string.match(format)) {
        this.setState({ emailError: '' },
          () => {
            this.validation1()
          })
      } else {
        this.setState({ emailError: '*Valid Email Required' },
          () => {
            this.validation1()
          })
      }
    // } else if (event.target.name === 'userName') {
    //   if (event.target.value.length < 3 || event.target.value.length > 10) {
    //     this.setState({ userNameError: '*Min 3 Chars, Max 10 Chars' },
    //       () => {
    //         this.validation2()
    //       })
    //   } else {
    //     this.setState({ userNameError: '' },
    //       () => {
    //         this.validation2()
    //       })
    //   }
    } else if (event.target.name === 'password') {
      const string = event.target.value
      const upperRegex = /(?=.*[A-Z])/
      const lowerRegex = /(?=.*[a-z])/
      const numberRegex = /(?=.*[0-9])/
      if (string.length < 8) {
        this.setState({ passwordError: '*Minimum 8 Characters' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ passwordError: '' },
          () => {
            this.validation2()
          })
      }
      if (string.match(upperRegex)) {
        this.setState({ passwordUError: '' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ passwordUError: '*Must Include Uppercase Letter' },
          () => {
            this.validation2()
          })
      }
      if (string.match(lowerRegex)) {
        this.setState({ passwordLError: '' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ passwordLError: '*Must Include Lowercase Letter' },
          () => {
            this.validation2()
          })
      }
      if (string.match(numberRegex)) {
        this.setState({ passwordNError: '' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ passwordNError: '*Must Include a Number' },
          () => {
            this.validation2()
          })
      }
      if (string !== this.state.pwConfirm) {
        this.setState({ pwConfirmError: '*Passwords do not match' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ pwConfirmError: '' },
          () => {
            this.validation2()
          })
      }
    } else if (event.target.name === 'pwConfirm') {
      if (event.target.value !== this.state.password) {
        this.setState({ pwConfirmError: '*Passwords do not match' },
          () => {
            this.validation2()
          })
      } else {
        this.setState({ pwConfirmError: '' },
          () => {
            this.validation2()
          })
      }
    }
  }

  validation1 = () => {
    if (this.state.firstName && this.state.lastName && this.state.email && !this.state.firstNameError && !this.state.lastNameError && !this.state.emailError) {
      this.setState({ validation1: true })
    } else {
      this.setState({ validation1: false })
    }
  }

  validation2 = () => {
    if (this.state.password && this.state.pwConfirm && !this.state.passwordError && !this.state.passwordUError && !this.state.passwordLError && !this.state.passwordNError && !this.state.pwConfirmError) {
      this.setState({ validation2: true })
    } else {
      this.setState({ validation2: false })
    }
  }

  render () {
    return (
      <Container className="auth-container" style={{ maxWidth: '600px' }}>
        <Container fluid><a href='' onClick={() => this.props.history.push('/')} className={this.state.step === 3 ? 'no-display' : 'primary-font-style large-text'} style={{ textAlign: 'right', textDecoration: 'none', color: '#c4c4c4', display: 'block', fontWeight: '10', paddingRight: '.5rem', paddingTop: '.5rem' }}>X</a></Container>
        <Row style={{ height: '40%' }} className="d-flex align-content-around" >
          <div>
            <Col className="d-flex justify-content-center">
              <div style={{ width: '40%' }} className='ratio ratio-4x3'>
                <img className='' src={Logo2} alt='genie-logo' />
              </div>
            </Col>
          </div>
          <div>
            <h1 className="main-font-style" style={{ fontWeight: 'bold', textAlign: 'center', padding: '0' }}>Sign Up</h1>
            <div className='p-0' style={{ width: '100%' }}>
              <Row className="auth-progressbar-row">
                <Col style={{ marginRight: '.5rem' }} className={this.state.step ? 'ternary-background-color' : 'gray-background-color'}></Col>
                <Col style={{ marginRight: '.5rem' }} className={this.state.step === 2 || this.state.step === 3 ? 'ternary-background-color' : 'gray-background-color'}></Col>
                <Col className={this.state.step === 3 ? 'ternary-background-color' : 'gray-background-color'}></Col>
              </Row>
            </div>
          </div>
        </Row>
        <Row style={{ height: '60%' }}>
          <Col className="auth-form-div-wrapper">
            <div className={this.state.step === 3 ? 'height-one-hundred' : 'auth-form-div'}>
              {
                this.state.step !== 3 &&
              <Form onSubmit={this.handleSubmit} className="auth-form-form">
                {
                  this.state.step === 1 &&
                  <Step1 handleChange={this.handleChange} nextButtons={this.nextButtons} backButtons={this.backButtons} {...this.state}></Step1>
                }
                {
                  this.state.step === 2 &&
                  <Step2 handleSubmit={this.handleSubmit} handleChange={this.handleChange} nextButtons={this.nextButtons} backButtons={this.backButtons}{...this.state}></Step2>
                }
              </Form>
              }
              {
                this.state.step === 3 &&
              <Step3 nextButtons={this.nextButtons} backButtons={this.backButtons}></Step3>
              }
            </div>
          </Col>
        </Row>

      </Container>
    )
  }
}

export default withRouter(SignUp2)
