import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { withRouter } from 'react-router-dom'

class SignInForm extends React.Component {
  state = {

  }

  render () {
    return (
      <Form onSubmit={this.props.handleSubmit} className="auth-form-form">
        <Form.Group className="auth-form-form-group">
          <Form.Control
            required
            id="email-address"
            type="email"
            name="email"
            placeholder="Email Address"
            value={this.props.email}
            onChange={this.props.handleChange}
            onFocus={this.props.emptyErrors}
          />
        </Form.Group>
        <Form.Group className={this.props.errorConfirm || this.props.emailResent || this.props.errorCredential ? '' : 'auth-form-form-group'}>
          <Form.Control
            required
            id="password"
            type="password"
            name="password"
            placeholder="Password"
            value={this.props.password}
            onChange={this.props.handleChange}
            onFocus={this.props.emptyErrors}
          />
          {this.props.errorConfirm &&
            <p className="auth-error-messages">Confirm your account before logging in. To resend the confirmation email, <span onClick={this.props.resendConfirmation} ><a style={{ fontWeight: 'bold', color: 'white', textDecoration: 'none', display: 'inline-block' }}>Click Here</a></span></p>
          }
          {this.props.emailResent &&
            <p className="auth-error-messages">Verication email has been resent.</p>
          }
          {this.props.errorCredential &&
            <p className="auth-error-messages">Incorrect email or password.<span onClick={this.props.forgotPassword} ><a style={{ fontWeight: 'bold', color: 'white', textDecoration: 'none', display: 'inline-block' }}>&nbsp;&nbsp;Reset Password</a></span></p>
          }
        </Form.Group>
        <Form.Group className="auth-form-form-group">
          <Button type="submit" style={{ width: '100%' }} className="btn-ternary white">Next</Button>
        </Form.Group>
        <Form.Group className="auth-form-form-group">
          <p style={{ textAlign: 'center', color: '#808080' }} className=" main-font-style auth-form-bottom-text">If you do not have an account, <span style={{ fontWeight: 'bold', color: 'white' }}><a href='' onClick={() => this.props.history.push('/sign-up')} className="auth-form-bottom-text" style={{ fontWeight: 'bold', color: 'white', textDecoration: 'none', display: 'inline-block' }}>&nbsp;Sign Up</a></span></p>
        </Form.Group>
      </Form>
    )
  }
}

export default withRouter(SignInForm)
