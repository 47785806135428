import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
// import { SiOpsgenie } from 'react-icons/si'
// import { IconContext } from 'react-icons'

// const authenticatedOptions = (
//   <Fragment>
//     <NavLink to='/change-password' className='nav-link'>Change Password</NavLink>
//     <NavLink to='/sign-out' className='nav-link'>Sign Out</NavLink>
//   </Fragment>
// )

// const unauthenticatedOptions = (
//   <Fragment>
//     <NavLink to='/sign-up' className='nav-link'>Sign Up</NavLink>
//     <NavLink to='/sign-in' className='nav-link'>Sign In</NavLink>
//   </Fragment>
// )

// const alwaysOptions = (
//   <Fragment>
//     <NavLink exact to='/' className='nav-link'>Home</NavLink>
//   </Fragment>
// )

const Header = ({ user }) => (
  <Navbar bg='secondary' variant='dark'>
    <Container className='navbar-padding'>
      <Navbar.Brand href='' onClick={() => this.props.history.push('/')} className='d-flex align-items-center'>
        {/* <IconContext.Provider value={{ className: 'genie-img' }}>
          <div>
            <SiOpsgenie></SiOpsgenie>
          </div>
        </IconContext.Provider>
        &nbsp; */}
        <h1 className='nav-text main-font-style mb-0 small-text'>Leads Genie</h1>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='ml-auto'>
          {user && (
            <span className='navbar-text mr-2'>Welcome, {user.email}</span>
          )}
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

export default Header
