import React from 'react'
import apiUrl from '../../apiConfig'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
// import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { IoChevronForward } from 'react-icons/io5'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { IconContext } from 'react-icons'
// import zipCodes from './../../data/fl_zipcodes.json'

class ZipCodes extends React.Component {
  state = {
    view: '',
    createZips: '',
    editZips: '',
    zip1: '',
    zip2: '',
    zip3: '',
    validated: '',
    duplicateError: '',
    zip1Validated: '',
    zip2Validated: '',
    zip3Validated: '',
    loaded: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.portals[0].zipcodes) {
          if (res.data.portals[0].zipcodes.zip1 !== '' && res.data.portals[0].zipcodes.zip2 !== '' && res.data.portals[0].zipcodes.zip3 !== '') {
            this.setState({ zip1: res.data.portals[0].zipcodes.zip1, zip2: res.data.portals[0].zipcodes.zip2, zip3: res.data.portals[0].zipcodes.zip3, view: 'edit' })
          }
        }
      })
      .then(() => this.setState({ loaded: true }))
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.mlsid !== prevProps.mlsid) {
      this.getRequest()
    }
    if (this.state.view !== prevState.view) {
      this.props.viewProfile('zipCodes', this.state.view)
    }
  }

  getRequest = () => {
    // console.log('getrequest')
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.portals[0].zipcodes) {
          this.setState({ zip1: res.data.portals[0].zipcodes.zip1, zip2: res.data.portals[0].zipcodes.zip2, zip3: res.data.portals[0].zipcodes.zip3, view: '', createZips: '', editZips: '', zip: '', validated: '', duplicateError: '', zip1Validated: '', zip2Validated: '', zip3Validated: '' })
        }
      })
  }

  handleChange = (event) => {
    const value = event.target.value
    this.setState({ [event.target.name]: value },
      () => {
        this.handleErrors()
      })
  }

  handleErrors = () => {
    const zip1 = parseInt(this.state.zip1)
    const zip2 = parseInt(this.state.zip2)
    const zip3 = parseInt(this.state.zip3)
    const zip1String = this.state.zip1
    const zip2String = this.state.zip2
    const zip3String = this.state.zip3
    if (zip1String.length === 5) {
      const zip1Valid = this.props.stateData.some((location) => { return location.zip_code === zip1 })
      if (zip1Valid) {
        this.setState({ zip1Validated: true },
          () => { this.validated() }
        )
      } else {
        this.setState({ zip1Validated: '' },
          () => { this.validated() }
        )
      }
    } else {
      this.setState({ zip1Validated: '' },
        () => { this.validated() }
      )
    }
    if (zip2String.length === 5) {
      const zip2Valid = this.props.stateData.some((location) => { return location.zip_code === zip2 })
      if (zip2Valid) {
        this.setState({ zip2Validated: true },
          () => { this.validated() }
        )
      } else {
        this.setState({ zip2Validated: '' },
          () => { this.validated() }
        )
      }
    } else {
      this.setState({ zip2Validated: '' },
        () => { this.validated() }
      )
    }
    if (zip3String.length === 5) {
      const zip3Valid = this.props.stateData.some((location) => { return location.zip_code === zip3 })
      if (zip3Valid) {
        this.setState({ zip3Validated: true },
          () => { this.validated() }
        )
      } else {
        this.setState({ zip3Validated: '' },
          () => { this.validated() }
        )
      }
    } else {
      this.setState({ zip3Validated: '' },
        () => { this.validated() }
      )
    }
    if (zip1 === zip2 || zip2 === zip3 || zip1 === zip3) {
      this.setState({ duplicateError: 'No Duplicate Zip Codes' },
        () => { this.validated() }
      )
    } else {
      this.setState({ duplicateError: '' },
        () => { this.validated() }
      )
    }
  }

  validated = () => {
    if (this.state.zip1Validated && this.state.zip2Validated && this.state.zip3Validated && !this.state.duplicateError) {
      this.setState({ validated: true })
    } else {
      this.setState({ validated: false })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ view: 'edit' })
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          zipcodes: {
            zip1: this.state.zip1,
            zip2: this.state.zip2,
            zip3: this.state.zip3
          }
        }
      }
    })
  }

  render () {
    let jsx
    if (!this.state.view) {
      jsx = (
        <React.Fragment>
          {!this.state.createZips && !this.state.editZips && this.state.loaded &&
          <div onClick={() => this.setState({ createZips: true })} style={{ width: '100%' }}>
            <p style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'underline' }} className="main-font-style small-text red">Add 3 Targeted Zip Codes</p>
          </div>
          }
          {this.state.createZips &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="lg-portal-inputs">
              <Row>
                <div className="col-4 zip-inputs">
                  <InputGroup size="sm">
                    <Form.Control
                      required
                      maxLength={5}
                      type="text"
                      name="zip1"
                      placeholder="Zip Code"
                      value={this.state.zip1}
                      onChange={this.handleChange}
                      style={{ borderRightWidth: '0' }}
                    />
                    <InputGroup.Text style={{ backgroundColor: 'white', borderTopRightRadius: '.2rem', borderBottomRightRadius: '.2rem' }}>{this.state.zip1Validated && !this.state.duplicateError ? <span className="variable-checks">&#10004;</span> : ''}</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="col-4 zip-inputs">
                  <InputGroup size="sm">
                    <Form.Control
                      required
                      maxLength={5}
                      name="zip2"
                      type="text"
                      placeholder="Zip Code"
                      value={this.state.zip2}
                      onChange={this.handleChange}
                      style={{ borderRightWidth: '0' }}
                    />
                    <InputGroup.Text style={{ backgroundColor: 'white', borderTopRightRadius: '.2rem', borderBottomRightRadius: '.2rem' }}>{this.state.zip2Validated && !this.state.duplicateError ? <span className="variable-checks">&#10004;</span> : ''}</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="col-4 zip-inputs">
                  <InputGroup size="sm">
                    <Form.Control
                      required
                      maxLength="5"
                      type="text"
                      name="zip3"
                      placeholder="Zip Code"
                      value={this.state.zip3}
                      onChange={this.handleChange}
                      style={{ borderRightWidth: '0' }}
                    />
                    <InputGroup.Text style={{ backgroundColor: 'white', borderTopRightRadius: '.2rem', borderBottomRightRadius: '.2rem' }}>{this.state.zip3Validated && !this.state.duplicateError ? <span className="variable-checks">&#10004;</span> : ''}</InputGroup.Text>
                  </InputGroup>
                </div>
              </Row>
              <Row>
                <Form.Group className="d-flex justify-content-between" style={{ paddingRight: '0' }}>
                  <div>
                    {this.state.duplicateError &&
                      <p className="auth-error-messages">{this.state.duplicateError}</p>
                    }
                  </div>
                  <Button size="sm" type="submit" disabled={!this.state.validated} className="variable-buttons">
                    <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                      <IconContext.Provider value={{ className: 'auth-back-img' }}>
                        <div>
                          <IoChevronForward></IoChevronForward>
                        </div>
                      </IconContext.Provider>
                    </div>
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </div>
          }
          {this.state.editZips &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="lg-portal-inputs">
              <Row>
                <div className="col-4 zip-inputs">
                  <InputGroup size="sm">
                    <Form.Control
                      required
                      maxLength={5}
                      type="text"
                      name="zip1"
                      placeholder="Zip Code"
                      value={this.state.zip1}
                      onChange={this.handleChange}
                      style={{ borderRightWidth: '0' }}
                    />
                    <InputGroup.Text style={{ backgroundColor: 'white', borderTopRightRadius: '.2rem', borderBottomRightRadius: '.2rem' }}>{this.state.zip1Validated && !this.state.duplicateError ? <span className="variable-checks">&#10004;</span> : ''}</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="col-4 zip-inputs">
                  <InputGroup size="sm">
                    <Form.Control
                      required
                      maxLength={5}
                      name="zip2"
                      type="text"
                      placeholder="Zip Code"
                      value={this.state.zip2}
                      onChange={this.handleChange}
                      style={{ borderRightWidth: '0' }}
                    />
                    <InputGroup.Text style={{ backgroundColor: 'white', borderTopRightRadius: '.2rem', borderBottomRightRadius: '.2rem' }}>{this.state.zip2Validated && !this.state.duplicateError ? <span className="variable-checks">&#10004;</span> : ''}</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="col-4 zip-inputs">
                  <InputGroup size="sm">
                    <Form.Control
                      required
                      maxLength="5"
                      type="text"
                      name="zip3"
                      placeholder="Zip Code"
                      value={this.state.zip3}
                      onChange={this.handleChange}
                      style={{ borderRightWidth: '0' }}
                    />
                    <InputGroup.Text style={{ backgroundColor: 'white', borderTopRightRadius: '.2rem', borderBottomRightRadius: '.2rem' }}>{this.state.zip3Validated && !this.state.duplicateError ? <span className="variable-checks">&#10004;</span> : ''}</InputGroup.Text>
                  </InputGroup>
                </div>
              </Row>
              <Row>
                <Form.Group className="d-flex justify-content-between" style={{ paddingRight: '0' }}>
                  <div>
                    {this.state.duplicateError &&
                      <p className="auth-error-messages">{this.state.duplicateError}</p>
                    }
                  </div>
                  <Button size="sm" type="submit" disabled={!this.state.validated} className="variable-buttons">
                    <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                      <IconContext.Provider value={{ className: 'auth-back-img' }}>
                        <div>
                          <IoChevronForward></IoChevronForward>
                        </div>
                      </IconContext.Provider>
                    </div>
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </div>
          }
        </React.Fragment>
      )
    } else {
      jsx = (
        <div style={{ width: '100%' }}>
          <p onClick={() => this.setState({ view: '', createZips: '', editZips: 'true', validated: 'true' })} style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'none' }} className="main-font-style small-text variable-main-font-color"><span className="xs-text" style={{ fontWeight: 'bold' }}>targeted zip codes:</span><br></br><span className="variable-checks">&#10004;</span>&nbsp;&nbsp;{this.state.zip1},&nbsp;{this.state.zip2},&nbsp;{this.state.zip3}<MdOutlineModeEditOutline></MdOutlineModeEditOutline></p>
        </div>
      )
    }
    return (
      <React.Fragment>
        {jsx}
      </React.Fragment>
    )
  }
}

export default ZipCodes
