import React from 'react'
import { Route, Redirect } from 'react-router-dom'
// import UserPool from '../auth/SignUp2/UserPool'

// const runBeforeRender = () => {
//   const user = UserPool.getCurrentUser()
//   let session
//   if (user) {
//     user.getSession((err, data) => {
//       if (err) {
//         console.error(err)
//         session = false
//       } else {
//         console.log(data)
//         session = true
//       }
//     })
//   }
//   return session
// }

// props will include a `user` object or empty object
// props will include a `component` as `Component` or a `render`
// all other props that may be passed in are `..rest`
const AuthenticatedRoute = ({ user, component: Component, render, ...rest }) => {
  // const [session] = useState(runBeforeRender)
  let jsx
  // if `use` and a `render` then create route with `render`
  if (user && render) {
    jsx = <Route {...rest} render={render} />
    // if `user` but no `render` then create route with `Component`
    // if no `user` then redirect to home
  } else if (user) {
    jsx = (
      <Route
        {...rest}
        render={(props) =>
          <Component {...props} />
        }
      />
    )
  } else {
    jsx = (
      <Route
        {...rest}
        render={(props) => <Redirect to='/unauthorized' /> }
      />
    )
  }
  return <React.Fragment>{jsx}</React.Fragment>
}

export default AuthenticatedRoute
