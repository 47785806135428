import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import moment from 'moment'
// import Image from './../../../images/house.jpg'

class ImgModal extends React.Component {
  state = {
  }

  convertDate = (input) => {
    // console.log(input, 'date')
    const myDate = new Date(input)
    // console.log(myDate)
    const dateString = myDate.toString()
    const dateArr = dateString.split(' ')
    const newArr = [dateArr[0], dateArr[1], dateArr[2]]
    return newArr.join(' ')
  }

  convertTime = (input) => {
    // console.log(input, 'time')
    const myDate = new Date(input)
    // console.log(myDate)
    const toLocal = myDate.toString()
    const dateArr = toLocal.split(' ')
    // console.log(dateArr)
    const string = dateArr[4]
    return moment(string, 'HH:mm:ss').format('h:mm a')
  }

  render () {
    return (
      <React.Fragment>
        {this.props.adminListingData &&
        <Modal size="lg" show={this.props.imageModal}>
          <Modal.Body>
            <Carousel variant="dark" indicators={false}>
              {this.props.adminListingData.Media.map((image) => (
                <Carousel.Item key={image.MediaURL}>
                  <Carousel.Caption style={{ width: '100%', left: '0', right: '0', bottom: '0', top: '0', paddingTop: '0', paddingBottom: '0' }}>
                    <div className="d-flex justify-content-end" style={{ width: '100%' }}>
                      <Button onClick={this.props.closeImageModal} className="carousel-button" variant="outline-primary" style={{ border: 'none', color: 'black' }}>X</Button>
                    </div>
                  </Carousel.Caption>
                  <img
                    className="d-block w-100"
                    src={image.MediaURL}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <Modal.Footer style={{ paddingBottom: '0' }}>
              <div style={{ textAlign: 'center', width: '100%' }} className='main-font-style'>
                {this.props.adminOpenHouseDates.map(item => (
                  <div key={item}>{item}</div>
                ))}
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        }
        {this.props.listingData &&
        <Modal size="lg" show={this.props.imageModal}>
          <Modal.Body>
            <Carousel variant="dark" indicators={false}>
              {this.props.listingData.Media.map((image) => (
                <Carousel.Item key={image.MediaURL}>
                  <Carousel.Caption style={{ width: '100%', left: '0', right: '0', bottom: '0', top: '0', paddingTop: '0', paddingBottom: '0' }}>
                    <div className="d-flex justify-content-end" style={{ width: '100%' }}>
                      <Button onClick={this.props.closeImageModal} className="carousel-button" variant="outline-primary" style={{ border: 'none' }}>X</Button>
                    </div>
                  </Carousel.Caption>
                  <img
                    className="d-block w-100"
                    src={image.MediaURL}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <Modal.Footer style={{ paddingBottom: '0' }}>
              <div style={{ textAlign: 'center', width: '100%' }} className='main-font-style'>
                {this.props.openHouseData.Dates.map(item => (
                  <div key={item.OpenHouseStartTime}>{this.props.openHouseloading ? '' : this.convertDate(item.OpenHouseStartTime)} | {this.props.openHouseloading ? '' : this.convertTime(item.OpenHouseStartTime)}-{this.props.openHouseloading ? '' : this.convertTime(item.OpenHouseEndTime)}</div>
                ))}
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        }
      </React.Fragment>
    )
  }
}

export default ImgModal
