import React from 'react'
import UserPool from './../SignUp2/UserPool'
import { withRouter, Redirect, Switch } from 'react-router-dom'

class SignOut2 extends React.Component {
  state = {
    signedOut: ''
  }

  logout = () => {
    const user = UserPool.getCurrentUser()
    if (user) {
      // console.log(user)
      user.signOut()
    }
  }

  componentDidMount () {
    const user = UserPool.getCurrentUser()
    if (user) {
      // console.log(user)
      user.signOut()
      this.props.clearUser()
      this.setState({ signedOut: true })
    }
  }

  render () {
    if (this.state.signedOut) {
      return <Switch><Redirect to={{ pathname: '/' }} /></Switch>
    }
    return (
      <div></div>
    )
  }
}

export default withRouter(SignOut2)
