import React from 'react'
// import axios from 'axios'
// import apiUrl from '../../apiConfig'
import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { IoMdTrash } from 'react-icons/io'
import { CSVLink } from 'react-csv'

class LeadModal extends React.Component {
  state = {
    data: '',
    headers: ['First Name', 'Last Name', 'Email', 'Phone', 'Lead Channel', 'Timestamp']
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.leads !== this.props.leads) {
      const dataArray = []
      if (this.props.leads.length >= 1) {
        this.props.leads.forEach((lead, i) => {
          const tempArray = [lead.leadFirstName, lead.leadLastName, lead.leadEmail, lead.leadPhone, lead.channel, lead.timestamp]
          dataArray.push(tempArray)
          this.setState({ data: dataArray })
        })
      }
    }
  }

  // startDownload = () => {
  //   const dataArray = []
  //   if (this.props.leads.length >= 1) {
  //     this.props.leads.forEach((lead, i) => {
  //       const tempArray = [lead.leadFirstName, lead.leadLastName, lead.leadEmail, lead.leadPhone, lead.channel, lead.timestamp]
  //       dataArray.push(tempArray)
  //       this.setState({ data: dataArray })
  //     })
  //   }
  // }

  limitDate = (date) => {
    const array = date.split(' ')
    return `${array[1]} ${array[2]}`
  }

  render () {
    return (
      <React.Fragment>
        <Modal show={this.props.leadModal} fullscreen={'sm-down'} onHide={this.props.leadModalClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: 'bold' }} className="main-font-style">See Your Leads Below</Modal.Title>
          </Modal.Header>
          <Modal.Body className="main-font-style">
            {this.props.leads.length >= 1 &&
            <div>
              <div className='d-flex justify-content-around mb-3'>
                <CSVLink filename='Clicks.csv' data={this.props.clicks} headers={[{ label: 'timestamp', key: 'timestamp' }]} className='btn' style={{ backgroundColor: '#212529', color: 'white' }}>Export QR Code Clicks</CSVLink>
                <CSVLink filename='Leads.csv' headers={this.state.headers} data={this.state.data} onClick={this.startDownload} className='btn' style={{ backgroundColor: '#212529', color: 'white' }}>Export Opt Ins</CSVLink>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.leads.map((lead) => (
                    <tr key={lead._id}>
                      <td><IoMdTrash onClick={() => this.props.deleteLead(lead._id)}></IoMdTrash></td>
                      <td>{this.limitDate(lead.timestamp)}</td>
                      <td>{lead.leadFirstName} {lead.leadLastName}</td>
                      <td>{lead.leadEmail}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            }
            {!this.props.leads.length >= 1 &&
            <p>You do not have any leads at this time.</p>
            }
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default LeadModal
