import React from 'react'
import Container from 'react-bootstrap/Container'
// import { SiOpsgenie } from 'react-icons/si'
// import { IconContext } from 'react-icons'
import Logo2 from './../../images/logo2.png'
import Typewriter from 'typewriter-effect'

class WithLicenseIntro extends React.Component {
  state = {
  }

  render () {
    return (
      <Container fluid style={{ height: '100vh' }}>
        <div style={{ height: '60%' }} className="d-flex flex-column justify-content-evenly">
          <p style={{ textAlign: 'center' }} className='main-font-style large-text white'>Hi,&nbsp;<span style={{ fontWeight: 'bold' }} >{this.props.user.idToken.payload.given_name}</span></p>
          <div style={{ textAlign: 'center' }} className='main-font-style large-text white'>
            <Typewriter
              onInit={(typewriter) => {
                typewriter.typeString('Welcome to your portal.')
                  .start()
              }}
            />
          </div>
        </div>
        <div style={{ height: '30%' }} className="d-flex flex-column justify-content-start">
          <div className="d-flex justify-content-center">
            <div style={{ width: '40%' }} className='ratio ratio-4x3'>
              <img className='' src={Logo2} alt='genie-logo' />
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default WithLicenseIntro
