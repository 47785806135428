import React from 'react'
import axios from 'axios'
import apiUrl from '../../apiConfig'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// import Popover from 'react-bootstrap/Popover'
import { BsTrashFill } from 'react-icons/bs'
import { IconContext } from 'react-icons'
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'
import Row from 'react-bootstrap/Row'
import { AiFillCheckCircle } from 'react-icons/ai'
import { MdOutlineCancel } from 'react-icons/md'

class BulkLicensesSubscription extends React.Component {
  state = {
    assignees: this.props.bulkSubscriptions.assignees,
    loading: false,
    assigneeId: '',
    index: '',
    assigneeStripeSub: ''
  }

  componentDidUpdate (prevProps) {
    if (this.props.bulkSubscriptions.assignees !== prevProps.bulkSubscriptions.assignees) {
      this.setState({ assignees: this.props.bulkSubscriptions.assignees })
    }
  }

  toDeleteOne = (event) => {
    const key = `remove${event.target.dataset.id}`
    this.setState({ [key]: false, loading: true })
    axios({
      url: apiUrl + '/bulk-subscription-add',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        quantity: parseFloat(this.props.bulkSubscriptions.quantity) - 1,
        subscriptionId: this.props.bulkSubscriptions.subscriptionId,
        assigneeId: event.target.dataset.btnId
      }
    })
      .then(res => {
        this.props.msgAlert({
          heading: 'Success',
          message: `Your bulk subscription was successfully reduced to ${res.data.subscription.quantity} licenses.`
        })
      }
      )
      .then(this.props.getRequest())
      .then(() => this.setState({ loading: false }))
      .catch(() => {
        this.props.msgAlert({
          heading: 'Something Went Wrong',
          message: 'Please check your payment info and your internet connection, and try again'
        })
      })
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  validateId = (event) => {
    event.preventDefault()
    if (this.state[`userid${event.target.dataset.id}`] === this.props.portalId) {
      this.props.msgAlert({
        heading: 'You Cannot Assign Bulk Licenses to Yourself'
      })
      return
    }
    // const key = `assign${event.target.dataset.id}`
    // this.setState({ [key]: false })
    axios({
      url: apiUrl + '/allportals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => {
        if (res.data.portals.some(portal => { return portal._id === this.state[`userid${event.target.dataset.id}`] })) {
          const pos = res.data.portals.map(i => { return i._id }).indexOf(this.state[`userid${event.target.dataset.id}`])
          this.setState({ assigneeId: this.state[`userid${event.target.dataset.id}`], index: parseFloat(event.target.dataset.id) - 1, firstName: res.data.portals[pos].first, lastName: res.data.portals[pos].last, assigneeStripeSub: res.data.portals[pos].stripeSubStatus })
          const key = `assign${event.target.dataset.id}`
          this.setState({ [key]: false })
        } else {
          this.props.msgAlert({
            heading: 'User Id Does Not Exist',
            message: 'Please get the correct user id from the user and try again'
          })
        }
      })
      .then(() => this.setState({ [`userid${event.target.dataset.id}`]: '' }))
      .then(this.findAssigneeIdAlreadyExist)
  }

  findAssigneeIdAlreadyExist = () => {
    if (this.state.assigneeId) {
      if (this.state.assigneeStripeSub === 'active' || this.state.assigneeStripeSub === 'trialing' || this.state.assigneeStripeSub === 'incomplete' || this.state.assigneeStripeSub === 'past_due' || this.state.assigneeStripeSub === 'past_due_alert_given') {
        this.props.msgAlert({
          heading: 'This User Already has a Personal Subscription'
        })
        this.setState({ assigneeStripeSub: '' })
        return
      }
      axios({
        url: apiUrl + '/bulksubscriptions',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
        }
      })
        .then(res => {
          if (res.data.subscriptions.some(pos => { return pos.index.id === this.state.assigneeId })) {
            this.props.msgAlert({
              heading: 'This User Already has a License'
            })
          } else {
            this.addAssigneeId()
          }
        })
    }
  }

  addAssigneeId = () => {
    const currArray = this.state.assignees
    currArray[this.state.index].id = this.state.assigneeId
    currArray[this.state.index].first = this.state.firstName
    currArray[this.state.index].last = this.state.lastName
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          bulkSubscriptions: {
            assignees: currArray,
            ownerStripeId: this.props.bulkSubscriptions.ownerStripeId,
            ownerPortalId: this.props.bulkSubscriptions.ownerPortalId,
            ownerCognitoId: this.props.bulkSubscriptions.ownerCognitoId,
            subscriptionStatus: this.props.bulkSubscriptions.subscriptionStatus,
            subscriptionId: this.props.bulkSubscriptions.subscriptionId,
            quantity: this.props.bulkSubscriptions.quantity
          }
        }
      }
    })
      .then(() => this.setState({ assigneeId: '', index: '', firstName: '', lastName: '' }))
      .then(this.props.getRequestAssignees)
      .then(() => {
        this.props.msgAlert({
          heading: 'Success'
        })
      })
  }

  removeAssigneeId = (event) => {
    const currArray = this.state.assignees
    const value = event.target.dataset.id
    const index = currArray.map(i => { return i.id }).indexOf(value)
    // console.log(index)
    currArray[index].id = ''
    currArray[index].first = ''
    currArray[index].last = ''
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          bulkSubscriptions: {
            assignees: currArray,
            ownerStripeId: this.props.bulkSubscriptions.ownerStripeId,
            ownerPortalId: this.props.bulkSubscriptions.ownerPortalId,
            ownerCognitoId: this.props.bulkSubscriptions.ownerCognitoId,
            subscriptionStatus: this.props.bulkSubscriptions.subscriptionStatus,
            subscriptionId: this.props.bulkSubscriptions.subscriptionId,
            quantity: this.props.bulkSubscriptions.quantity
          }
        }
      }
    })
      .then(() => this.setState({ assigneeId: '', index: '', firstName: '', lastName: '' }))
      .then(this.props.getRequestAssignees)
      .then(() => {
        this.props.msgAlert({
          heading: 'Success'
        })
      })
  }

  limit = (str, length) => {
    if (str.length <= length) {
      return str
    } else {
      return str.substring(0, length) + '.'
    }
  }

  render () {
    return (
      <React.Fragment>
        {!this.state.loading &&
        <div className="main-font-style">
          <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Manage Licenses</p>
          <ListGroup style={{ maxHeight: '50vh', overflowY: 'scroll' }}>{this.state.assignees.map((value, index) => (
            <ListGroup.Item key={index + 1}>
              <div style={{ fontWeight: 'bold', fontSize: '.7rem' }}>License {index + 1}:</div>
              <div className="d-flex">
                {!this.state[`assign${index + 1}`] &&
                <div className="col-7">
                  {!value.id &&
                  <span style={{ marginRight: '1rem' }}>Unassigned</span>
                  }
                  {!value.id &&
                  <Button onClick={() => { const assign = `assign${index + 1}`; this.setState({ [assign]: true }) }} size="sm" variant="ternary" className="white">Assign</Button>
                  }
                  {value.id &&
                  <span style={{ marginRight: '1rem' }}>{this.limit(value.first + ' ' + value.last, 11)}</span>
                  }
                  {value.id &&
                  <Button onClick={this.removeAssigneeId} data-id={value.id} size="sm" variant="danger" className="white">Unassign</Button>
                  }
                </div>
                }
                {this.state[`assign${index + 1}`] &&
                <div className="col-7">
                  <div style={{ width: '80%' }}>
                    <Form onSubmit={this.validateId} data-id={index + 1}>
                      <FormGroup as={Row} className="mb-1">
                        <Form.Control
                          required
                          placeholder= "User's ID"
                          name= {`userid${index + 1}`}
                          size= "sm"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                      <div>
                        <Button className="white" type="submit" variant="ternary" size='sm' style={{ backgroundColor: 'white', borderColor: 'white' }}><IconContext.Provider value={{ color: '#23a455', size: '1.5rem' }}><div><AiFillCheckCircle /></div></IconContext.Provider></Button>
                        <Button onClick={() => { const assign = `assign${index + 1}`; this.setState({ [assign]: false, [`userid${index + 1}`]: '' }) }} className="white" size='sm' style={{ backgroundColor: 'white', borderColor: 'white' }}><IconContext.Provider value={{ color: 'black', size: '1.5rem' }}><div><MdOutlineCancel /></div></IconContext.Provider></Button>
                      </div>
                    </Form>
                  </div>
                </div>
                }
                {!this.state[`remove${index + 1}`] &&
                <div className="col-5 d-flex justify-content-end">
                  <div style={{ width: '15%' }} onClick={() => { const remove = `remove${index + 1}`; this.setState({ [remove]: true, [`userid${index + 1}`]: '' }) }} className="">
                    <IconContext.Provider value={{ color: 'red', width: '100%', height: '100%' }}><BsTrashFill/></IconContext.Provider>
                  </div>
                </div>
                }
                {this.state[`remove${index + 1}`] &&
                <div className="col-5 d-flex justify-content-end">
                  <div>
                    <div style={{ fontSize: '.7rem' }}>Remove?</div>
                    <div>
                      <Button onClick={this.toDeleteOne} data-id={index + 1} data-btn-id={value.id} style={{ marginRight: '.1rem' }} className="white" size="sm" variant="danger">Yes</Button>
                      <Button onClick={() => { const remove = `remove${index + 1}`; this.setState({ [remove]: false }) }} className="white" size="sm" variant="ternary">No</Button>
                    </div>
                  </div>
                </div>
                }
              </div>
            </ListGroup.Item>
          ))}
          </ListGroup>
        </div>
        }
        {this.state.loading &&
        <div className="d-flex justify-content-center">
          <Spinner animation="border" size="lg"></Spinner>
        </div>
        }
      </React.Fragment>
    )
  }
}

export default BulkLicensesSubscription
