import React from 'react'
import axios from 'axios'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import apiUrl from '../../apiConfig'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import { serverError } from '../AutoDismissAlert/messages'

class CreatePortal extends React.Component {
  state = {
    loading: true,
    portalId: '',
    result: '',
    stripeCustomerId: '',
    serverError: ''
  }

  setServerErrorMessage = () => {
    if (this.state.serverError === true) {
      this.props.msgAlert({
        heading: 'Server Error',
        message: serverError
      })
    }
  }

  newPortal = () => {
    // console.log(this.state.stripeCustomerId)
    axios({
      url: apiUrl + '/portals',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          theme: 'theme-one',
          mlsinfo: {
            mls: this.props.location.props.mls,
            mlsState: this.props.location.props.state
          },
          name: {
            first: this.props.user.idToken.payload.given_name,
            last: this.props.user.idToken.payload.family_name,
            email: this.props.user.idToken.payload.email
          },
          profilepicture: {
            url: '',
            key: ''
          },
          portalAccess: false,
          stripe: {
            stripeCusId: this.state.stripeCustomerId,
            stripeSubId: '',
            stripeSubStatus: '',
            stripeDefPaymentId: ''
          }
        }
      }
    })
      .then(res => this.setState({ portalId: res.data.portal._id }))
      // .then(this.newStripeCustomer())
      .then(this.setState({ loading: false }))
  }

  newStripeCustomer = () => {
    // const timeStamp = Math.floor(Date.now() / 1000)
    // const sevenDays = 604800
    // const trialEnd = timeStamp + sevenDays
    const firstName = this.props.user.idToken.payload.given_name
    const lastName = this.props.user.idToken.payload.family_name
    axios({
      url: apiUrl + '/create-customer',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        email: this.props.user.idToken.payload.email,
        metadata: {
          id: this.props.user.accessToken.payload.username
        },
        name: `${firstName} ${lastName}`
      }
    })
      .then(res => this.setState({ stripeCustomerId: res.data.customer.id }))
      .then(this.newPortal)
      .catch((e) => {
        // console.log(e)
        this.setState({ serverError: true })
        this.props.msgAlert({
          heading: 'Something Went Wrong',
          message: 'Please try again later'
        })
      })
  }

  componentDidMount () {
    if (this.props.location.props) {
      // console.log(this.props.location.props)
      this.setState({ result: this.props.location.props.result })
    }
    setTimeout(() => {
      axios({
        url: apiUrl + '/portals',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
        }
      })
        .then((res) => {
          // console.log(res.data.portals)
          if (res.data.portals.length === 1) {
            this.setState({ loading: false, portalId: res.data.portals[0]._id, stripeCustomerId: res.data.portals[0].stripe.stripeCusId })
            this.setState({ loading: false })
          } else {
            this.newStripeCustomer()
          }
        })
        .catch(() => {
          this.setState({ serverError: true },
            () => this.setServerErrorMessage()
          )
        })
    }, 1250)
  }

  render () {
    if (this.state.serverError) {
      return <Switch><Redirect to ={{ pathname: '/sign-out' }} /></Switch>
    }
    if (!this.state.loading) {
      return <Switch><Redirect to ={{ pathname: '/portal/' + this.state.portalId, props: { result: this.state.result, stripeCustomerId: this.state.stripeCustomerId, fromCreatePortal: true } }} /></Switch>
    }
    return (
      <Container fluid className="portal-container" style={{ maxWidth: '600px', height: '100vh', backgroundColor: '#252739' }}>
        <div style={{ width: '100%', height: '100%' }} className="d-flex justify-content-center align-items-center">
          <Spinner animation='border' role='status' style={{ height: '6rem', width: '6rem', color: 'grey' }}/>
        </div>
      </Container>
    )
  }
}

export default withRouter(CreatePortal)
