import React from 'react'
import apiUrl from '../../../apiConfig'
import axios from 'axios'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
// import House from '../../../images/house.jpg'
// import SampleListings from '../../../data/samplelistings.json'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'

class ProdOpenHouses extends React.Component {
  state = {
    componentLoading: true,
    listings: '',
    nextLink: '',
    hasMoreData: false,
    skip: '',
    openHouses: '',
    renderedListings: [],
    imageModal: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/openhouses',
      method: 'POST',
      headers: {
      },
      data: {
        dateRangeStart: this.todayDate(),
        dateRangeEnd: this.getDate(),
        mlsId: this.props.portalData.mlsinfo.mls
      }
    })
      .then(res => {
        this.setOpenHouseData(res.data.listings)
      })
      .then(this.getListings)
  }

  setOpenHouseData = (openHouseArray) => {
    const openHouseData = []
    openHouseArray.forEach((value) => {
      const check = openHouseData.some((item) => {
        return value.ListingId === item.ListingId
      })
      if (!check) {
        openHouseData.push({
          ListingId: value.ListingId,
          Dates: []
        })
      }
    })
    openHouseData.forEach((value) => {
      openHouseArray.forEach((item) => {
        if (value.ListingId === item.ListingId) {
          if (value.Dates.length <= 2) {
            value.Dates.push({
              OpenHouseDate: item.OpenHouseDate,
              OpenHouseStartTime: item.OpenHouseStartTime,
              OpenHouseEndTime: item.OpenHouseEndTime
            })
          }
        }
      })
    })
    this.setState({ openHouses: openHouseData })
  }

  getDate = () => {
    const oldDate = new Date()
    oldDate.setDate(oldDate.getDate() + 7)
    return oldDate
  }

  getListings = () => {
    axios({
      url: apiUrl + '/listings',
      method: 'POST',
      headers: {
      },
      data: {
        mlsId: this.props.portalData.mlsinfo.mls,
        zip1: this.props.portalData.zipcodes.zip1,
        zip2: this.props.portalData.zipcodes.zip2,
        zip3: this.props.portalData.zipcodes.zip3
      }
    })
      .then(res => {
        // console.log(res.data.listings)
        this.renderListings(res.data.listings.value)
        if (res.data.listings['@odata.nextLink']) {
          this.setState({ nextLink: res.data.listings['@odata.nextLink'], hasMoreData: true, skip: 10 })
        }
      })
  }

    // for each listing, loop through the open houseses, and return where the mlsid === listingid. Push that value into an array. Then render that array
    renderListings = (market) => {
      const arr = []
      market.forEach((market) => {
        this.state.openHouses.forEach((oh) => {
          if (market.ListingId === oh.ListingId) {
            arr.push({
              mlsId: market.ListingId,
              image: market.Media[0].MediaURL,
              media: market.Media,
              dates: oh.Dates,
              price: market.ListPrice,
              beds: market.BedroomsTotal,
              baths: market.BedroomsFull,
              sqft: market.LivingArea,
              address: market.UnparsedAddress
            })
          }
        })
      })
      const oldArr = this.state.renderedListings
      const newArr = oldArr.concat(arr)
      this.setState({ renderedListings: newArr, componentLoading: false })
    }

  fetchItems = () => {
    if (this.state.hasMoreData) {
      axios({
        url: apiUrl + '/listings',
        method: 'POST',
        headers: {
        },
        data: {
          skip: this.state.skip,
          mlsId: this.props.portalData.mlsinfo.mls,
          zip1: this.props.portalData.zipcodes.zip1,
          zip2: this.props.portalData.zipcodes.zip2,
          zip3: this.props.portalData.zipcodes.zip3
        }
      })
        .then(res => {
          // console.log(res.data)
          this.renderListings(res.data.listings.value)
          if (res.data.listings['@odata.nextLink']) {
            const skip = parseFloat(this.state.skip) + 10
            this.setState({ nextLink: res.data.listings['@odata.nextLink'], hasMoreData: true, skip: skip })
          } else {
            this.setState({ hasMoreData: false, nextLink: '', skip: '' })
          }
        })
    }
  }

  todayDate = () => {
    let theDay
    let month
    const currentDate = new Date()
    const date = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
    const dateArr = date.split('-')
    if (dateArr[1] < 10) {
      theDay = '0'.concat(dateArr[1])
    } else {
      theDay = dateArr[1]
    }
    if (dateArr[2] < 10) {
      month = '0'.concat(dateArr[2])
    } else {
      month = dateArr[2]
    }
    return `${dateArr[0]}-${theDay}-${month}`
  }

  convertDate = (input) => {
    // console.log(input, 'date')
    const myDate = new Date(input)
    const dateString = myDate.toString()
    const dateArr = dateString.split(' ')
    const newArr = [dateArr[0], dateArr[1], dateArr[2]]
    return newArr.join(' ')
  }

  convertTime = (input) => {
    // console.log(input, 'time')
    const myDate = new Date(input)
    // console.log(myDate)
    const toLocal = myDate.toString()
    const dateArr = toLocal.split(' ')
    // console.log(dateArr)
    const string = dateArr[4]
    return moment(string, 'HH:mm:ss').format('h:mm a')
  }

  imageModal = (listing) => {
    // console.log(listing, 'listing')
    const mediaListing = this.state.renderedListings.find((index) => {
      return index.mlsId === listing.mlsId
    })
    this.setState({ media: mediaListing.media, imageModal: true })
  }

  render () {
    return (
      <Container fluid style={{ maxWidth: '600px' }} id="listings">
        {!this.state.componentLoading &&
        <InfiniteScroll
          loadMore={this.fetchItems}
          hasMore={this.state.hasMoreData}
          loader={
            <div className="d-flex justify-content-center mt-5">
              {this.state.hasMoreData &&
            <div>
              <Spinner size="lg" animation="border"></Spinner>&nbsp;Loading...
            </div>
              }
              {!this.state.hasMoreData &&
            <p style={{ textAlign: 'center' }}>No more data</p>
              }
            </div>
          }
        >
          <div>
            {this.state.renderedListings.map((listing) => (
              <div key={listing.mlsId}>
                <div className='ratio ratio-16x9 mt-3'>
                  <div onClick={() => this.imageModal(listing)} style={{ backgroundImage: `url(${listing.image})` }} className="profile-img-sect">
                    <div style={{ marginTop: '30%' }}>
                      {listing.dates.map((date) => (
                        <div key={date.OpenHouseStartTime} style={{ backgroundColor: 'rgba(46,46,61,0.75)', width: '100%', textAlign: 'center', marginBottom: '.2rem' }} className="secondary-font-style small-text-profile white">{this.state.componentLoading ? '' : this.convertDate(date.OpenHouseStartTime)} | {this.state.componentLoading ? '' : this.convertTime(date.OpenHouseStartTime)} - {this.state.componentLoading ? '' : this.convertTime(date.OpenHouseEndTime)}</div>
                      ))}
                    </div>
                  </div>
                </div>
                <div style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, .25)', paddingLeft: '.5rem', paddingTop: '.2rem', paddingBottom: '.2rem', backgroundColor: 'white' }} className='main-font-style small-text mb-3'>
                  <div style={{ fontWeight: 'bold' }} className="secondary-font-style">${this.state.componentLoading ? '' : listing.price.toLocaleString()}</div>
                  <div>{listing.beds} Beds | {listing.baths} Baths | {listing.sqft} SQFT</div>
                  <div>{listing.address}</div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
        }
        {this.state.media &&
        <Modal size="lg" show={this.state.imageModal} backdrop={true}>
          <Modal.Body>
            <Carousel variant="dark" indicators={false}>
              {this.state.media.map((image) => (
                <Carousel.Item key={image.MediaURL}>
                  <Carousel.Caption style={{ width: '100%', left: '0', right: '0', bottom: '0', top: '0', paddingTop: '0', paddingBottom: '0' }}>
                    <div className="d-flex justify-content-end" style={{ width: '100%' }}>
                      <Button onClick={() => this.setState({ imageModal: false })} className="carousel-button" variant="outline-primary" style={{ border: 'none', color: 'black' }}>X</Button>
                    </div>
                  </Carousel.Caption>
                  <img
                    className="d-block w-100"
                    src={image.MediaURL}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
        }
      </Container>
    )
  }
}

export default ProdOpenHouses
