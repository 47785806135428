import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from './../../../images/qrcode.png'

class Container4 extends React.Component {
  state = {

  }

  render () {
    return (
      <Container className="landing-cont4-main-container" fluid style={{ backgroundColor: '#edf1f2' }}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <img src={Image} className="landing-cont4-img"/>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="landing-agent-profile">
              <p className="small-text main-font-style mb-0" style={{ fontWeight: 'bold' }}>Agent Profile</p>
              <p className="small-text main-font-style">Your unique QR Code unlocks your agent profile for the motivated buyer to see. They’ll be able to view the featured open house, a feed of other open houses in the area, and most importantly, options to contact you directly!</p>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

export default Container4
