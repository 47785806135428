import React from 'react'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import UserPool from './../SignUp2/UserPool'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import { SiOpsgenie } from 'react-icons/si'
// import { IconContext } from 'react-icons'
import SignInForm from './SignInForm'
import ResetPassword from './ResetPassword'
import Logo2 from './../../../images/logo2.png'

class SignIn2 extends React.Component {
  state = {
    view: '',
    email: '',
    password: '',
    newPassword: '',
    verificationCode: '',
    errorConfirm: '',
    emailResent: '',
    errorCredential: '',
    errorVerificationCode: '',
    newPasswordError: '',
    newPasswordNError: '',
    newPasswordUError: '',
    newPasswordLError: '',
    verErrorMsg: '',
    validation: '',
    loginSuccess: ''
  }

  emptyErrors = () => {
    this.setState({ emailResent: '', errorCredential: '', verErrorMsg: '' })
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value },
      () => {
        this.handleErrors(event)
      })
  }

  forgotPassword = (event) => {
    event.preventDefault()
    const user = new CognitoUser({
      Username: this.state.email,
      Pool: UserPool
    })
    user.forgotPassword({
      onSuccess: (data) => {
        // console.log('onSuccess: ', data)
        this.setState({ view: 'reset' })
      },
      onFailure: (err) => {
        console.error('onFailure: ', err.message)
      }
    })
  }

  passwordResetVer = (event) => {
    event.preventDefault()
    const user = new CognitoUser({
      Username: this.state.email,
      Pool: UserPool
    })
    user.confirmPassword(this.state.verificationCode, this.state.newPassword, {
      onSuccess: (data) => {
        // console.log('onSuccess: ', data)
        this.setState({ view: '', password: '', errorCredential: '', verificationCode: '', newPassword: '', verErrorMsg: '', validation: '' })
      },
      onFailure: (err) => {
        console.error('onFailure: ', err.message)
        this.setState({ verErrorMsg: `${err.message}` })
      }
    })
  }

  resendConfirmation = (event) => {
    event.preventDefault()
    const user = new CognitoUser({
      Username: this.state.email,
      Pool: UserPool
    })
    user.resendConfirmationCode((err, data) => {
      if (err) {
        console.error(err)
      } else {
        // console.log(data)
        this.setState({ errorConfirm: '', emailResent: true, password: '' })
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const user = new CognitoUser({
      Username: this.state.email,
      Pool: UserPool
    })
    const authDetails = new AuthenticationDetails({
      UserName: this.state.email,
      Password: this.state.password
    })
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        // console.log('onSuccess: ', data)
        this.props.setUser(data)
        // console.log(this.props.user)
        this.setState({ loginSuccess: true })
      },
      onFailure: (err) => {
        console.error('onFailure: ', err.message)
        if (err.message === 'User is not confirmed.') {
          this.setState({ errorConfirm: true })
        } else if (err.message === 'Incorrect username or password.') {
          this.setState({ errorCredential: true })
        }
      },
      newPasswordRequired: (data) => {
        // console.log('newPasswordRequired: ', data)
      }
    })
  }

  handleErrors = (event) => {
    if (event.target.name === 'verificationCode') {
      if (event.target.value.length < 6) {
        this.setState({ errorVerificationCode: true },
          () => {
            this.validation()
          })
      } else {
        this.setState({ errorVerificationCode: false },
          () => {
            this.validation()
          })
      }
    } else if (event.target.name === 'newPassword') {
      const string = event.target.value
      const upperRegex = /(?=.*[A-Z])/
      const lowerRegex = /(?=.*[a-z])/
      const numberRegex = /(?=.*[0-9])/
      if (string.length < 8) {
        this.setState({ newPasswordError: '*Minimum 8 Characters' },
          () => {
            this.validation()
          })
      } else {
        this.setState({ newPasswordError: '' },
          () => {
            this.validation()
          })
      }
      if (string.match(upperRegex)) {
        this.setState({ newPasswordUError: '' },
          () => {
            this.validation()
          })
      } else {
        this.setState({ newPasswordUError: '*Must Include Uppercase Letter' },
          () => {
            this.validation()
          })
      }
      if (string.match(lowerRegex)) {
        this.setState({ newPasswordLError: '' },
          () => {
            this.validation()
          })
      } else {
        this.setState({ newPasswordLError: '*Must Include Lowercase Letter' },
          () => {
            this.validation()
          })
      }
      if (string.match(numberRegex)) {
        this.setState({ newPasswordNError: '' },
          () => {
            this.validation()
          })
      } else {
        this.setState({ newPasswordNError: '*Must Include a Number' },
          () => {
            this.validation()
          })
      }
    }
  }

  validation = () => {
    if (this.state.newPassword && this.state.verificationCode && !this.state.newPasswordError && !this.state.newPasswordUError && !this.state.newPasswordLError && !this.state.newPasswordNError && !this.state.errorVerificationCode) {
      this.setState({ validation: true })
    } else {
      this.setState({ validation: false })
    }
  }

  render () {
    if (this.state.loginSuccess) {
      return <Switch><Redirect to ={{ pathname: '/selectmls' }} /></Switch>
    }
    return (
      <Container className="auth-container" style={{ maxWidth: '600px' }}>
        <Container fluid><a href='' onClick={() => this.props.history.push('/')} className='primary-font-style large-text' style={{ textAlign: 'right', textDecoration: 'none', color: '#c4c4c4', display: 'block', fontWeight: '10', paddingRight: '.5rem', paddingTop: '.5rem' }}>X</a></Container>
        <Row style={{ height: '35%' }} className="d-flex align-content-around" >
          <div>
            {this.state.view === '' &&
            <Col className="d-flex justify-content-center">
              <div style={{ width: '40%' }} className='ratio ratio-4x3'>
                <img className='' src={Logo2} alt='genie-logo' />
              </div>
            </Col>
            }
            {this.state.view === 'reset' &&
            <Col className="d-flex justify-content-center">
              <h1 className="main-font-style" style={{ fontWeight: 'bold', textAlign: 'center' }}>Password Reset</h1>
            </Col>
            }
          </div>
          {this.state.view === '' &&
          <div>
            <h1 className="main-font-style" style={{ fontWeight: 'bold', textAlign: 'center', padding: '0' }}>Sign In</h1>
            <div className='p-0' style={{ width: '100%' }}>
            </div>
          </div>
          }
          {this.state.view === 'reset' &&
          <div>
            <p className="main-font-style small-text" style={{ textAlign: 'center', paddingRight: '1rem', paddingLeft: '1rem' }}>A verification code has been sent to <span style={{ fontWeight: 'bold' }}>{this.state.email}</span>, if this email is registered. Type the verification code below along with a new password.</p>
          </div>
          }
        </Row>
        <Row style={{ height: '65%' }}>
          <Col className="auth-form-div-wrapper">
            <div className={this.state.view === '' ? 'auth-form-div' : 'auth-form-div-reset'}>
              {this.state.view === '' &&
              <SignInForm {...this.state} emptyErrors={this.emptyErrors} handleSubmit={this.handleSubmit} handleChange={this.handleChange} resendConfirmation={this.resendConfirmation} forgotPassword={this.forgotPassword}></SignInForm>
              }
              {this.state.view === 'reset' &&
              <ResetPassword {...this.state} handleChange={this.handleChange} emptyErrors={this.emptyErrors} passwordResetVer={this.passwordResetVer}></ResetPassword>
              }
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(SignIn2)
