import React from 'react'
import axios from 'axios'
import apiUrl from '../../apiConfig'
// import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import ReactLoading from 'react-loading'

class ProfilePicture extends React.Component {
  state = {
    view: '',
    modal: '',
    createPic: '',
    editPic: '',
    loading: true,
    file: '',
    pictureId: '',
    pictureUrl: '',
    pictureKey: '',
    validated: false,
    selected: ''
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.portals[0].profilepicture.key) {
          this.setState({ pictureId: res.data.portals[0].profilepicture._id, pictureUrl: res.data.portals[0].profilepicture.url, pictureKey: res.data.portals[0].profilepicture.key, view: 'pic' })
        } else {
          this.setState({ createPic: true })
        }
      })
      .then(this.setState({ loading: false }))
  }

  // handleSubmit = (event) => {
  //   event.preventDefault()
  //   this.setState({ loading: true })
  //   const data = new FormData()
  //   data.append('file', selected)
  //   axios({
  //     url: apiUrl + '/files',
  //     method: 'POST',
  //     data: data
  //   })
  //     .then((res) => this.setState({ file: res.data.file }))
  //     .then(() => this.setState({ loading: false }))
  //     .catch(console.error)
  // }

  // <div className="profile-pic-circle d-flex align-items-center">
  // <p style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'underline' }} className="small-text">Add Profile Picture</p>
  // </div>

  closeModal = () => {
    this.setState({ modal: false, file: '', selected: false })
  }

  handleChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      this.setState({ [event.target.name]: file })
      this.setState({ selected: true })
      const arr = file.name.split('.')
      if (arr[arr.length - 1] === 'jpg' || arr[arr.length - 1] === 'jpeg' || arr[arr.length - 1] === 'png' || arr[arr.length - 1] === 'bmp') {
        this.setState({ validated: true })
      } else {
        this.setState({ validated: false })
      }
    }
  }

  handleSubmit = (event) => {
    this.setState({ loading: true, selected: false })
    if (!this.state.editPic) {
      event.preventDefault()
    }

    const data = new FormData()
    data.append('file', this.state.file)
    data.append('portalId', this.props.portalId)

    axios({
      url: apiUrl + '/profilepictures',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: data
    })
      .then(res => this.setState({ pictureId: res.data.portal.profilepicture._id, pictureUrl: res.data.portal.profilepicture.url, pictureKey: res.data.portal.profilepicture.key, view: 'pic', file: '' }))
      .then(this.setState({ modal: false }))
      .then(this.setState({ createPic: '', editPic: '' }))
      .then(this.setState({ loading: false }))
      // .then(this.deletePic)
  }

  handleEditSubmit = (event) => {
    event.preventDefault()
    this.setState({ modal: false, loading: true })
    this.deletePic()
  }

  deletePic = () => {
    axios({
      url: apiUrl + '/profilepictures/' + this.state.pictureId,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portalId: this.props.portalId,
        key: this.state.pictureKey
      }
    })
      .then(this.handleSubmit())
  }

  render () {
    let jsx
    if (this.state.loading) {
      jsx = (
        <div className="profile-pic-circle d-flex align-items-center" style={{ backgroundColor: '#252739' }}>
          <ReactLoading type='spokes' color='white' height='100%' width='100%'/>
        </div>
      )
    } else {
      if (!this.state.view) {
        jsx = (
          <React.Fragment>
            {this.state.createPic &&
            <div onClick={() => this.setState({ modal: true })} className="profile-pic-circle d-flex align-items-center" style={{ backgroundColor: '#c4c4c4' }}>
              <p style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'underline' }} className="small-text">Add Profile Picture</p>
            </div>
            }
          </React.Fragment>
        )
      } else {
        jsx = (
          <React.Fragment>
            <div onClick={() => this.setState({ modal: true, editPic: true })} className="profile-pic-circle d-flex align-items-center">
              <img className="profile-pic-img" alt="uploaded file" src={this.state.pictureUrl}/>
            </div>
          </React.Fragment>
        )
      }
    }
    return (
      <React.Fragment>
        {jsx}
        <Modal show={this.state.modal} size="sm" onHide={this.closeModal}>
          <Modal.Header closeButton>
            {this.state.createPic &&
            <Modal.Title className="main-font-style">Select a File</Modal.Title>
            }
            {this.state.editPic &&
            <Modal.Title className="main-font-style">Edit Profile Picture</Modal.Title>
            }
          </Modal.Header>
          <Modal.Body>
            {this.state.createPic &&
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="mb-2">
                <Form.Control
                  required
                  type= 'file'
                  name='file'
                  accept="image/*"
                  onChange={this.handleChange}
                  onFocus={() => this.setState({ selected: false })}
                />
              </Form.Group>
              <Form.Group><Form.Text className="main-font-style">Supported Formats: <span className={!this.state.validated && this.state.selected ? 'black' : ''}>JPG, JPEG, PNG, BMP</span></Form.Text></Form.Group>
              <Button style={{ float: 'right' }} type='submit' variant="ternary" className="white">Upload</Button>
            </Form>
            }
            {this.state.editPic &&
            <Form onSubmit={this.handleEditSubmit}>
              <Form.Group className="mb-2">
                <Form.Control
                  required
                  type= 'file'
                  name='file'
                  accept="image/*"
                  onChange={this.handleChange}
                  onFocus={() => this.setState({ selected: false })}
                />
              </Form.Group>
              <Form.Group><Form.Text className="main-font-style">Supported Formats: <span className={!this.state.validated && this.state.selected ? 'black' : ''}>JPG, JPEG, PNG, BMP</span></Form.Text></Form.Group>
              <Button disabled={!this.state.validated} style={{ float: 'right' }} type='submit' variant="ternary" className="white">Upload</Button>
            </Form>
            }
          </Modal.Body>
        </Modal>
        {/* <Form>
          <Form.Group>
            <Form.Control
              type= 'file'
            />
          </Form.Group>
        </Form> */}
      </React.Fragment>
    )
  }
}

export default ProfilePicture
