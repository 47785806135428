import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { TiWarning } from 'react-icons/ti'
import { IconContext } from 'react-icons'

class Unauthorized extends React.Component {
  state = {}

  render () {
    if (this.props.user) {
      return <Redirect to={{ pathname: '/sign-out' }} />
    }
    return (
      <div style={{ height: '100vh' }} className="d-flex flex-column justify-content-center">
        <div style={{ height: '60%' }} className="d-flex flex-column justify-content-evenly">
          <div>
            <p style={{ textAlign: 'center' }} className="large-text main-font-style">You are not Authorized to View this Content</p>
          </div>
          <div className="d-flex justify-content-center">
            <IconContext.Provider value={{ color: '#23a455', className: 'alert-icon' }}>
              <div>
                <TiWarning />
              </div>
            </IconContext.Provider>
          </div>
          <div>
            <p style={{ textAlign: 'center' }} className="med-text main-font-style">Please <span><a href='' onClick={() => this.props.history.push('/sign-in')} style={{ textDecoration: 'none', fontWeight: 'bold', color: 'black' }}>Sign In</a></span></p>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Unauthorized)
