import React from 'react'
import apiUrl from '../../../apiConfig'
import axios from 'axios'
import moment from 'moment'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import { withRouter, Redirect, Switch } from 'react-router-dom'
import { IoBedSharp } from 'react-icons/io5'
import { FaExpand, FaDirections, FaBath, FaExpandArrowsAlt, FaArrowDown } from 'react-icons/fa'
import { IconContext } from 'react-icons'
// import House from '../../../images/house.jpg'
// import TestListings from './Listings'
// import ProductionListings from './ProdListings'
// import ImageModal from './ImageModal'
import ProdOpenHouses from './ProdOpenHouses'
import ImgModal from './ImgModal'
import TestOpenHouses from './TestOpenHouses'

class ViewProfile extends React.Component {
  state = {
    portal: false,
    portalData: '',
    openHouseData: '',
    listingData: '',
    adminListingData: '',
    theme: '',
    openHouseloading: true,
    imageModal: false,
    adminOpenHouseDates: [
      'June 1st 11:30AM-2:00PM',
      'June 2nd 12:00PM-4:00PM',
      'June 3rd 1:00PM-5:00PM'
    ],
    noOpenHouses: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => this.setState({ portalData: res.data.portals[0], theme: res.data.portals[0].theme }))
      .then(() => {
        if (this.state.portalData.mlsinfo.mlsid === '123456789') {
          this.getListingData()
        } else {
          this.getOpenHouseData()
        }
      })
  }

  closeImageModal = () => {
    this.setState({ imageModal: false })
  }

  toPortal = () => {
    this.setState({ portal: true })
  }

  getListingData = () => {
    if (this.state.portalData.mlsinfo.mlsid === '123456789') {
      axios({
        url: apiUrl + '/testlisting',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
        }
      })
        .then(res => this.setState({ adminListingData: res.data.listing.value[0], openHouseloading: false }))
    } else {
      axios({
        url: apiUrl + '/listing',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
        },
        data: {
          listingid: this.state.openHouseData.ListingId,
          mlsId: this.state.portalData.mlsinfo.mls
        }
      })
        .then(res => this.setState({ listingData: res.data.listing.value[0] }))
    }
  }

  getOpenHouseData = () => {
    // console.log('loading')
    axios({
      url: apiUrl + '/openhouse',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        listingid: this.state.portalData.featuredoh.id,
        mlsId: this.state.portalData.mlsinfo.mls
      }
    })
      .then(res => this.setOpenHouseData(res.data.listing.value))
  }

  setOpenHouseData = (dataArray) => {
    // console.log(dataArray, 'dataArray')
    const newObject = {
      ListingId: dataArray[0].ListingId,
      Dates: []
    }
    dataArray.forEach((item) => {
      if (item.OpenHouseStatus !== 'Ended') {
        newObject.Dates.push({
          OpenHouseEndTime: item.OpenHouseEndTime,
          OpenHouseStartTime: item.OpenHouseStartTime,
          OpenHouseDate: item.OpenHouseDate
        })
      }
    })
    if (newObject.Dates.length >= 1) {
      this.setState({ openHouseData: newObject, openHouseloading: false },
        () => {
          this.getListingData()
        })
    } else {
      this.setBackupOpenHouse()
    }
  }

  setBackupOpenHouse = () => {
    axios({
      url: apiUrl + '/backupopenhouse',
      method: 'POST',
      headers: {
      },
      data: {
        mlsId: this.state.portalData.mlsinfo.mls,
        zip1: this.state.portalData.zipcodes.zip1,
        zip2: this.state.portalData.zipcodes.zip2,
        zip3: this.state.portalData.zipcodes.zip3,
        dateRangeStart: this.todayDate(),
        dateRangeEnd: this.getDate()
      }
    })
      .then(res => {
        // console.log(res.data)
        if (res.data.openHouse.nextLinks) {
          if (res.data.openHouse.nextLinks.openHouse) {
            this.searchMoreBackupOpenHouses(res.data.openHouse.nextLinks.openHouse)
          } else {
            this.setState({ noOpenHouses: true })
          }
        } else {
          this.getBackupOpenHouseData(res.data.openHouse.ListingId)
        }
      })
  }

  searchMoreBackupOpenHouses = (link) => {
    axios({
      url: apiUrl + '/backupopenhouse',
      method: 'POST',
      headers: {
      },
      data: {
        mlsId: this.state.portalData.mlsinfo.mls,
        zip1: this.state.portalData.zipcodes.zip1,
        zip2: this.state.portalData.zipcodes.zip2,
        zip3: this.state.portalData.zipcodes.zip3,
        link: link
      }
    })
      .then(res => {
        if (res.data.openHouse.nextLinks) {
          // console.log(res.data.openHouse)
          if (res.data.openHouse.nextLinks.openHouse) {
            this.searchMoreBackupOpenHouses(res.data.openHouse.nextLinks.openHouse)
          } else {
            this.setState({ noOpenHouses: true })
          }
        } else if (res.data.openHouse.ListingId) {
          this.getBackupOpenHouseData(res.data.openHouse.ListingId)
        } else if (!res.data.openHouse.ListingId && !res.data.openHouse.nextLinks.openHouse) {
          this.setState({ noOpenHouses: true })
        }
      })
  }

  getBackupOpenHouseData = (id) => {
    // console.log('loading')
    axios({
      url: apiUrl + '/openhouse',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        listingid: id,
        mlsId: this.state.portalData.mlsinfo.mls
      }
    })
      .then(res => this.setOpenHouseData(res.data.listing.value))
  }

  todayDate = () => {
    let theDay
    let month
    const currentDate = new Date()
    const date = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
    const dateArr = date.split('-')
    if (dateArr[1] < 10) {
      theDay = '0'.concat(dateArr[1])
    } else {
      theDay = dateArr[1]
    }
    if (dateArr[2] < 10) {
      month = '0'.concat(dateArr[2])
    } else {
      month = dateArr[2]
    }
    return `${dateArr[0]}-${theDay}-${month}`
  }

  // todayDate = () => {
  //   return new Date()
  // }

  getDate = () => {
    const oldDate = new Date()
    oldDate.setDate(oldDate.getDate() + 7)
    return oldDate
  }

  changeTheme = (event) => {
    const newTheme = event.target.dataset.id
    this.setState({ theme: newTheme })
    axios({
      url: apiUrl + '/portals/' + this.state.portalData._id,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          theme: newTheme
        }
      }
    })
  }

  calcPricePerSqft = () => {
    let price
    if (this.state.portalData.mlsinfo.mlsid === '123456789') {
      price = 102.5
    } else {
      price = parseFloat(this.state.listingData.ListPrice) / parseFloat(this.state.listingData.LivingArea)
    }
    if (price.toString().length < 6) {
      return price
    } else {
      return price.toString().substring(0, 6)
    }
  }

  scrollDown = (event) => {
    event.preventDefault()
    // console.log(event.currentTarget.dataset.anchor)
    const id = event.currentTarget.dataset.anchor
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  limit = (str, length) => {
    if (str.length <= length) {
      return str
    } else {
      return str.substring(0, length)
    }
  }

  convertDate = (input) => {
    // console.log(input, 'date')
    const myDate = new Date(input)
    // console.log(myDate)
    const dateString = myDate.toString()
    // console.log(dateString)
    const dateArr = dateString.split(' ')
    // console.log(dateArr)
    const newArr = [dateArr[0], dateArr[1], dateArr[2]]
    // console.log(newArr)
    return newArr.join(' ')
  }

  convertTime = (input) => {
    // console.log(input, 'time')
    const myDate = new Date(input)
    // console.log(myDate)
    const toLocal = myDate.toString()
    const dateArr = toLocal.split(' ')
    // console.log(dateArr)
    const string = dateArr[4]
    return moment(string, 'HH:mm:ss').format('h:mm a')
  }

  render () {
    if (this.state.portal) {
      return <Switch><Redirect to ={{ pathname: '/portal/' + this.props.match.params.id }} /></Switch>
    }
    const show = (this.state.portalData) && (this.state.listingData) && (!this.state.openHouseloading)
    const showAdmin = (this.state.portalData) && (!this.state.listingData)
    return (
      <React.Fragment>
        {!this.state.noOpenHouses &&
        <React.Fragment>
          <React.Fragment>
            {this.state.openHouseloading &&
            <Container className={'profile-container profile-border-color profile-sect' + ' ' + this.state.theme} style={{ maxWidth: '600px', height: '100vh' }}>
              <div style={{ width: '100%', height: '100%' }} className="d-flex justify-content-center align-items-center">
                <Spinner animation='border' role='status' style={{ height: '6rem', width: '6rem', color: 'grey' }}/>
              </div>
            </Container>
            }
          </React.Fragment>
          {show &&
          <React.Fragment>
            <Container className='profile-container profile-border-color profile-sect' style={{ maxWidth: '600px', height: '100vh' }}>
              <div className="profile-img-sect profile-img-sect-color" style={{ backgroundImage: `url(${this.state.listingData.Media[0].MediaURL})` }}>
                <div className="row">
                  <div className="col-4" style={{ paddingRight: '0' }}>
                    <Button onClick={this.toPortal} style={{ marginTop: '.4rem', marginLeft: '.4rem', marginRight: '0' }} variant="primary" size="sm">Back to Portal</Button>
                  </div>
                  <div className="col d-flex align-items-center" style={{ marginTop: '.4rem' }}>
                    <div style={{ backgroundColor: '#23a455', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-one" onClick={this.changeTheme}></div> {/* Leads Genie */}
                    <div style={{ backgroundColor: '#B40101', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-two" onClick={this.changeTheme}></div> {/* Keller Williams */}
                    <div style={{ backgroundColor: '#BEAF87', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-three" onClick={this.changeTheme}></div> {/* Century 21 */}
                    <div style={{ backgroundColor: '#012169', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-four" onClick={this.changeTheme}></div> {/* Coldwell Banker */}
                    <div style={{ backgroundColor: '#dc1c2e', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-five" onClick={this.changeTheme}></div> {/* Remax */}
                    <div style={{ backgroundColor: '#C82021', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-six" onClick={this.changeTheme}></div> {/* Redfin */}
                  </div>
                </div>
                <IconContext.Provider value={{ className: 'expand-icon' }}>
                  <div onClick={() => this.setState({ imageModal: true })} style={{ paddingRight: '1.2rem' }} className="d-flex justify-content-end">
                    <FaExpand></FaExpand>
                  </div>
                </IconContext.Provider>
                <div className="d-flex flex-column justify-content-between" style={{ height: '69%', paddingBottom: '1rem', marginTop: '-20px' }} >
                  <div style={{ backgroundColor: 'rgba(46,46,61,0.75)', width: '50%', borderRadius: '0 20px 20px 0' }}><h1 className="secondary-font-style small-text-profile" style={{ color: 'white', textAlign: 'center', marginTop: '.5rem' }}>Featured Open House</h1></div>
                </div>
              </div>
              <div style={{ height: '45%' }}>
                <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: 'white', height: '10%' }}><h2 className="secondary-font-style med-text-profile" style={{ color: 'black', textAlign: 'center', margin: '0' }}>{this.state.openHouseloading ? '' : this.convertDate(this.state.openHouseData.Dates[0].OpenHouseStartTime)} | {this.state.openHouseloading ? '' : this.convertTime(this.state.openHouseData.Dates[0].OpenHouseStartTime)} - {this.state.openHouseloading ? '' : this.convertTime(this.state.openHouseData.Dates[0].OpenHouseEndTime)}</h2></div>
                <Carousel interval={null} style={{ height: '90%' }} className={'profile-desc-sect profile-desc-sect-color' + ' ' + this.state.theme}>
                  <Carousel.Item style={{ height: '90%' }}>
                    <div className="d-flex flex-column justify-content-around" style={{ height: '100%', width: '100%', paddingLeft: '1rem', paddingRight: '1rem', color: 'white' }}>
                      <div style={{ paddingRight: '2rem', paddingLeft: '2rem' }} className="d-flex flex-row justify-content-between">
                        <div className="main-font-style"><p className="m-0 small-text-profile">{this.state.portalData.mlsinfo.mlsid === '123456789' ? '123' : this.state.listingData.StreetNumberNumeric} {this.state.portalData.mlsinfo.mlsid === '123456789' ? 'Main' : this.state.listingData.StreetName} {this.state.portalData.mlsinfo.mlsid === '123456789' ? 'Street' : this.state.listingData.StreetSuffix}</p><p style={{ color: '#c4c4c4' }} className="m-0 small-text-profile">{this.state.listingData.City}, {this.state.listingData.StateOrProvince} {this.state.portalData.mlsinfo.mlsid === '123456789' ? '33333' : this.state.listingData.PostalCode}</p></div>
                        <div className="d-flex align-items-center"><IconContext.Provider value={{ className: 'directions-icon variable-checks' }}><FaDirections></FaDirections></IconContext.Provider></div>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <div className="col-4 d-flex justify-content-center"><div className="desc-card-bg-color desc-card-bg"><div className="d-flex justify-content-center desc-icon-sect"><IconContext.Provider value={{ className: 'directions-icon variable-checks' }}><IoBedSharp></IoBedSharp></IconContext.Provider></div><div className="main-font-style desc-text-sect"><p className="small-text-profile mb-0" style={{ color: '#c4c4c4', textAlign: 'center' }}>{this.state.portalData.mlsinfo.mlsid === '123456789' ? '2' : this.state.listingData.BedroomsTotal} Bedrooms</p></div></div></div>
                        <div className="col-4 d-flex justify-content-center"><div className="desc-card-bg-color desc-card-bg"><div className="d-flex justify-content-center desc-icon-sect"><IconContext.Provider value={{ className: 'directions-icon variable-checks' }}><FaBath></FaBath></IconContext.Provider></div><div className="main-font-style desc-text-sect"><p className="small-text-profile mb-0" style={{ color: '#c4c4c4', textAlign: 'center' }}>{this.state.portalData.mlsinfo.mlsid === '123456789' ? '3' : this.state.listingData.BathroomsTotalInteger} Bathrooms</p></div></div></div>
                        <div className="col-4 d-flex justify-content-center"><div className="desc-card-bg-color desc-card-bg"><div className="d-flex justify-content-center desc-icon-sect"><IconContext.Provider value={{ className: 'directions-icon variable-checks' }}><FaExpandArrowsAlt></FaExpandArrowsAlt></IconContext.Provider></div><div className="main-font-style desc-text-sect"><p className="small-text-profile mb-0" style={{ color: '#c4c4c4', textAlign: 'center' }}>{this.state.portalData.mlsinfo.mlsid === '123456789' ? '1200' : this.state.listingData.LivingArea} ft2</p></div></div></div>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <div className="col-4 main-font-style small-text-profile d-flex justify-content-center"><div><p style={{ color: '#c4c4c4' }} className='m-0 p-0'>Listing Price:</p><p className='m-0 p-0'>${this.state.openHouseloading ? '' : this.state.listingData.ListPrice.toLocaleString()}</p></div></div>
                        <div className="col-4 main-font-style small-text-profile d-flex justify-content-center"><div><p style={{ color: '#c4c4c4' }} className='m-0 p-0'>DOM:</p><p className='m-0 p-0'>{this.state.portalData.mlsinfo.mlsid === '123456789' ? '123' : this.state.listingData.DaysOnMarket} Days</p></div></div>
                        <div className="col-4 main-font-style small-text-profile d-flex justify-content-center"><div><p style={{ color: '#c4c4c4' }} className='m-0 p-0'>Price/Sqft:</p><p className='m-0 p-0'>${this.calcPricePerSqft()}</p></div></div>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item style={{ color: 'white', height: '90%' }}>
                    <div style={{ height: '100%', paddingLeft: '2rem', paddingRight: '2rem' }} className="d-flex flex-column justify-content-around">
                      <div className="main-font-style white small-text"><p style={{ textAlign: 'center' }}>Download a FREE copy of the report here!</p><div className="d-flex justify-content-center" style={{ width: '100%' }}><Button className="variable-buttons">Download</Button></div></div>
                      <div className="main-font-style white small-text"><p style={{ textAlign: 'center' }}>See More Open Houses Below!</p><div className="d-flex justify-content-center"><IconContext.Provider value={{ className: 'directions-icon' }}><FaArrowDown></FaArrowDown></IconContext.Provider></div></div>
                      <div className="main-font-style white"><p style={{ textAlign: 'center', fontSize: '.7rem' }}>{this.state.portalData.mlsinfo.brokerOfficeName} | {this.state.portalData.mlsinfo.brokerid} | {this.state.portalData.mlsinfo.brokerOfficePhone}</p></div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className={this.state.theme} style={{ height: '20%' }}>
                <div className="profile-agent-sect profile-agent-sect-color" style={{ height: '100%' }}>
                  <div className="d-flex flex-column justify-content-around" style={{ paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '.5rem', height: '100%' }}>
                    <div className="d-flex flex-row">
                      <div style={{ marginRight: '.8rem' }} className="agent-headshot-circle d-flex align-items-center">
                        <img className="profile-pic-img-profile" alt="uploaded file" src={this.state.portalData.profilepicture.url}/>
                      </div>
                      <div className="small-text-profile main-font-style"><p className="m-0 white">{this.state.portalData.name.first} {this.state.portalData.name.last}</p><p className="m-0 small-text-profile main-font-style" style={{ color: '#c4c4c4' }}>{this.state.portalData.slogan.text}</p></div>
                    </div>
                    <div className="d-flex justify-content-center"><Button className="sched-show-button variable-buttons" style={{ width: '90%', color: 'white' }}>Schedule Private Showing</Button></div>
                  </div>
                </div>
              </div>
            </Container>
            <Container style={{ maxWidth: '600px' }}>
              <h1 className="secondary-font-style mt-2" style={{ textAlign: 'center' }}>More Open House Below!</h1>
            </Container>
            <ProdOpenHouses {...this.state}></ProdOpenHouses>
            <ImgModal></ImgModal>
            {/* <ImageModal {...this.state} closeImageModal={this.closeImageModal}></ImageModal> */}
            {/* <ProductionListings {...this.state}></ProductionListings> */}
          </React.Fragment>
          }
          {showAdmin &&
          <React.Fragment>
            <Container className='profile-container profile-border-color profile-sect' style={{ maxWidth: '600px', height: '100vh' }}>
              <div className="profile-img-sect profile-img-sect-color" style={{ backgroundImage: `url(${this.state.adminListingData.Media ? this.state.adminListingData.Media[0].MediaURL : ''})` }}>
                <div className="row">
                  <div className="col-4" style={{ paddingRight: '0' }}>
                    <Button onClick={this.toPortal} style={{ marginTop: '.4rem', marginLeft: '.4rem', marginRight: '0' }} variant="primary" size="sm">Back to Portal</Button>
                  </div>
                  <div className="col d-flex align-items-center" style={{ marginTop: '.4rem' }}>
                    <div style={{ backgroundColor: '#23a455', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-one" onClick={this.changeTheme}></div> {/* Leads Genie */}
                    <div style={{ backgroundColor: '#B40101', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-two" onClick={this.changeTheme}></div> {/* Keller Williams */}
                    <div style={{ backgroundColor: '#BEAF87', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-three" onClick={this.changeTheme}></div> {/* Century 21 */}
                    <div style={{ backgroundColor: '#012169', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-four" onClick={this.changeTheme}></div> {/* Coldwell Banker */}
                    <div style={{ backgroundColor: '#dc1c2e', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-five" onClick={this.changeTheme}></div> {/* Remax */}
                    <div style={{ backgroundColor: '#C82021', marginRight: '.2rem' }} className="theme-picker-circles" data-id="theme-six" onClick={this.changeTheme}></div> {/* Redfin */}
                  </div>
                </div>
                <IconContext.Provider value={{ className: 'expand-icon' }}>
                  <div onClick={() => this.setState({ imageModal: true })} style={{ paddingRight: '1.2rem' }} className="d-flex justify-content-end">
                    <FaExpand></FaExpand>
                  </div>
                </IconContext.Provider>
                <div className="d-flex flex-column justify-content-between" style={{ height: '69%', paddingBottom: '1rem', marginTop: '-20px' }} >
                  <div style={{ backgroundColor: 'rgba(46,46,61,0.75)', width: '50%', borderRadius: '0 20px 20px 0' }}><h1 className="secondary-font-style small-text-profile" style={{ color: 'white', textAlign: 'center', marginTop: '.5rem' }}>Featured Open House</h1></div>
                </div>
              </div>
              <div style={{ height: '45%' }}>
                <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: 'white', height: '10%' }}><h2 className="secondary-font-style med-text-profile" style={{ color: 'black', textAlign: 'center', margin: '0' }}>{this.state.adminOpenHouseDates[0]}</h2></div>
                <Carousel interval={null} style={{ height: '90%' }} className={'profile-desc-sect profile-desc-sect-color' + ' ' + this.state.theme}>
                  <Carousel.Item style={{ height: '90%' }}>
                    <div className="d-flex flex-column justify-content-around" style={{ height: '100%', width: '100%', paddingLeft: '1rem', paddingRight: '1rem', color: 'white' }}>
                      <div style={{ paddingRight: '2rem', paddingLeft: '2rem' }} className="d-flex flex-row justify-content-between">
                        <div className="main-font-style"><p className="m-0 small-text-profile">{this.state.portalData.mlsinfo.mlsid === '123456789' ? '123' : this.state.listingData.StreetNumberNumeric} {this.state.portalData.mlsinfo.mlsid === '123456789' ? 'Main' : this.state.listingData.StreetName} {this.state.portalData.mlsinfo.mlsid === '123456789' ? 'Street' : this.state.listingData.StreetSuffix}</p><p style={{ color: '#c4c4c4' }} className="m-0 small-text-profile">Tampa, FL {this.state.portalData.mlsinfo.mlsid === '123456789' ? '33333' : this.state.listingData.PostalCode}</p></div>
                        <div className="d-flex align-items-center"><IconContext.Provider value={{ className: 'directions-icon variable-checks' }}><FaDirections></FaDirections></IconContext.Provider></div>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <div className="col-4 d-flex justify-content-center"><div className="desc-card-bg-color desc-card-bg"><div className="d-flex justify-content-center desc-icon-sect"><IconContext.Provider value={{ className: 'directions-icon variable-checks' }}><IoBedSharp></IoBedSharp></IconContext.Provider></div><div className="main-font-style desc-text-sect"><p className="small-text-profile mb-0" style={{ color: '#c4c4c4', textAlign: 'center' }}>{this.state.portalData.mlsinfo.mlsid === '123456789' ? '2' : this.state.listingData.BedroomsTotal} Bedrooms</p></div></div></div>
                        <div className="col-4 d-flex justify-content-center"><div className="desc-card-bg-color desc-card-bg"><div className="d-flex justify-content-center desc-icon-sect"><IconContext.Provider value={{ className: 'directions-icon variable-checks' }}><FaBath></FaBath></IconContext.Provider></div><div className="main-font-style desc-text-sect"><p className="small-text-profile mb-0" style={{ color: '#c4c4c4', textAlign: 'center' }}>{this.state.portalData.mlsinfo.mlsid === '123456789' ? '3' : this.state.listingData.BathroomsFull} Bathrooms</p></div></div></div>
                        <div className="col-4 d-flex justify-content-center"><div className="desc-card-bg-color desc-card-bg"><div className="d-flex justify-content-center desc-icon-sect"><IconContext.Provider value={{ className: 'directions-icon variable-checks' }}><FaExpandArrowsAlt></FaExpandArrowsAlt></IconContext.Provider></div><div className="main-font-style desc-text-sect"><p className="small-text-profile mb-0" style={{ color: '#c4c4c4', textAlign: 'center' }}>{this.state.portalData.mlsinfo.mlsid === '123456789' ? '1200' : this.state.listingData.LivingArea} ft2</p></div></div></div>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <div className="col-4 main-font-style small-text-profile d-flex justify-content-center"><div><p style={{ color: '#c4c4c4' }} className='m-0 p-0'>Listing Price:</p><p className='m-0 p-0'>${this.state.portalData.mlsinfo.mlsid === '123456789' ? '123,000' : this.state.listingData.ListPrice}</p></div></div>
                        <div className="col-4 main-font-style small-text-profile d-flex justify-content-center"><div><p style={{ color: '#c4c4c4' }} className='m-0 p-0'>DOM:</p><p className='m-0 p-0'>{this.state.portalData.mlsinfo.mlsid === '123456789' ? '123' : this.state.listingData.DaysOnMarket} Days</p></div></div>
                        <div className="col-4 main-font-style small-text-profile d-flex justify-content-center"><div><p style={{ color: '#c4c4c4' }} className='m-0 p-0'>Price/Sqft:</p><p className='m-0 p-0'>${this.calcPricePerSqft()}</p></div></div>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item style={{ color: 'white', height: '90%' }}>
                    <div style={{ height: '100%', paddingLeft: '2rem', paddingRight: '2rem' }} className="d-flex flex-column justify-content-around">
                      <div className="main-font-style white small-text"><p style={{ textAlign: 'center' }}>Download a FREE copy of the report here!</p><div className="d-flex justify-content-center" style={{ width: '100%' }}><Button className='variable-buttons'>Download</Button></div></div>
                      <div className="main-font-style white small-text"><p style={{ textAlign: 'center' }}>See More Open Houses Below!</p><div className="d-flex justify-content-center"><IconContext.Provider value={{ className: 'directions-icon' }}><FaArrowDown data-anchor="listings" onClick={this.scrollDown}></FaArrowDown></IconContext.Provider></div></div>
                      <div className="main-font-style white"><p style={{ textAlign: 'center', fontSize: '.7rem' }}>{this.state.portalData.mlsinfo.brokerOfficeName} | {this.state.portalData.mlsinfo.brokerid} | {this.state.portalData.mlsinfo.brokerOfficePhone}</p></div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className={this.state.theme} style={{ height: '20%' }}>
                <div className="profile-agent-sect profile-agent-sect-color" style={{ height: '100%' }}>
                  <div className="d-flex flex-column justify-content-around" style={{ paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '.5rem', height: '100%' }}>
                    <div className="d-flex flex-row">
                      <div style={{ marginRight: '.8rem' }} className="agent-headshot-circle d-flex align-items-center">
                        <img className="profile-pic-img-profile" alt="uploaded file" src={this.state.portalData.profilepicture.url}/>
                      </div>
                      <div className="small-text-profile main-font-style"><p className="m-0 white">{this.state.portalData.name.first} {this.state.portalData.name.last}</p><p className="m-0 small-text-profile main-font-style" style={{ color: '#c4c4c4' }}>{this.state.portalData.slogan.text}</p></div>
                    </div>
                    <div className="d-flex justify-content-center"><Button className="sched-show-button variable-buttons" style={{ width: '90%', color: 'white' }}>Schedule Private Showing</Button></div>
                  </div>
                </div>
              </div>
            </Container>
            <Container style={{ maxWidth: '600px' }}>
              <h1 className="secondary-font-style mt-2" style={{ textAlign: 'center' }}>More Open House Below!</h1>
            </Container>
            <ImgModal></ImgModal>
            <TestOpenHouses></TestOpenHouses>
            {/* <ImageModal {...this.state} closeImageModal={this.closeImageModal}></ImageModal> */}
            {/* <TestListings {...this.state}></TestListings> */}
          </React.Fragment>
          }
        </React.Fragment>
        }
        {this.state.noOpenHouses &&
          <Container className={'profile-container profile-border-color profile-sect main-font-style' + ' ' + this.state.theme} style={{ maxWidth: '600px', height: '100vh', color: 'white' }}>
            <div style={{ width: '100%', height: '100%' }} className="d-flex flex-column justify-content-center">
              <p style={{ textAlign: 'center' }}>Currently there are no open houses in the area to display. Please check back later</p>
            </div>
          </Container>
        }
      </React.Fragment>
    )
  }
}

export default withRouter(ViewProfile)
