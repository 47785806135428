import React from 'react'
// import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'

class ThemePicker extends React.Component {
  state = {
  }

  render () {
    return (
      <React.Fragment>
        <div style={{ width: '100%' }}>
          <p className="small-text white mb-0 theme-picker variable-main-font-color">Theme Picker:</p>
          <div>
            <div style={{ backgroundColor: '#23a455' }} className="theme-picker-circles" data-id="theme-one" onClick={this.props.changeTheme}></div> {/* Leads Genie */}
            <div style={{ backgroundColor: '#B40101' }} className="theme-picker-circles" data-id="theme-two" onClick={this.props.changeTheme}></div> {/* Keller Williams */}
            <div style={{ backgroundColor: '#BEAF87' }} className="theme-picker-circles" data-id="theme-three" onClick={this.props.changeTheme}></div> {/* Century 21 */}
            <div style={{ backgroundColor: '#012169' }} className="theme-picker-circles" data-id="theme-four" onClick={this.props.changeTheme}></div> {/* Coldwell Banker */}
            <div style={{ backgroundColor: '#dc1c2e' }} className="theme-picker-circles" data-id="theme-five" onClick={this.props.changeTheme}></div> {/* Remax */}
            <div style={{ backgroundColor: '#C82021' }} className="theme-picker-circles" data-id="theme-six" onClick={this.props.changeTheme}></div> {/* Redfin */}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ThemePicker
