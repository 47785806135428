import React from 'react'
import apiUrl from '../../../apiConfig'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroller'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
// import House from '../../../images/house.jpg'
// import SampleListings from '../../../data/samplelistings.json'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'

class TestOpenHouses extends React.Component {
  state = {
    componentLoading: true,
    testListings: '',
    testNextLink: '',
    testFullDataCount: '',
    testCurrentDataCount: '',
    testHasMoreData: '',
    testSkip: '',
    imageModal: false,
    media: ''
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/testlistings',
      method: 'POST',
      headers: {
      }
    })
      .then(res => {
        this.setState({ testListings: res.data.listings.value, testFullDataCount: res.data.listings['@odata.count'] })
        if (res.data.listings['@odata.nextLink']) {
          this.setState({ testNextLink: res.data.listings['@odata.nextLink'], testHasMoreData: true, testSkip: 10 })
        }
      })
      .then(() => this.setState({ componentLoading: false }))
  }

  fetchTestItems = () => {
    if (this.state.testHasMoreData) {
      axios({
        url: apiUrl + '/testlistings',
        method: 'POST',
        headers: {
        },
        data: {
          skip: this.state.testSkip
        }
      })
        .then(res => {
          const oldValues = this.state.testListings
          const newValues = res.data.listings.value
          const newState = oldValues.concat(newValues)
          this.setState({ testListings: newState, testCurrentDataCount: res.data.listings['@odata.count'] })
          if (res.data.listings['@odata.nextLink']) {
            const skip = parseFloat(this.state.testSkip) + 10
            this.setState({ testNextLink: res.data.listings['@odata.nextLink'], testHasMoreData: true, testSkip: skip })
          }
          if (this.state.testSkip > 100) {
            this.setState({ testNextLink: '', testHasMoreData: false })
          }
        })
    }
  }

  imageModal = (listing) => {
    const mediaListing = this.state.testListings.find((index) => {
      return index['@odata.id'] === listing['@odata.id']
    })
    this.setState({ media: mediaListing.Media, imageModal: true })
  }

  render () {
    return (
      <Container fluid style={{ maxWidth: '600px' }} id="listings">
        {!this.state.componentLoading &&
        <InfiniteScroll
          loadMore={this.fetchTestItems}
          hasMore={this.state.testHasMoreData}
          loader={
            <div className="d-flex justify-content-center mt-5">
              {this.state.testHasMoreData &&
            <div>
              <Spinner size="lg" animation="border"></Spinner>&nbsp;Loading...
            </div>
              }
              {!this.state.testHasMoreData &&
            <p style={{ textAlign: 'center' }}>No more data</p>
              }
            </div>
          }
        >
          <div>
            {this.state.testListings.map((listing) => (
              <div key={listing['@odata.id']}>
                <div className='ratio ratio-16x9 mt-3'>
                  <div onClick={() => this.imageModal(listing)} style={{ backgroundImage: `url(${listing.Media[0].MediaURL})` }} className="profile-img-sect">
                    <div style={{ marginTop: '30%' }}>
                      <div style={{ backgroundColor: 'rgba(46,46,61,0.75)', width: '100%', textAlign: 'center', marginBottom: '.2rem' }} className="secondary-font-style small-text-profile white">May 06th 11:30AM-2:00PM</div>
                      <div style={{ backgroundColor: 'rgba(46,46,61,0.75)', width: '100%', textAlign: 'center', marginBottom: '.2rem' }} className="secondary-font-style small-text-profile white">May 06th 11:30AM-2:00PM</div>
                    </div>
                  </div>
                </div>
                <div style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, .25)', paddingLeft: '.5rem', paddingTop: '.2rem', paddingBottom: '.2rem', backgroundColor: 'white' }} className='main-font-style small-text mb-3'>
                  <div style={{ fontWeight: 'bold' }} className="secondary-font-style">${this.state.componentLoading ? '' : listing.ListPrice.toLocaleString()}</div>
                  <div>{listing.BedroomsTotal} Beds | {listing.BathroomsFull} Baths | {listing.LivingArea} SQFT</div>
                  <div>123 Main Street, Tampa, FL 33333</div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
        }
        {this.state.media &&
        <Modal size="lg" show={this.state.imageModal} backdrop={true}>
          <Modal.Body>
            <Carousel variant="dark" indicators={false}>
              {this.state.media.map((image) => (
                <Carousel.Item key={image.MediaURL}>
                  <Carousel.Caption style={{ width: '100%', left: '0', right: '0', bottom: '0', top: '0', paddingTop: '0', paddingBottom: '0' }}>
                    <div className="d-flex justify-content-end" style={{ width: '100%' }}>
                      <Button onClick={() => this.setState({ imageModal: false })} className="carousel-button" variant="outline-primary" style={{ border: 'none', color: 'black' }}>X</Button>
                    </div>
                  </Carousel.Caption>
                  <img
                    className="d-block w-100"
                    src={image.MediaURL}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
        }
      </Container>
    )
  }
}

export default TestOpenHouses
