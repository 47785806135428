import React from 'react'
import apiUrl from '../../apiConfig'
import axios from 'axios'
import { AiOutlineDownload } from 'react-icons/ai'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ProfilePicture from './ProfilePicture'
import ThemePicker from './ThemePicker'
import Locate from './Locate'
import ZipCodes from './ZipCodes'
import OpenHouse from './OpenHouse'
import MobileNumber from './MobileNumber'
import Slogan from './Slogan'
import BrokerInfo from './BrokerInfo'
// import LeadMagnet from './LeadMagnet'
import AccountInfoModal from './AccountInfoModal'
import FailedPaymentModal from './FailedPaymentModal'
import FailedBulkPaymentModal from './FailedBulkPaymentModal'
import BulkLicensesModal from './BulkLicensesModal'
import QRCodeModal from './QRCodeModal'
import LeadModal from './LeadModal'

class WithLicensePortal extends React.Component {
  state = {
    accountInfoModal: false,
    failedPaymentModal: false,
    failedBulkPaymentModal: false,
    bulkLicensesModal: false,
    stateData: '',
    qrCodeModal: false,
    leads: '',
    leadModal: false,
    clicks: ''
  }

  bulkLicensesModalOpen = () => {
    this.setState({ bulkLicensesModal: true, accountInfoModal: false })
  }

  bulkLicensesModalClose = () => {
    this.setState({ bulkLicensesModal: false })
  }

  accountInfoModalClose = () => {
    this.setState({ accountInfoModal: false })
  }

  failedPaymentModalClose = () => {
    this.setState({ failedPaymentModal: false })
  }

  failedBulkPaymentModalClose = () => {
    this.setState({ failedBulkPaymentModal: false })
  }

  qrCodeModalOpen = () => {
    this.setState({ qrCodeModal: true })
  }

  qrCodeModalClose = () => {
    this.setState({ qrCodeModal: false })
  }

  componentDidMount () {
    if (this.props.stripeSubStatus === 'past_due') {
      this.setState({ failedPaymentModal: true })
    }
    if (this.props.bulkSubStatus === 'past_due') {
      this.setState({ failedBulkPaymentModal: true })
    }
    axios({
      url: apiUrl + '/findstate',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        name: this.props.mlsState
      }
    })
      .then(res => {
        const allStateArray = res.data.states
        const myStateArray = allStateArray.filter(state => state.name === this.props.mlsState)
        this.setState({ stateData: myStateArray[0].data })
      })
  }

  getLeads = () => {
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => this.setState({ leads: res.data.portal.leads, clicks: res.data.portal.clicks }))
      // .then(() => this.getClicks())
      .then(() => this.setState({ leadModal: true }))
  }

  // getClicks = () => {
  //   axios({
  //     url: apiUrl + '/portals/' + this.props.portalId,
  //     method: 'GET',
  //     headers: {
  //       Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
  //     }
  //   })
  //     .then(res => this.setState({ clicks: res.data.portal.clicks }))
  //     .then(() => this.setState({ leadModal: true }))
  // }

  deleteLead = (lead) => {
    axios({
      url: apiUrl + '/portals/' + this.props.portalId + '/' + lead,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(() => this.setState({ leads: [...this.state.leads.filter((item) => item._id !== lead)] }))
  }

  leadModalClose = () => {
    this.setState({ leadModal: false })
  }

  render () {
    return (
      <Container fluid className='d-flex flex-column justify-content-around' style={{ height: '100vh', paddingLeft: '0', paddingRight: '0', marginTop: '.7rem' }}>
        <Row className="g-0" style={{ width: '100%', height: '25%' }}>
          <Col className="col-4">
            <Button size="sm" className="white main-font-style small-text account-info-button variable-buttons" style={{ paddingRight: '0.4rem', paddingLeft: '0.4rem' }} onClick={() => this.setState({ accountInfoModal: true })}>Acct. Info</Button>
            <AccountInfoModal assignor={this.props.assignor} bulkLicensesModalOpen={this.bulkLicensesModalOpen} {...this.state} user={this.props.user} msgAlert={this.props.msgAlert} accountInfoModalClose={this.accountInfoModalClose} getSession={this.props.getSession} stripeSubStatus={this.props.stripeSubStatus}></AccountInfoModal>
          </Col>
          <Col className="col-4 d-flex justify-content-center align-items-end">
            <ProfilePicture portalId={this.props.portalId} user={this.props.user}></ProfilePicture>
          </Col>
          <Col className="col-4 d-flex justify-content-center align-items-end">
            <ThemePicker changeTheme={this.props.changeTheme} portalId={this.props.portalId} user={this.props.user}></ThemePicker>
          </Col>
        </Row>
        <Row className="mt-5">
          {this.props.showQRCode &&
          <Col onClick={this.qrCodeModalOpen}>
            <p className='main-font-style white blink' style={{ marginBottom: '0', paddingBottom: '0', textAlign: 'center' }}>View and Download Your QR Code&nbsp;<AiOutlineDownload></AiOutlineDownload></p>
          </Col>
          }
        </Row>
        <Row>
          <div className="d-flex justify-content-center mt-2">
            <Button size="sm" className="white main-font-style small-text variable-buttons" onClick={this.getLeads}>View Leads</Button>
          </div>
        </Row>
        <Row>
          <Col className="col-12">
            {this.props.mlsid &&
            <Locate viewProfile={this.props.viewProfile} stateData={this.state.stateData} mlsState={this.props.mlsState} mlsid={this.props.mlsid} portalId={this.props.portalId} user={this.props.user}></Locate>
            }
          </Col>
        </Row>
        <div>
          {this.props.mlsid &&
          <Slogan viewProfile={this.props.viewProfile} stateData={this.state.stateData} mlsid={this.props.mlsid} portalId={this.props.portalId} user={this.props.user}></Slogan>
          }
          {this.props.mlsid &&
          <ZipCodes viewProfile={this.props.viewProfile} stateData={this.state.stateData} mlsid={this.props.mlsid} portalId={this.props.portalId} user={this.props.user}></ZipCodes>
          }
          {this.props.mlsid &&
          <OpenHouse viewProfile={this.props.viewProfile} mlsid={this.props.mlsid} portalId={this.props.portalId} user={this.props.user}></OpenHouse>
          }
          {this.props.mlsid &&
          <MobileNumber viewProfile={this.props.viewProfile} portalId={this.props.portalId} user={this.props.user}></MobileNumber>
          }
          <BrokerInfo viewProfile={this.props.viewProfile} getMlsId={this.props.getMlsId} portalId={this.props.portalId} user={this.props.user}></BrokerInfo>
          {this.props.mlsid &&
          <div></div>
          /* <LeadMagnet portalId={this.props.portalId} user={this.props.user}></LeadMagnet> */
          }
        </div>
        <FailedBulkPaymentModal {...this.state} user={this.props.user} failedBulkPaymentModalClose={this.failedBulkPaymentModalClose} stripeSubId={this.props.stripeSubId} stripeDefPaymentId={this.props.stripeDefPaymentId} stripeSubStatus={this.props.stripeSubStatus} stripeCustomerId={this.props.stripeCustomerId} portalId={this.props.portalId}></FailedBulkPaymentModal>
        <FailedPaymentModal {...this.state} user={this.props.user} failedPaymentModalClose={this.failedPaymentModalClose} stripeSubId={this.props.stripeSubId} stripeDefPaymentId={this.props.stripeDefPaymentId} stripeSubStatus={this.props.stripeSubStatus} stripeCustomerId={this.props.stripeCustomerId} portalId={this.props.portalId}></FailedPaymentModal>
        <BulkLicensesModal {...this.state} currentPrice={this.props.currentPrice} bulkLicensesModalClose={this.bulkLicensesModalClose} user={this.props.user} failedPaymentModalClose={this.failedPaymentModalClose} stripeSubId={this.props.stripeSubId} stripeDefPaymentId={this.props.stripeDefPaymentId} stripeSubStatus={this.props.stripeSubStatus} stripeCustomerId={this.props.stripeCustomerId} portalId={this.props.portalId} msgAlert={this.props.msgAlert}></BulkLicensesModal>
        <QRCodeModal theme={this.props.theme} state={this.state.qrCodeModal} close={this.qrCodeModalClose} open={this.qrCodeModalOpen} portalId={this.props.portalId}></QRCodeModal>
        <LeadModal {...this.props} {...this.state} deleteLead={this.deleteLead} leadModalClose={this.leadModalClose}></LeadModal>
      </Container>
    )
  }
}

export default WithLicensePortal
