import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { withRouter } from 'react-router-dom'

class Step1 extends React.Component {
  state = {
  }

  render () {
    return (
      <React.Fragment>
        <Form.Group className={this.props.firstNameError ? '' : 'auth-form-form-group'}>
          <Form.Control
            required
            id="first-name"
            type="input"
            name="firstName"
            value={this.props.firstName}
            placeholder="First Name"
            onChange={this.props.handleChange}
          />
          <div>
            {this.props.firstNameError &&
            <p className="auth-error-messages">&nbsp;{this.props.firstNameError}</p>
            }
          </div>
        </Form.Group>
        <Form.Group className={this.props.lastNameError ? '' : 'auth-form-form-group'}>
          <Form.Control
            required
            id="last-name"
            type="input"
            name="lastName"
            value={this.props.lastName}
            placeholder="Last Name"
            onChange={this.props.handleChange}
          />
          <div>
            {this.props.lastNameError &&
            <p className="auth-error-messages">&nbsp;{this.props.lastNameError}</p>
            }
          </div>
        </Form.Group>
        <Form.Group className={this.props.emailError ? '' : 'auth-form-form-group'}>
          <Form.Control
            required
            id="email-address"
            type="email"
            name="email"
            value={this.props.email}
            placeholder="Email Address"
            onChange={this.props.handleChange}
          />
          <div>
            {this.props.emailError &&
            <p className="auth-error-messages">&nbsp;{this.props.emailError}</p>
            }
          </div>
        </Form.Group>
        <Form.Group className="auth-form-form-group">
          <Button onClick={this.props.nextButtons} style={{ width: '100%' }} disabled={!this.props.validation1} className="btn-ternary white">Next</Button>
        </Form.Group>
        <Form.Group className="auth-form-form-group">
          <p style={{ textAlign: 'center', color: '#808080' }} className=" main-font-style auth-form-bottom-text">If you already have an account, <span className="" style={{ fontWeight: 'bold', color: 'white' }}><a href='' onClick={() => this.props.history.push('/sign-in')} className="auth-form-bottom-text" style={{ fontWeight: 'bold', color: 'white', textDecoration: 'none', display: 'inline-block' }}>&nbsp;Sign In</a></span></p>
        </Form.Group>
      </React.Fragment>
    )
  }
}

export default withRouter(Step1)
