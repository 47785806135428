import React from 'react'
import Alert from 'react-bootstrap/Alert'

import './AutoDismissAlert.scss'

class AutoDismissAlert extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show: true
    }
    this.timeoutId = null
  }

  componentDidMount () {
    this.timeoutId = setTimeout(this.handleClose, 5000)
  }

  componentWillUnmount () {
    clearTimeout(this.timeoutId)
  }

handleClose = () => this.setState({ show: false })

render () {
  const { variant, heading, message, deleteAlert, id } = this.props

  // Delete this alert after the fade animation time (300 ms by default)
  if (!this.state.show) {
    setTimeout(() => {
      deleteAlert(id)
    }, 300)
  }

  return (
    <Alert
      show={this.state.show}
      variant={variant}
      onClose={this.handleClose}>
      <div className='container'>
        <Alert.Heading style={{ textAlign: 'center' }}>{heading}</Alert.Heading>
        <p className='alert-body' style={{ textAlign: 'center' }}>{message}</p>
      </div>
    </Alert>
  )
}
}

export default AutoDismissAlert
