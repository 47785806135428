import React from 'react'
import apiUrl from '../../apiConfig'
import axios from 'axios'
import { withRouter, Redirect, Switch } from 'react-router-dom'
import NoLicense from './NoLicense'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import WithLicense from './WithLicense'

class AgentPortal extends React.Component {
  state = {
    getReqLoading: true,
    portalAccess: '',
    portalView: 'intro',
    portalId: this.props.match.params.id,
    stripeCustomerId: '',
    stripeSubId: '',
    stripeSubStatus: '',
    stripeDefPaymentId: '',
    purchaseResult: '',
    assignor: '',
    bulkSubStatus: '',
    mlsid: '',
    mls: '',
    mlsState: '',
    openHouse: '',
    locate: '',
    zipCodes: '',
    mobileNumber: '',
    slogan: '',
    mlsIdComponent: '',
    profile: false,
    theme: '',
    notFound: false,
    portalForWindowId: '',
    windowId: '',
    checkWindowId: false
  }

  componentDidMount () {
    const split = window.location.href.split('portal/')
    const portalID = split[1]
    // console.log(portalID)
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        this.setState({ windowId: portalID, portalForWindowId: res.data.portals[0]._id, stripeDefPaymentId: res.data.portals[0].stripe.stripeDefPaymentId, stripeCustomerId: res.data.portals[0].stripe.stripeCusId, stripeSubId: res.data.portals[0].stripe.stripeSubId, stripeSubStatus: res.data.portals[0].stripe.stripeSubStatus, portalAccess: res.data.portals[0].portalAccess, mlsid: res.data.portals[0].mlsinfo.mlsid, mls: res.data.portals[0].mlsinfo.mls, mlsState: res.data.portals[0].mlsinfo.mlsState, theme: res.data.portals[0].theme })
        if (res.data.portals[0].bulkSubscriptions) {
          this.setState({ bulkSubStatus: res.data.portals[0].bulkSubscriptions.subscriptionStatus })
        }
      })
      .then(setTimeout(() => { this.setState({ getReqLoading: false, checkWindowId: true }) }, 500))
      .then(this.getLicenseList)
    if (this.props.location.props) {
      this.setState({ purchaseResult: this.props.location.props.result },
        () => {
          this.setCancelMessage()
          // this.setValidLicense()
        })
    }
    setTimeout(() => {
      this.setState({ portalView: 'portal' })
    }, 6000)
  }

  changeTheme = (event) => {
    const newTheme = event.target.dataset.id
    this.setState({ theme: newTheme })
    axios({
      url: apiUrl + '/portals/' + this.state.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          theme: newTheme
        }
      }
    })
  }

  getLicenseList = () => {
    axios({
      url: apiUrl + '/bulksubscriptions',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then(res => {
        if (res.data.subscriptions.some(pos => pos.index.id === this.state.portalId)) {
          this.setState({ portalAccess: true })
          const index = res.data.subscriptions.filter(pos => pos.index.id === this.state.portalId)
          this.setState({ assignor: index[0].assignorName })
        }
      })
  }

  toSignOut = () => {
    return this.props.history.push('/sign-out')
  }

  setCancelMessage = () => {
    if (this.state.purchaseResult === 'cancel') {
      this.props.msgAlert({
        heading: 'Transaction Canceled'
      })
    }
  }

  getMlsId = () => {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        this.setState({ mlsid: res.data.portals[0].mlsinfo.mlsid })
      })
  }

  // setValidLicense = () => {
  //   if (this.state.purchaseResult === 'success') {
  //     this.setState({ validLicense: true })
  //   }
  // }

  viewProfile = (component, view) => {
    this.setState({ [component]: view })
  }

  toProfile = () => {
    if (this.state.locate && this.state.zipCodes && this.state.mobileNumber && this.state.mlsIdComponent && this.state.openHouse) {
      this.setState({ profile: true })
    }
  }

  render () {
    if (this.state.checkWindowId) {
      if (this.state.portalForWindowId !== this.state.windowId) {
        return <Switch><Redirect to = {{ pathname: '/404notfound' }}></Redirect></Switch>
      }
    }
    if (this.state.profile) {
      return <Switch><Redirect to ={{ pathname: '/profile/' + this.state.portalId, props: { portalAccess: this.state.portalAccess, mlsid: this.state.mlsid, mls: this.state.mls, mlsState: this.state.mlsState } }} /></Switch>
    }
    return (
      <Container fluid className={'portal-container' + ' ' + this.state.theme} style={{ maxWidth: '600px', paddingBottom: '3rem' }}>
        <div style={{ width: '100%', paddingTop: '.7rem' }} className='d-flex justify-content-between'>
          <div>
            {this.state.portalView !== 'intro' && this.state.portalAccess &&
            <Button onClick={this.toProfile} disabled={!this.state.locate || !this.state.zipCodes || !this.state.mobileNumber || !this.state.mlsIdComponent || !this.state.openHouse || !this.state.slogan} className="portal-top-btn top-buttons variable-main-font-color">View Profile</Button>
            }
          </div>
          <div>
            <Button onClick={this.toSignOut} className="portal-top-btn top-buttons variable-main-font-color">Sign Out</Button>
          </div>
        </div>
        {!this.state.getReqLoading &&
        <div style={{ height: '90%' }}>
          {this.state.portalAccess &&
          <WithLicense showQRCode={this.state.locate && this.state.zipCodes && this.state.mobileNumber && this.state.mlsIdComponent && this.state.openHouse && this.state.slogan ? true : ''} theme={this.state.theme} changeTheme={this.changeTheme} viewProfile={this.viewProfile} getMlsId={this.getMlsId} mlsState={this.state.mlsState} mlsid={this.state.mlsid} bulkSubStatus={this.state.bulkSubStatus} assignor={this.state.assignor} locationProps={this.props.location.props} currentPrice={this.props.currentPrice} stripeSubId={this.state.stripeSubId} stripeDefPaymentId={this.state.stripeDefPaymentId} stripeSubStatus={this.state.stripeSubStatus} stripeCustomerId={this.state.stripeCustomerId} portalId={this.state.portalId} user={this.props.user} getSession={this.props.getSession} msgAlert={this.props.msgAlert} setUser={this.props.setUser}></WithLicense>
          }
          {!this.state.portalAccess &&
          <NoLicense bulkSubStatus={this.state.bulkSubStatus} msgAlert={this.props.msgAlert} currentPrice={this.props.currentPrice} setupPrice={this.props.setupPrice} stripeSubStatus={this.state.stripeSubStatus} stripeCustomerId={this.state.stripeCustomerId} portalId={this.state.portalId} user={this.props.user}></NoLicense>
          }
        </div>
        }
      </Container>
    )
  }
}

export default withRouter(AgentPortal)
