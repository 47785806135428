import React from 'react'
import apiUrl from '../../apiConfig'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
// import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { IoChevronForward } from 'react-icons/io5'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { IconContext } from 'react-icons'
// import zipCodes from './../../data/fl_zipcodes.json'

class Locate extends React.Component {
  state = {
    view: '',
    createLocation: '',
    editLocation: '',
    zip: '',
    zipValidated: '',
    city: '',
    state: '',
    validated: '',
    component: 'locate',
    loaded: false
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.mlsid !== prevProps.mlsid) {
      this.getRequest()
    }
    if (this.state.view !== prevState.view) {
      this.props.viewProfile('locate', this.state.view)
    }
  }

  getRequest = () => {
    // console.log('getrequest')
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.portals[0].location) {
          this.setState({ city: res.data.portals[0].location.city, state: res.data.portals[0].location.state, view: '', createLocation: '', editLocation: '', zip: '', zipValidated: '' })
        }
      })
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.portals[0].location) {
          if (res.data.portals[0].location.city !== '' && res.data.portals[0].location.state !== '') {
            this.setState({ city: res.data.portals[0].location.city, state: res.data.portals[0].location.state, zip: res.data.portals[0].location.zip, view: 'edit', validated: true, zipValidated: true })
          }
        }
      })
      .then(() => this.setState({ loaded: true }))
  }

  handleChange = (event) => {
    const value = event.target.value
    this.setState({ [event.target.name]: value },
      () => {
        this.handleErrors()
      })
  }

  handleErrors = () => {
    const zip = parseInt(this.state.zip)
    const zipString = this.state.zip
    if (zipString.length === 5) {
      const zipValid = this.props.stateData.some((location) => { return location.zip_code === zip })
      if (zipValid) {
        this.setState({ zipValidated: true },
          () => { this.validated() }
        )
      } else {
        this.setState({ zipValidated: `Not a Valid ${this.props.mlsState} Zip Code` },
          () => { this.validated() }
        )
      }
    } else {
      this.setState({ zipValidated: '' },
        () => { this.validated() }
      )
    }
  }

  validated = () => {
    if (this.state.zipValidated === true) {
      this.setState({ validated: true })
    } else {
      this.setState({ validated: false })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const zip = parseInt(this.state.zip)
    const arr = this.props.stateData.filter((location) => { return zip === location.zip_code })
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          location: {
            city: arr[0].city,
            state: arr[0].state,
            zip: arr[0].zip_code,
            county: arr[0].county
          }
        }
      }
    })
      .then(() => this.setState({ city: arr[0].city, state: arr[0].state, view: 'edit' }))
  }

  handleEditSubmit = (event) => {
    event.preventDefault()
    this.setState({ view: 'edit' })
  }

  render () {
    let jsx
    if (!this.state.view) {
      jsx = (
        <React.Fragment>
          {!this.state.createLocation && !this.state.editLocation && this.state.loaded &&
          <div onClick={() => this.setState({ createLocation: true })}>
            <p style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'underline' }} className="main-font-style med-text red">Add Location</p>
          </div>
          }
          {this.state.createLocation &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <Form.Control
                  required
                  type="text"
                  maxLength={5}
                  style={{ borderRadius: '.25rem 0 0 .25rem', borderRightWidth: '0' }}
                  placeholder="Five Digit Zip Code"
                  onChange={this.handleChange}
                  value={this.state.zip}
                  name="zip"
                />
                <InputGroup.Text style={{ backgroundColor: 'white', borderTopRightRadius: '.2rem', borderBottomRightRadius: '.2rem' }}>{this.state.zipValidated === true ? <span className="variable-checks">&#10004;</span> : ''}</InputGroup.Text>
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
              {this.state.zipValidated !== true &&
              <Form.Group>
                <p className="auth-error-messages">{this.state.zipValidated}</p>
              </Form.Group>
              }
            </Form>
          </div>
          }
          {this.state.editLocation &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <Form.Control
                  required
                  type="text"
                  maxLength={5}
                  style={{ borderRadius: '.25rem 0 0 .25rem', borderRightWidth: '0' }}
                  placeholder="Five Digit Zip Code"
                  onChange={this.handleChange}
                  value={this.state.zip}
                  name="zip"
                />
                <InputGroup.Text style={{ backgroundColor: 'white', borderTopRightRadius: '.2rem', borderBottomRightRadius: '.2rem' }}>{this.state.zipValidated === true ? <span className="variable-checks">&#10004;</span> : ''}</InputGroup.Text>
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
              {this.state.zipValidated !== true &&
              <Form.Group>
                <p className="auth-error-messages">{this.state.zipValidated}</p>
              </Form.Group>
              }
            </Form>
          </div>
          }
        </React.Fragment>
      )
    } else {
      jsx = (
        <div className="variable-main-font-color" style={{ width: '100%' }}>
          <p onClick={() => this.setState({ view: '', createLocation: '', editLocation: 'true' })} style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'none' }} className="main-font-style med-text"><span className="xs-text" style={{ fontWeight: 'bold' }}>Location:</span><br></br><span className="variable-checks">&#10004;</span>&nbsp;&nbsp;{this.state.city},&nbsp;{this.state.state}&nbsp;<MdOutlineModeEditOutline></MdOutlineModeEditOutline></p>
        </div>
      )
    }
    return (
      <React.Fragment>
        {jsx}
      </React.Fragment>
    )
  }
}

export default Locate
