import React from 'react'
import apiUrl from '../../apiConfig'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { IoChevronForward } from 'react-icons/io5'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { IconContext } from 'react-icons'

class Slogan extends React.Component {
  state = {
    view: '',
    createSlogan: '',
    editSlogan: '',
    slogan: '',
    validated: '',
    lengthError: false,
    loaded: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/portals',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.portals[0].slogan) {
          this.setState({ slogan: res.data.portals[0].slogan.text, view: 'edit' })
        }
      })
      .then(() => this.setState({ loaded: true }))
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.view !== prevState.view) {
      this.props.viewProfile('slogan', this.state.view)
    }
  }

  handleChange = (event) => {
    const value = event.target.value
    this.setState({ [event.target.name]: value },
      () => {
        const arr = this.state.slogan.split('')
        if (arr.length > 0 && arr.length < 28) {
          this.setState({ validated: true })
        } else {
          this.setState({ validated: false })
        }
        if (arr.length > 28) {
          this.setState({ lengthError: true })
        } else {
          this.setState({ lengthError: false })
        }
      })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ view: 'edit' })
    axios({
      url: apiUrl + '/portals/' + this.props.portalId,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.props.user.accessToken.jwtToken}`
      },
      data: {
        portal: {
          slogan: {
            text: this.state.slogan
          }
        }
      }
    })
  }

  render () {
    let jsx
    if (!this.state.view) {
      jsx = (
        <React.Fragment>
          {!this.state.createSlogan && !this.state.editSlogan && this.state.loaded &&
          <div onClick={() => this.setState({ createSlogan: true })}>
            <p style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'underline' }} className="main-font-style small-text red">Add Slogan</p>
          </div>
          }
          {this.state.createSlogan &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <Form.Control
                  required
                  placeholder="Enter Your Slogan"
                  onChange={this.handleChange}
                  value={this.state.slogan}
                  name="slogan"
                />
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
              {this.state.lengthError &&
              <Form.Group>
                <p className="auth-error-messages">must be under 28 characters</p>
              </Form.Group>
              }
            </Form>
          </div>
          }
          {this.state.editSlogan &&
          <div className="portal-div-inputs">
            <Form onSubmit={this.handleSubmit} className="portal-inputs">
              <InputGroup>
                <Form.Control
                  required
                  placeholder="Enter Your Slogan"
                  onChange={this.handleChange}
                  value={this.state.slogan}
                  name="slogan"
                />
                <Button type="submit" disabled={!this.state.validated} style={{ borderRadius: '0 .25rem .25rem 0' }} className="variable-buttons">
                  <div className="d-flex justify-content-center align-content-center align-items-center" style={{ height: '100%', width: '100%' }}>
                    <IconContext.Provider value={{ className: 'auth-back-img' }}>
                      <div>
                        <IoChevronForward></IoChevronForward>
                      </div>
                    </IconContext.Provider>
                  </div>
                </Button>
              </InputGroup>
              {this.state.lengthError &&
              <Form.Group>
                <p className="auth-error-messages">must be under 28 characters</p>
              </Form.Group>
              }
            </Form>
          </div>
          }
        </React.Fragment>
      )
    } else {
      jsx = (
        <div style={{ width: '100%' }}>
          <p onClick={() => this.setState({ view: '', createSlogan: '', editSlogan: 'true', validated: 'true' })} style={{ textAlign: 'center', marginTop: '1rem', textDecoration: 'none' }} className="main-font-style small-text variable-main-font-color"><span className="xs-text" style={{ fontWeight: 'bold' }}>slogan:</span><br></br><span className="variable-checks">&#10004;</span>&nbsp;&nbsp;{this.state.slogan}&nbsp;<MdOutlineModeEditOutline></MdOutlineModeEditOutline></p>
        </div>
      )
    }
    return (
      <React.Fragment>
        {jsx}
      </React.Fragment>
    )
  }
}

export default Slogan
